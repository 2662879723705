import React from 'react'
import isEmail from 'validator/es/lib/isEmail'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { usePutCustomer } from '../../../hooks/queryHooks/usePutCustomer'
import {
  EmailType,
  EmailTypes
} from '../../../integrations/allkort/v1/enums/EmailType'
import { Email } from '../../../integrations/allkort/v1/schemas/Email'
import ActionButton from '../../elements/ActionButton'
import Button from '../../elements/Button'
import Checkbox from '../../elements/Checkbox'
import Input from '../../elements/Input'
import ToolTipWrapper from '../../elements/ToolTipWrapper'
import CustomerProfileEmailsHead from '../CustomerProfileEmailsHead'
import ProfileSettingsModal from '../ProfileSettingsModal'
import { EmailEdit, mapEmailsToEmailEdit } from './CustomerProfileEmails'

const emptyEmailRow = {
  address: '',
  types: [],
  errorMessage: ''
}

type CustomerProfileEmailsEditProps = {
  setEditMode: (newValue: boolean) => void
}

/**
 * This number is used to give new phone numbers unique IDs.
 * These are later replaced with real IDs when added to the database
 */
let emailId = 0

const CustomerProfileEmailsEdit: React.FC<CustomerProfileEmailsEditProps> = ({
  setEditMode
}) => {
  const { data: customer } = useGetCustomer()

  const [editModeEmails, setNewEmails] = React.useState<EmailEdit[]>(
    mapEmailsToEmailEdit(customer!.emails) ?? [emptyEmailRow]
  )

  const nrOfInformationEmails = editModeEmails.filter((email) =>
    // @ts-ignore
    email.types.includes('Information')
  ).length

  const nrOfInvoiceEmails = editModeEmails.filter((email) =>
    // @ts-ignore
    email.types.includes('InvoiceStatement')
  ).length

  const EmailTypeValues = Object.values(EmailType)

  const [removalConfirmation, setRemovalConfirmation] =
    React.useState<boolean>(false)
  const [toBeDeleted, editToBeDeleted] = React.useState<number>(-1)

  const updateCustomerMutation = usePutCustomer({
    extendedOnSuccessFunction: () => setEditMode(false)
  })

  let cannotSave = false
  editModeEmails.forEach((email) => {
    if (!email.address || email.errorMessage) {
      cannotSave = true
    }
  })
  const deleteRow = (index: number) => {
    const newEmailsCopy = [...editModeEmails]
    if (index > -1) {
      newEmailsCopy.splice(index, 1)
    }
    setNewEmails(newEmailsCopy)
  }
  const handleRemovalConfirmation = (email: EmailEdit, index: number) => {
    editToBeDeleted(index)
    if (email.address === '') {
      deleteRow(index)
    } else {
      setRemovalConfirmation(true)
    }
  }
  const addRow = () => {
    const newEmailsCopy = [...editModeEmails]
    newEmailsCopy.push(emptyEmailRow)
    setNewEmails(newEmailsCopy)
  }

  const handleSave = () => {
    const newEmails: Email[] = []

    editModeEmails.forEach((email) => {
      email.types.forEach((emailType) => {
        newEmails.push({
          emailAddress: email.address,
          emailTypeId: emailType,
          emailId,
          sortOrder: 0
        })
        emailId++
      })
    })

    updateCustomerMutation.mutate({
      emails: newEmails
    })
  }

  const validateEmail = (value: string, index: number) => {
    const currentAddress = editModeEmails[index]?.address
    let errorMessage = ''
    const duplicates = editModeEmails.filter(
      (email, i) => email.address === value && i !== index
    )
    if (currentAddress) {
      if (currentAddress.length === 0) {
        errorMessage = 'Kan inte vara tomt'
      } else if (!isEmail(currentAddress)) {
        errorMessage = 'Felaktigt format'
      } else if (duplicates.length !== 0) {
        errorMessage = 'Addressen finns redan'
      } else if (editModeEmails[index]?.types.length === 0) {
        errorMessage = 'Klicka i en typ'
      }
    }
    return errorMessage
  }

  const handleChangeType = (value: EmailType, index: number, key: string) => {
    const newEmailsCopy = [...editModeEmails]
    const currentEmail = newEmailsCopy[index]
    if (newEmailsCopy && currentEmail) {
      if (currentEmail.types.includes(value)) {
        const types = currentEmail.types.filter((item) => item !== value)
        currentEmail.types = types
        newEmailsCopy[index] = currentEmail
        // @ts-ignore
        newEmailsCopy[index].errorMessage = validateEmail(value, index)
      } else {
        // @ts-ignore
        newEmailsCopy[index].types.push(value)
        // @ts-ignore
        newEmailsCopy[index].errorMessage = validateEmail(value, index)
      }
    }
    setNewEmails(newEmailsCopy)
  }

  const handleChangeEmail = (value: string, index: number) => {
    const newEmailsCopy = [...editModeEmails]
    if (newEmailsCopy[index]) {
      // @ts-ignore
      newEmailsCopy[index].address = value
      // @ts-ignore
      newEmailsCopy[index].errorMessage = validateEmail(value, index)
    }
    setNewEmails(newEmailsCopy)
  }
  return (
    <>
      <table className="ProfileEmailTable ProfileEmailTable--Edit">
        <CustomerProfileEmailsHead />
        <tbody>
          {editModeEmails.map((item, index) => {
            const isRowDeleteDisabled =
              (nrOfInformationEmails === 1 &&
                item.types.includes('Information')) ||
              (nrOfInvoiceEmails === 1 &&
                item.types.includes('InvoiceStatement'))

            return (
              <tr key={index}>
                <th>
                  <Input
                    error={item.errorMessage ? item.errorMessage : undefined}
                    onChange={(event) =>
                      handleChangeEmail(event.currentTarget.value, index)
                    }
                    value={item.address}
                  />
                </th>
                {EmailTypeValues.map((key: EmailTypes) => {
                  if (
                    customer!.invoiceMailingDeliveryType === 'PaperMailing' &&
                    customer!.statementMailingDeliveryType === 'PaperMailing' &&
                    key === EmailType.InvoiceStatement
                  ) {
                    return null
                  }

                  const isChecked = item.types.includes(EmailType[key])
                  const isInformationCheckbox = key === 'Information'
                  const isMailInvoiceCheckbox = key === 'InvoiceStatement'

                  const isOnlyInformationCheckbox = nrOfInformationEmails === 1
                  const isOnlyInvoiceCheckbox = nrOfInvoiceEmails === 1

                  const isDisabled =
                    isChecked &&
                    ((isInformationCheckbox && isOnlyInformationCheckbox) ||
                      (isMailInvoiceCheckbox && isOnlyInvoiceCheckbox))

                  return (
                    <th key={key}>
                      <ToolTipWrapper
                        enabled={isDisabled}
                        noHoverState
                        text="Du kan inte klicka ur detta eftersom det måste finnas minst en mailadress av denna typen"
                      >
                        <Checkbox
                          ariaLabel={
                            isDisabled
                              ? 'Du kan inte klicka ur detta eftersom det måste finnas minst en mailadress av denna typen'
                              : undefined
                          }
                          checked={isChecked}
                          disabled={isDisabled}
                          onChange={() =>
                            handleChangeType(EmailType[key], index, 'types')
                          }
                        />
                      </ToolTipWrapper>
                    </th>
                  )
                })}

                <th className="ProfileEmails__Delete">
                  <ToolTipWrapper
                    enabled={isRowDeleteDisabled}
                    noHoverState
                    text={`Du kan inte ta bort ${item.address} eftersom mailadressen är den enda som får ett typ av utskick som kräver minst en mailadress`}
                    tooltipPlacement="left"
                  >
                    <ActionButton
                      aria-label={
                        isRowDeleteDisabled
                          ? `Du kan inte ta bort ${item.address} eftersom mailadressen är den enda som får ett typ av utskick som kräver minst en mailadress`
                          : ''
                      }
                      isVisuallyDisabled={isRowDeleteDisabled}
                      onClick={() => handleRemovalConfirmation(item, index)}
                      size="small"
                      title={
                        isRowDeleteDisabled ? '' : `Ta bort ${item.address}`
                      }
                      variation="delete"
                    />
                  </ToolTipWrapper>
                </th>

                {removalConfirmation && (
                  <ProfileSettingsModal
                    onBack={() => {
                      setRemovalConfirmation(false)
                    }}
                    onCancel={() => {
                      setRemovalConfirmation(false)
                    }}
                    onConfirm={() => {
                      deleteRow(toBeDeleted)
                      setRemovalConfirmation(false)
                    }}
                    onSave={() => {
                      deleteRow(toBeDeleted)
                      setRemovalConfirmation(false)
                    }}
                    saveButtonText="Ta bort"
                  >
                    <div className="Modal__Section">
                      <h5>{'Bekräfta borttagning'}</h5>
                      {'Är du säker på att du vill ta bort denna mailaddress?'}
                    </div>
                  </ProfileSettingsModal>
                )}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div
        className="ProfileEmails__Row"
        style={{ borderBottom: '2px solid #f0f0f0' }}
      >
        <Button
          className="PaymentOptions__Badge my-4"
          onClick={addRow}
          style={{ border: 'none' }}
        >
          <i className="Icon fa fa-plus fad me-2" />
          {'Lägg till'}
        </Button>
      </div>

      <div className="mt-3">
        <Button
          className="ProfilePhone__Button ProfilePhone__Button--Save me-3"
          disabled={cannotSave}
          onClick={handleSave}
        >
          {'Spara'}
        </Button>
        <Button
          className="ProfilePhone__Button ProfilePhone__Button--Cancel"
          onClick={() => setEditMode(false)}
        >
          {'Avbryt'}
        </Button>
      </div>
    </>
  )
}

export default CustomerProfileEmailsEdit
