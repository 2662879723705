import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetCurrentUser
export type GetCurrentUserParams = {
  queryOptions?: Omit<
    UseQueryOptions<UserProfile, unknown, UserProfile, unknown[]>,
    'initialData'
  >
  additionalQueryKeys?: unknown[]
}

export const useGetCurrentUser = (options: GetCurrentUserParams = {}) => {
  const { queryOptions = {}, additionalQueryKeys = [] } = options
  const { userProfileRepo } = useRepos()

  const query = useQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: () => userProfileRepo.getCurrentUser(),
    keepPreviousData: true,
    ...queryOptions
  })

  return query
}
