import classNames from 'classnames'
import React from 'react'
import { PaymentStatus } from '../../domain/enums/PaymentStatus'
import { InvoiceModel } from '../../domain/models/InvoiceModel'
import Icon from './Icon'

type Props = {
  className?: string
  invoice: InvoiceModel
}

export const PaymentBadge: React.FunctionComponent<Props> = ({
  className,
  invoice
}) => {
  switch (invoice.getPaymentStatus()) {
    case PaymentStatus.Due:
      return (
        <div
          className={classNames(
            'PaymentBadge',
            'PaymentBadge--Warning',
            className
          )}
        >
          <Icon
            name="info-circle"
            primaryColor="yellow200"
            secondaryColor="yellow400"
          />
          <span className="PaymentBadge__Text">
            {invoice.remainingAmount < invoice.totalAmount
              ? 'Kvar att betala'
              : 'Att betala'}
          </span>
        </div>
      )
    case PaymentStatus.Overdue:
      return (
        <div
          className={classNames(
            'PaymentBadge',
            'PaymentBadge--Error',
            className
          )}
        >
          <Icon
            name="exclamation-triangle"
            primaryColor="red400"
            secondaryColor="yellow500"
          />
          <span className="PaymentBadge__Text">
            {'Betala'}
            <span className="PaymentBadge__LongText">{' snarast'}</span>
          </span>
        </div>
      )
    default:
      return (
        <div
          className={classNames(
            'PaymentBadge',
            'PaymentBadge--Success',
            className
          )}
        >
          <Icon
            name="check-circle"
            primaryColor="green300"
            secondaryColor="green500"
          />
          <span className="PaymentBadge__Text">{'Betald'}</span>
        </div>
      )
  }
}

export default PaymentBadge
