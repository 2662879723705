import { AgreementDto } from '../schemas/AgreementDto'

export class AgreementsProxy {
  static async getAgreements(): Promise<AgreementDto[]> {
    const response = await fetch(
      `${import.meta.env.VITE_BACKEND_URL}/api/Agreements`,
      {
        method: 'GET'
      }
    )

    if (!response.ok) {
      throw new Error(
        'Lyckades inte hämta avtal. Försök igen senare eller kontakta kundservice.'
      )
    }

    return (await response.json()) as AgreementDto[]
  }
}
