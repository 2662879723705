import React, { ReactElement } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

export type NavTabObject = {
  label: string
  component: ReactElement<unknown>
  link?: string
}

export type NavTabsObject = {
  [key: string]: NavTabObject
}

type NavTabsProps = { tabs: NavTabsObject }

const NavTabs: React.FC<NavTabsProps> = (props) => {
  const tabs = { ...props.tabs }

  for (const tabKey in tabs) {
    if (tabKey.includes('/*')) {
      const newTabKey = tabKey.replaceAll('/*', '')
      tabs[newTabKey] = tabs[tabKey]
      delete tabs[tabKey]
    }
  }

  const { pathname } = useLocation()
  const tabKeys = Object.keys(tabs)
  const tabEntries = Object.entries(tabs)
  const hasRootPathTab = tabKeys.includes('/')
  const currentPath = pathname.split('/').at(-1)

  if (!tabEntries) {
    return <></>
  }

  if (tabEntries[0] && tabEntries.length < 2) {
    return tabEntries[0][1].component
  }

  return (
    <>
      {/* <ul className="NavTabs">
        {tabEntries.map(([tabLink, tabArgs], index) => {
          const isRootPath = tabLink === '/'
          const tabUrl = isRootPath ? '' : removeTrailingSlash(tabLink)

          const isActive =
            currentPath === tabUrl ||
            (isRootPath &&
              !tabKeys.find((tabPath) => tabPath === currentPath)) ||
            (!hasRootPathTab &&
              index === 0 &&
              !tabKeys.find((tabPath) => tabPath === currentPath))

          return (
            <li className="NavTab" key={tabLink + tabArgs.label}>
              <Link
                aria-label={tabArgs.label}
                className={classNames('NavTab__Link', {
                  'NavTab__Link--Active': isActive
                })}
                to={tabArgs?.link ? tabArgs?.link : tabUrl}
              >
                {tabArgs.label}
              </Link>
            </li>
          )
        })}
      </ul> */}
      <Routes>
        {!hasRootPathTab && tabEntries[0] && (
          <Route element={tabEntries[0][1].component} key="/" path="" />
        )}
        {tabEntries.map(([tabLink, tabArgs]) => {
          const isRootPath = tabLink === '/'
          const tabPath = isRootPath ? '' : tabLink
          return (
            <Route
              element={tabArgs.component}
              key={tabPath + tabArgs.label}
              path={tabPath}
            />
          )
        })}
      </Routes>
    </>
  )
}

export default NavTabs
