import React, { useEffect, useState } from 'react'
import { AuthService } from '../../integrations/backend/v1/services/AuthService'
import { getUrlParams } from '../../utils/getUrlParam'
import Button from '../elements/Button'
import InlineAlert, { Props as InlineAlertProps } from '../elements/InlineAlert'
import Loader from '../elements/Loader'

const ResetPasswordPage: React.FC<{}> = () => {
  const [inlineAlert, setInlineAlert] = useState<InlineAlertProps | null>(null)

  useEffect(() => {
    const { customerNo, email } = getUrlParams()

    if (!customerNo || !email) {
      window.location.href = `/?error=${encodeURIComponent(
        'Lyckades inte påbörja lösenordsåterställning. Länken är felaktig.'
      )}`
    }
  }, [])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const { customerNo: customerNoString, email } = getUrlParams()

    if (customerNoString && email) {
      const customerNo = parseInt(customerNoString, 10)

      try {
        await AuthService.generateResetPasswordToken(customerNo, email)
        setInlineAlert({
          content: (
            <span>
              {
                'En återställningslänk har skickats till den angivna mailadressen.'
              }
            </span>
          )
        })
      } catch (ex) {
        setInlineAlert({
          content: 'Lyckades inte återställa lösenord.'
        })
      }
    }
  }

  const { customerNo, email } = getUrlParams()

  if (!customerNo || !email) {
    return <Loader />
  }

  return (
    <div className="Section ResetPasswordPage BgColor--Red400">
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          <form
            className="Column Column--JustifyStart Column--Md6 Column--Lg6"
            onSubmit={handleSubmit}
          >
            <h1 className="NewPasswordPage__Heading">
              {'Återställning av lösenord\r'}
            </h1>
            <p>
              {
                'Av säkerhetsskäl behöver du skapa ett nytt lösenord för att komma åt ditt '
              }
              {'konto.\rVi kommer att skicka en länk till '}
              <b>{email}</b>
              {' där du kan skapa ett nytt lösenord.\r'}
            </p>
            <Button
              className="NewPasswordPage__Button"
              type="submit"
              // Dummy URL for on-hover visibility:
              url="#"
            >
              {'Återställ lösenord\r'}
            </Button>
            {inlineAlert && <InlineAlert {...inlineAlert} />}
          </form>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordPage
