/* eslint-disable max-len, sort-keys */

import { CardType } from '../../../domain/enums/CardType'
import { TransactionModel } from '../../../domain/models/TransactionModel'
import { TransactionModelMapper } from '../../../integrations/allkort/v1/mappers/TransactionModelMapper'

const mapper = new TransactionModelMapper()

export const mockTransactions: TransactionModel[] = [
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      '0ce832bd5d843db9ace81a45270b629296dff5acd5f31f9eb80c0df9d6db5602c33a0727b642ab1885ff2b98cdc939a8e2de8c6abfcdae7668b91898eccfc93a',
    dateTime: '2020-12-10T15:23:00',
    siteId: 20,
    siteName: 'Qstar Höör',
    cardNo: 34,
    cardType: CardType.SmartCard,
    productId: 1,
    productName: '95 Oktan',
    price: 13.48,
    quantity: 10,
    amount: 134.8,
    mailingNo: 9121285,
    discount: 0.3,
    billedPrice: 0,
    billedAmount: 0,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      'c7af0295eec19333d3b46bd5306aa54a9d6348170b66a94d493ba787e1349285cb648b3d057a7596bdc1e2e6c08641f18fff9c6131ef60f446f32189d87b975a',
    dateTime: '2020-12-10T15:22:00',
    siteId: 144,
    siteName: 'Qstar Hökerum',
    cardNo: 34,
    cardType: CardType.SmartCard,
    productId: 1,
    productName: '95 Oktan',
    price: 13.83,
    quantity: 20,
    amount: 276.6,
    mailingNo: 9121285,
    discount: 0.3,
    billedPrice: 0,
    billedAmount: 0,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      '3c5818a69d5cc264474f214e964c9f905e5b2f06ee09371fd2c946ba22f3a563b0dd8753c4537a6288b8fd91bdc0859267a944cb8052e11d6b2e94c41b37bb8b',
    dateTime: '2020-12-10T15:22:00',
    siteId: 11,
    siteName: 'Qstar Åstorp',
    cardNo: 34,
    cardType: CardType.SmartCard,
    productId: 8,
    productName: 'Diesel Standard',
    price: 13.63,
    quantity: 10,
    amount: 136.3,
    mailingNo: 9121285,
    discount: 0.3,
    billedPrice: 0,
    billedAmount: 0,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      'cfa6b77c52afdf20f0c972f5cbdc52772170433ff84677a0dbf5cf98b3810bcf15c7c72dc7ae679bf8b72358a685c13c0d3ec697574962e6044af8a98de39216',
    dateTime: '2020-11-21T14:41:57',
    siteId: 356,
    siteName: 'Qstar Fritsla',
    cardNo: 32,
    cardType: CardType.CreditCard,
    productId: 1,
    productName: '95 Oktan',
    price: 13.73,
    quantity: 33.52,
    amount: 460.23,
    mailingNo: 9121285,
    discount: 0.3,
    billedPrice: 0,
    billedAmount: 0,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      '0389a97e965c3dbe3112e30102d4e32c73c9c23adb4714ceb17fbed93d4b557687ccfc96a37f4b23e3df7b07f9723798b14082a19965f88c8ac54db72cdceb58',
    dateTime: '2020-11-17T08:26:39',
    siteId: 160,
    siteName: 'Qstar Rycklösa',
    cardNo: 33,
    cardType: CardType.CreditCard,
    productId: 8,
    productName: 'Diesel Standard',
    price: 13.43,
    quantity: 62.06,
    amount: 833.47,
    mailingNo: 9121285,
    discount: 0.3,
    billedPrice: 0,
    billedAmount: 0,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      '1deb20c06919cd09b12663b4602c8fc362ed4cc9b57a723d538830c5acdca23c42ba647e8f3dde4fb2cc19693a275537874f63b98b41b87e613b3c99f53358f9',
    dateTime: '2020-11-08T14:31:57',
    siteId: 6510,
    siteName: 'Qstar Dalsjöfors',
    cardNo: 32,
    cardType: CardType.CreditCard,
    productId: 1,
    productName: '95 Oktan',
    price: 13.38,
    quantity: 39.95,
    amount: 534.53,
    mailingNo: 9121285,
    discount: 0.3,
    billedPrice: 13.08,
    billedAmount: 522.55,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      '6301905064107efba67b24ea7968a6232ddbdcf16d94e5cbe16c00d275d8e1f306918ffeadde08bc6b7325c0fba0e739fa5189160a8a2dde40f7c9055e177617',
    dateTime: '2020-10-30T11:48:50',
    siteId: 160,
    siteName: 'Qstar Rycklösa',
    cardNo: 32,
    cardType: CardType.CreditCard,
    productId: 1,
    productName: '95 Oktan',
    price: 12.98,
    quantity: 47.97,
    amount: 622.65,
    mailingNo: 9121285,
    discount: 0.3,
    billedPrice: 12.68,
    billedAmount: 608.26,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      '8b80ffec6c1d64713d2b3c98b327dd16b0281655e5e3f9318f8e4b4ce053bf5bd44d3854f7d3fb7a85db229a1680ccf20241de62262271ce3cc397324ae5225b',
    dateTime: '2020-10-29T12:00:00',
    siteId: 400,
    siteName: 'Qstar-Butiken Spårgatan',
    cardNo: 33,
    cardType: CardType.CreditCard,
    productId: 704,
    productName: 'Butik',
    price: 574.44,
    quantity: 1,
    amount: 574.44,
    mailingNo: 9121285,
    discount: 0,
    billedPrice: 574.44,
    billedAmount: 574.44,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      'e51e2f1d8e807b5384846404cc1c58348f5ca55e2826c83d62deb0e350d7ac99b4becce6e35a9c6f1b7e72c944a95bd678325bb321a3c77b904b14e500e38bdc',
    dateTime: '2020-10-29T09:34:11',
    siteId: 123,
    siteName: 'Qstar Norrköping',
    cardNo: 33,
    cardType: CardType.CreditCard,
    productId: 8,
    productName: 'Diesel Standard',
    price: 13.49,
    quantity: 46.21,
    amount: 623.37,
    mailingNo: 9121285,
    discount: 0.3,
    billedPrice: 13.19,
    billedAmount: 609.51,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  }),
  mapper.getTransactionModel({
    transactionType: 'K',
    transactionId:
      'e5528536f7fda992c65649cc2e33149ad01104414b20c29ffccb4cf2e8c2d3e539ab15be94873b3a74fe9581e87ac9d8f4ea82a32ba738f9c419914ce0f98f97',
    dateTime: '2020-10-23T12:00:00',
    siteId: 400,
    siteName: 'Qstar-Butiken Spårgatan',
    cardNo: 33,
    cardType: CardType.CreditCard,
    productId: 704,
    productName: 'Butik',
    price: 551,
    quantity: -1,
    amount: -551,
    mailingNo: 9121285,
    discount: 0,
    billedPrice: 551,
    billedAmount: -551,
    vehicleNo: 0,
    odometer: 0,
	magstripe: ''
  })
]
