import { TransactionType } from '../../integrations/allkort/v1/schemas/Transaction'

export const TRANSACTION_MAX_AGE_YEARS = 7

export class TransactionModel {
  transactionId: string | null
  dateTime: Date
  siteName: string | null
  productName: string | null
  amount: number
  transactionType: TransactionType
  invoiceId: number | null
  magstripe: string | null

  constructor(
    transactionId: string | null,
    dateTime: Date,
    siteName: string | null,
    productName: string | null,
    amount: number,
    transactionType: TransactionType,
    invoiceId: number | null,
	magstripe: string | null
  ) {
    this.transactionId = transactionId
    this.dateTime = dateTime
    this.siteName = siteName
    this.productName = productName
    this.amount = amount
    this.transactionType = transactionType
    this.invoiceId = invoiceId
	this.magstripe = magstripe
  }
}
