import React, { Fragment } from 'react'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { usePutCustomer } from '../../../hooks/queryHooks/usePutCustomer'
import Button from '../../elements/Button'
import Link from '../../elements/Link'
import { PaymentMethodChangeConfirmation } from './Components/InvoiceMethodModal'

type CustomerProfileEmaiInfoProps = {
  closeModal: () => void
  setConfirmationModal: (args: PaymentMethodChangeConfirmation) => void
}

const CustomerProfileEmaiInfo: React.FC<CustomerProfileEmaiInfoProps> = ({
  closeModal,
  setConfirmationModal
}) => {
  const { data: customer } = useGetCustomer()
  const updateCustomerMutation = usePutCustomer({
    queryOptions: { onSettled: closeModal }
  })

  const invoiceEmails = customer!.emails.filter(
    (profileEmail) => profileEmail.emailTypeId === 'InvoiceStatement'
  )

  const handleSavePaymentMethod = () => {
    setConfirmationModal({
      onConfirm: () =>
        updateCustomerMutation.mutate({
          invoiceMailingDeliveryType: 'Email'
        }),
      newPaymentMethod: 'Email'
    })
  }
  return (
    <>
      <div className="Modal__Section">
        <p style={{ marginBottom: 0 }}>
          {'Dina mailfakturor kommer skickas till följande adresser:'}
        </p>
        {invoiceEmails.map((invoiceEmail) => (
          <Fragment key={invoiceEmail.emailId}>
            <b>{invoiceEmail.emailAddress}</b>
            <br />
          </Fragment>
        ))}
        {customer!.isCompany && (
          <small>
            {
              'Dessa mailadresser kan redigeras under sektionen "Mailadresser" i '
            }
            <Link
              href="/profil#emails"
              onClick={() => {
                closeModal()
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }}
              styled
            >
              {'din profil'}
            </Link>
          </small>
        )}
      </div>
      <div className="ProfileSettingsModal__Actions flex-column">
        <div className="d-flex justify-content-end">
          <Button
            className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Save"
            disabled={customer!.invoiceMailingDeliveryType === 'Email'}
            onClick={handleSavePaymentMethod}
            type="button"
          >
            {'Spara'}
          </Button>
          <Button
            className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Cancel"
            onClick={closeModal}
            type="button"
          >
            {'Avbryt'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default CustomerProfileEmaiInfo
