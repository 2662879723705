import React from 'react'
import useIsBankIdUser from '../../../hooks/useIsBankIdUser'
import Button from '../../elements/Button'
import Modal from '../../elements/Modal'
import { NeedsExtAuth } from '../../elements/NeedsExtAuth'

type SaveButtonState = 'disabled' | 'enabled' | 'hidden'

type Props = {
  className?: string
  onBack: () => void
  onCancel: () => void
  onConfirm?: () => void
  onSave: () => void
  saveButtonState?: SaveButtonState | false
  saveButtonText?: string
  saved?: boolean
  isPaymentSettings?: boolean
  error?: string
  closeButtonText?: string
  onClose?: () => void
}

const ProfileSettingsModal: React.FC<Props> = ({
  children,
  className,
  closeButtonText,
  onConfirm,
  onBack,
  onCancel,
  onSave,
  saveButtonState,
  saveButtonText,
  isPaymentSettings,
  saved,
  error = '',
  onClose
}) => {
  const isBankIdUser = useIsBankIdUser()
  const handleBackClick = () => {
    onBack()
  }

  const handleModalClose = () => {
    onCancel()
  }

  const handleConfirmClick = () => {
    if (onConfirm) onConfirm()
  }

  const handleSaveClick = () => {
    onSave()
  }

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  }

  return (
    <Modal className={className} onClose={onClose ?? handleModalClose}>
      {children}
      {isBankIdUser ? (
        <>
          {saved ? (
            <div className="ProfileSettingsModal__Actions">
              <Button
                className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Save"
                onClick={handleConfirmClick}
                type="button"
              >
                {isPaymentSettings && !isMobile ? (
                  <span>{'Ja, spara nytt betalsätt'}</span>
                ) : (
                  <span>{'Ja, spara'}</span>
                )}
              </Button>
              <Button
                className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Cancel"
                onClick={handleBackClick}
                type="button"
              >
                {isMobile() ? (
                  <i className="fas fa-long-arrow-left" />
                ) : (
                  <span>{'Gå tillbaka'}</span>
                )}
              </Button>
            </div>
          ) : (
            <div className="ProfileSettingsModal__Actions flex-column">
              <div className="d-flex justify-content-end">
                {saveButtonState !== 'hidden' && (
                  <Button
                    className={`ProfileSettingsModal__Button ProfileSettingsModal__Button--Save ${
                      saveButtonState === 'disabled'
                        ? 'ButtonAccessibilityDisabled'
                        : ''
                    }`}
                    onClick={() => {
                      if (saveButtonState !== 'disabled') {
                        handleSaveClick()
                      }
                    }}
                    type="button"
                  >
                    {saveButtonText ?? 'Spara'}
                  </Button>
                )}
                <Button
                  className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Cancel"
                  onClick={handleModalClose}
                  type="button"
                >
                  {closeButtonText ?? 'Avbryt'}
                </Button>
              </div>
              {
                <div className="d-flex justify-content-end">
                  <p
                    aria-live="assertive"
                    className={`${
                      error
                        ? 'ProfileSettingsModal__ErrorMessage'
                        : 'ProfilePhone__EmptyErrorMessage'
                    } mb-0`}
                  >
                    <span className="sr-only">{'Fel:'}</span>
                    {error}
                  </p>
                </div>
              }
            </div>
          )}
        </>
      ) : (
        <NeedsExtAuth
          actionText="För att ändra inställningar"
          className="PaymentMethod__Info"
        />
      )}
    </Modal>
  )
}

export default ProfileSettingsModal
