import React from 'react'
import { useGetCards } from '../../../hooks/queryHooks/useGetCards'
import Link from '../../elements/Link'
import AccountInformation from '../AccountInformation'
import BalanceInformation from '../BalanceInformation'
import { BalanceAmountAndHistory } from './BalanceAmountAndHistory'
import SmartCardBalance from './SmartCardBalance'

type Props = {}

const SmartCardView: React.FC<Props> = () => {
  const { data: cards } = useGetCards()

  if (
    cards?.smartCards.length === 0 ||
    import.meta.env.VITE_DEBUG_SHOW_NO_CARDS === 'true'
  ) {
    return (
      <div className="Row">
        <div className="Row Row--JustifyLeft">
          <div className="Row__Inner">
            <div className="Column Column--JustifyStart Column--Lg5">
              <div className="ContentBox">
                <div className="ContentBox__Content">
                  <h2>{'Våra smartkort'}</h2>
                  {/* Text taken from https://qstar.se/kund */}
                  <p>
                    {
                      'Vårt stationsnät sträcker sig över hela Sverige, hit och dit och fram '
                    }
                    {
                      'och tillbaka. För att få full koll på hur, var och när du tankar, kan '
                    }
                    {
                      'det vara ekonomiskt fördelaktigt att skaffa dig något av våra tankkort. '
                    }
                    {
                      'Ansökan gör du enkelt online och kortet är såklart gratis.'
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Row Row--JustifyLeft">
          <div className="Row__Inner">
            <div className="Column Column--JustifyStart Column--Lg6">
              <h2>{'Skaffa smartkort'}</h2>
              {/* Text taken from https://qstar.se/kund */}
              <p>
                {'Du ansöker om smartkort under fliken '}
                <Link className="SmartCardLink" href="/kort">
                  <b>{'Mina kort'}</b>
                </Link>
                {'.'}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="Row">
      <div className="Row__Inner">
        <div className="Column Column--JustifyStart Column--Lg6">
          <SmartCardBalance />
          <AccountInformation />
          <h5>{'Leveranssätt'}</h5>
          <BalanceInformation />
        </div>
        <BalanceAmountAndHistory />
      </div>
    </div>
  )
}

export default SmartCardView
