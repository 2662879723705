import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { BankCard } from '../../integrations/allkort/v1/schemas/Card'
import { useRepos } from '../useRepos'
import { useUserRights } from '../useUserRights'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetBankCards
export type GetCardsParams = {
  queryOptions?: Omit<
    UseQueryOptions<BankCard[], unknown, BankCard[], unknown[]>,
    'initialData'
  >
  additionalQueryKeys?: unknown[]
}

export type Bankcards = {
  bankCards: BankCard[]
}

export const useGetBankCards = (
  options: GetCardsParams = {
    queryOptions: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, additionalQueryKeys = [] } = options
  const { BankCardRepo } = useRepos()
  const { isSuperUser, userHasRight } = useUserRights()

  const query = useQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: async () => {
      let fetchedBankCards: BankCard[] = []

      if (userHasRight('BankCardsRead')) {
        fetchedBankCards = await BankCardRepo.getBankCards()
      }
      // return bankCards

      return fetchedBankCards
    },
    keepPreviousData: true,
    ...queryOptions
  })
  return query
}
