import classNames from 'classnames'
import React from 'react'
import { BankCard as BankCardType } from '../../integrations/allkort/v1/schemas/Card'

type BankCardProps = { card: BankCardType }

const BankCard: React.FC<BankCardProps> = ({ card }) => {
  return (
    <div className="Card__Container">
      <div
        className={classNames({
          Card: true,
          'Card--BankCard': true
        })}
      >
        <div className="Card__Content">
          <div className="Card__Top">
            <h3 className="Bankcard__Header">{'Bankkort'}</h3>
          </div>
          <div className="Card__Bottom">
            <div
              className="Card__CardRow Card__CardReference Card__CardReference--Primary"
              title={card.maskedPan}
            >
              {card.maskedPan.replaceAll('X', '*')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankCard
