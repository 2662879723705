import StatusCodes from 'http-status-codes'
import { ErrorCode } from '../schemas/ErrorCodes'

export class AuthProxy {
  private static _fetchAuth(
    method: string,
    path: string,
    options: RequestInit
  ): Promise<Response> {
    return fetch(`${import.meta.env.VITE_BACKEND_URL}/api/Auth${path}`, {
      ...options,
      method
    })
  }

  static async authorizeBankIDLogin(
    codeVerifier: string
  ): Promise<string | null> {
    const response = await this._fetchAuth('POST', '/AuthorizeBankIDLogin', {
      body: codeVerifier
    })
    return response.ok ? response.text() : null
  }

  static generateResetPasswordToken(
    customerNo: number,
    email: string
  ): Promise<Response> {
    return this._fetchAuth('POST', '/GenerateResetPasswordToken', {
      body: JSON.stringify({ customerNo, email }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  static async loginBankIDToken(code: string, verifier: string): Promise<void> {
    const response = await this._fetchAuth('POST', '/LoginBankIDToken', {
      body: JSON.stringify({ code, verifier }),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    })

    if (!response.ok) {
      throw new Error(
        'Lyckades inte slutföra BankID-inloggning. Försök igen senare eller kontakta kundservice.'
      )
    }
  }

  /** @returns Whether the password was valid, if `false` the user needs to change it */
  static async loginCustomerNumber(
    username: string,
    password: string
  ): Promise<boolean> {
    let response: Response

    try {
      response = await this._fetchAuth('POST', '/LoginCustomerNumber', {
        body: JSON.stringify({ password, username }),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    } catch (ex) {
      throw new Error('Inloggningsproblem vid anrop till server.')
    }

    if (!response.ok) {
      const status =
        import.meta.env.VITE_DEBUG_PASS_NEED_RESET === 'true'
          ? StatusCodes.UNAUTHORIZED
          : response.status

      const error =
        import.meta.env.VITE_DEBUG_PASS_NEED_RESET === 'true'
          ? { ErrorCode: ErrorCode.PasswordResetNeeded }
          : await response.json()

      switch (status) {
        case /* 400 */ StatusCodes.BAD_REQUEST: {
          if (error?.error === 'invalid_grant') {
            throw new Error(
              'Felaktiga inloggningsuppgifter. Kontrollera dina uppgifter och försök igen.'
            )
          } else {
            throw new Error(
              'Problem vid kommunikation med server. ' +
                'Försök igen senare eller kontakta kundservice.'
            )
          }
        }
        case /* 500 */ StatusCodes.INTERNAL_SERVER_ERROR: {
          throw new Error(
            'Problem vid kommunikation med inloggningsserver. ' +
              'Försök igen senare eller kontakta kundservice.'
          )
        }
        case /* 401 */ StatusCodes.UNAUTHORIZED: {
          if (error?.ErrorCode === ErrorCode.PasswordResetNeeded) {
            return false
          } else if (error?.ErrorCode === ErrorCode.FrozenAccount) {
            throw new Error(
              'Kunde inte logga in, ditt konto är låst. Vänligen kontakta kundservice.'
            )
          }
          throw new Error(
            'Felaktiga inloggningsuppgifter. Kontrollera dina uppgifter och försök igen.'
          )
        }
        default: {
          throw new Error(
            'Något gick fel vid kommunikation med server. ' +
              'Försök igen senare eller kontakta kundservice.'
          )
        }
      }
    }

    return true
  }

  static resetPassword(token: string, newPassword: string): Promise<Response> {
    return this._fetchAuth('POST', '/ResetPassword', {
      body: JSON.stringify({ newPassword, token }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  static getPasswordRegex(): Promise<Response> {
    return this._fetchAuth('GET', '/PasswordRegex', {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}
