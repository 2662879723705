/* eslint-disable no-confusing-arrow */
import React from 'react'
import { CardModel } from '../../domain/models/CardModel'
import { isBankCard } from '../../utils/isBankCard'
import { BankCard as BankCardType } from './../../integrations/allkort/v1/schemas/Card'
import BankCardRow from './BankCardRow'
import CardRow from './CardRow'

type Props = {
  model: (CardModel | BankCardType)[]
}

const CardTable: React.FC<Props> = ({ model }) => {
  return (
    <table className="CardTable">
      <tbody>
        {model.map((card, index) =>
          isBankCard(card) ? (
            <BankCardRow card={card as BankCardType} key={index} />
          ) : (
            <CardRow key={index} model={card as CardModel} />
          )
        )}
      </tbody>
    </table>
  )
}

export default CardTable
