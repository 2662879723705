import classNames from 'classnames'
import React, { forwardRef } from 'react'
import { Spinner } from 'react-bootstrap'
import * as Theme from '../../domain/utils/theme'
import upperFirst from '../../utils/upperFirst'
import Icon, { Props as IconProps } from './Icon'
import Link from './Link'
import Wrapper from './Wrapper'

export type ButtonProps = {
  alt?: string
  children?: React.ReactNode
  className?: string
  color?: Theme.ColorName
  disabled?: boolean
  design?: 'normal' | 'round'
  icon?: IconProps
  locked?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  openNewTab?: boolean
  raw?: string
  style?: React.CSSProperties
  text?: string
  title?: string
  type?: 'button' | 'reset' | 'submit'
  url?: string
  'aria-disabled'?: boolean
} & ConditionalLoadingProps

type ConditionalLoadingProps =
  | { isLoading?: never; loadingText?: never }
  | { isLoading: boolean; loadingText: string }

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      design,
      color,
      className,
      url,
      openNewTab,
      alt,
      text,
      raw,
      type,
      locked,
      icon,
      children,
      isLoading,
      loadingText,
      ...props
    },
    ref
  ) => {
    // We create a set and convert it to an array so we only get unique values:
    const buttonClassNames = Array.from(
      new Set([
        'Button',
        design && `Button--${upperFirst(design)}`,
        color && `BgColor--${upperFirst(color)}`,
        ...(className || '').split(/\s+/)
      ])
    )

    const ariaDisabled = props['aria-disabled']

    if (ariaDisabled) {
      props.onClick = () => undefined
    }

    return (
      <Wrapper
        if={Boolean(url)}
        wrap={(button) => (
          <Link
            href={props['aria-disabled'] || props.disabled ? '' : url}
            openNewTab={
              openNewTab || buttonClassNames.includes('Button--OpenNewTab')
            }
          >
            {button}
          </Link>
        )}
      >
        <button
          aria-disabled={(ariaDisabled || isLoading) ?? false}
          aria-label={alt === undefined ? text : alt}
          className={classNames(buttonClassNames, {
            'Button--Loading': isLoading
          })}
          dangerouslySetInnerHTML={raw ? { __html: raw } : undefined}
          ref={ref}
          // eslint-disable-next-line react/button-has-type
          type={type ?? 'button'}
          {...props}
        >
          {!raw && (
            <>
              {locked && (
                <div className="Button__LockedBadge">
                  <Icon name="lock" type="line" />
                </div>
              )}
              {icon && <Icon name={icon.name} type="line" />}
              {text}
              {isLoading && loadingText
                ? [
                    loadingText,
                    <Spinner
                      animation="border"
                      key="loader"
                      style={{
                        width: '1.5em',
                        height: '1.5em',
                        fontSize: '.75em'
                      }}
                    />
                  ]
                : children}
            </>
          )}
        </button>
      </Wrapper>
    )
  }
)

Button.displayName = 'Button'
export default Button
