import classNames from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import upperFirst from '../../utils/upperFirst'
import Button from './Button'
import Icon, { Props as IconProps } from './Icon'
import Link from './Link'

type BannerType = keyof typeof bannerTypeIconMap

export type BannerData = {
  content: React.ReactNode | string
  icon?: IconProps | null
  id: string
  linkTitle?: string
  onClose?: () => void
  permanent?: boolean
  type: BannerType
  url?: string
  noAnimation?: boolean
}

export type Props = BannerData & {
  closeBanner?: () => void
}

export const bannerTypeIconMap = {
  banner: {
    name: 'exclamation-circle',
    primaryColor: 'yellow500',
    secondaryColor: 'red400'
  },
  info: {
    name: 'info-circle',
    primaryColor: 'red100',
    secondaryColor: 'pink500'
  },
  success: {
    name: 'check-circle',
    primaryColor: 'green300',
    secondaryColor: 'white'
  },
  warning: {
    name: 'exclamation-circle',
    primaryColor: 'yellow500',
    secondaryColor: 'red400'
  }
} as const

const Banner: React.FC<Props> = ({
  content,
  icon,
  id,
  linkTitle,
  onClose,
  permanent,
  type,
  url,
  closeBanner,
  noAnimation
}) => {
  const handleCloseButtonKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && closeBanner && id) {
      closeBanner()
    }
  }

  const handleCloseButtonClick = () => {
    if (closeBanner) {
      closeBanner()
    }

    if (onClose) {
      onClose()
    }
  }

  const hasButton = closeBanner && !permanent
  const iconProps = icon === undefined ? bannerTypeIconMap[type] : icon

  return (
    <motion.div
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      initial={{ height: noAnimation ? 'auto' : 0 }}
      key={id}
      transition={{ bounce: 0 }}
    >
      <div
        className={classNames(
          'Banner',
          `Banner--${upperFirst(type)}`,
          hasButton && 'Banner--HasButton'
        )}
      >
        {iconProps && <Icon {...iconProps} className="Banner__Icon" />}
        {content}
        {url && (
          <Link href={url}>
            <Button color="red400">{linkTitle}</Button>
          </Link>
        )}
        {hasButton && (
          <div
            className="Banner__CloseButton"
            onClick={handleCloseButtonClick}
            onKeyPress={handleCloseButtonKeyPress}
            role="button"
            tabIndex={0}
          >
            <Icon
              className="Banner__CloseButtonIcon"
              name="times"
              type="line"
            />
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default Banner
