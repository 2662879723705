import * as React from 'react'
import Statements from '../Statements'
import { HistoryTabs } from './HistoryTabs'

interface BalanceAmountAndHistoryProps {}

export const BalanceAmountAndHistory: React.FC<
  BalanceAmountAndHistoryProps
> = () => {
  return (
    <div className="Column Column--Lg6 justify-content-start BalanceAmountAndHistory__Column">
      <HistoryTabs />

      <h5>{'Kontobesked'}</h5>
      <Statements />
    </div>
  )
}
