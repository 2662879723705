/* eslint-disable nonblock-statement-body-position */
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import isEmpty from 'validator/es/lib/isEmpty'
import { PaymentMethod } from '../../../../../domain/enums/PaymentMethod'
import { useGetCustomer } from '../../../../../hooks/queryHooks/useGetCustomer'
import { usePostCustomerServiceTask } from '../../../../../hooks/queryHooks/usePostCustomerServiceTask'
import { InputStateObject, useForm } from '../../../../../hooks/useForm'
import { useToasts } from '../../../../../hooks/useToasts'
import Button from '../../../../elements/Button'
import { CustomTooltip } from '../../../../elements/CustomTooltip'
import { ConfirmEInvoice } from './ConfirmEInvoice'
import { EInvoiceInputField } from './EInvoiceInputField'
import TechInfoModal from './EInvoiceReferenceTechInfo'

type EInvoiceCompanyProps = {
  closeModal: () => void
}

export interface SubmitObject {
  invoiceId: string
  eanCode: string
  invoiceExchance: string
  referenceNo: string
}

export type EInvoiceType = 'none' | 'peppol' | 'van'

export const EInvoiceCompany: React.FC<EInvoiceCompanyProps> = ({
  closeModal
}) => {
  const { addToast } = useToasts()
  const { data: customer } = useGetCustomer()

  const [confirmSubmit, setconfirmSubmit] = useState<boolean>(false)
  const [invoiceType, setinvoiceType] = useState<EInvoiceType>('none')

  const { inputs, handleInputChange, isValid, validateInput, resetForm } =
    useForm<InputStateObject<SubmitObject>>({
      invoiceId: {
        value: '',
        isError: false,
        required: true,
        validationFunction: (input: string) => !isEmpty(input),
        errorMessage: 'Fältet får inte vara tomt.'
      },
      eanCode: {
        isError: false,
        value: '',
        validationFunction: (input) => /^[0-9]*$/.test(input),
        errorMessage: 'Fältet får enbart innehålla siffror',
        required: false
      },
      invoiceExchance: {
        isError: false,
        value: '',
        required: false,
        validationFunction: (input: string) => !isEmpty(input),
        errorMessage: 'Fältet får inte vara tomt.'
      },
      referenceNo: {
        value: '',
        required: false
      }
    })

  const handleSubmit = () => {
    setconfirmSubmit(true)
  }

  const { mutate, isLoading } = usePostCustomerServiceTask({
    queryOptions: {
      onSuccess: () => addToast('Din ansökan för E-faktura är nu inskickad.'),
      onError: () =>
        addToast({
          message:
            'Din ansökan misslyckades. Försök igen senare eller kontakta kundservice.',
          isError: true
        })
    }
  })

  const handleConfirmSubmit = () => {
    let invoiceMessage = ''
    let glnCode = inputs.eanCode.value

    if (invoiceType === 'peppol') {
      invoiceMessage =
        'Ansökan om att byta till E-faktura via Peppol-nätverket:\n' +
        `Peppol-ID: ${inputs.invoiceId.value}\n` +
        `Beställarreferens: ${inputs.referenceNo.value}\n`
    } else {
      if (isEmpty(glnCode)) {
        glnCode = inputs.invoiceId.value
      }
      invoiceMessage =
        'Ansökan om att byta till E-faktura:\n' +
        `Parts-ID: ${inputs.invoiceId.value}\n` +
        `GLN: ${glnCode}\n` +
        `Operatör: ${inputs.invoiceExchance.value}\n` +
        `Beställarreferens: ${inputs.referenceNo.value}\n`
    }
    mutate(invoiceMessage)
    closeModal()
  }

  return (
    <>
      {confirmSubmit && (
        <ConfirmEInvoice
          invoiceType={invoiceType}
          onBack={() => setconfirmSubmit(false)}
          onCancel={() => setconfirmSubmit(false)}
          onSave={handleConfirmSubmit}
          saved={false}
          submitObject={inputs}
        />
      )}
      <div className="CustomerProfileEmail Modal__Section">
        <div className="ProfileSettings__Subheading">
          <form
            className="d-flex flex-column"
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <Row className="CompanyEInvoice__Row d-flex justify-content-end">
              <h6>{'Välj en e-fakturatyp'}</h6>
              <div className="ProfileSettings__RadioGroup">
                {customer!.invoiceMailingDeliveryType ===
                  PaymentMethod.EInvoiceCompany && (
                  <b>{'Skicka in nya uppgifter'}</b>
                )}
                <div className="PaymentRadioGroup__Row">
                  <label className="CompanyEInvoicePeppol__Label">
                    <input
                      className="PaymentRadioGroup__Checkbox"
                      name="togglePayment"
                      onChange={() => {
                        setinvoiceType('peppol')
                        resetForm()
                        inputs.invoiceExchance.required = false
                      }}
                      type="radio"
                    />
                    {'E-faktura via Peppol-nätverket'}
                  </label>
                  <CustomTooltip
                    className="ms-2"
                    text="Fakturan lämnar Qstar på formatet Peppol BIS Billing 3. Kan konverteras 
                    till annat format av er
                    operatör eller systemleverantör."
                    tooltipPlacement="top-end"
                  />
                </div>
                <div className="PaymentRadioGroup__Row">
                  <label className="CompanyEInvoicePeppol__Label">
                    <input
                      className="PaymentRadioGroup__Checkbox"
                      name="togglePayment"
                      onChange={() => {
                        setinvoiceType('van')
                        resetForm()
                        inputs.invoiceExchance.required = true
                      }}
                      type="radio"
                    />
                    {'E-faktura via VAN-operatör'}
                  </label>
                  <CustomTooltip
                    className="ms-2"
                    text="Det traditionella operatörsnätverket för e-faktura. VAN-operatör 
                    är ibland omnämnt som fakturaväxel, tjänsteleverantör, VAN-leverantör 
                    eller VAN-tjänst. Fakturan lämnar Qstar på formatet Svefaktura 1.0. 
                    Kan konverteras till annat format av er
                    operatör eller systemleverantör."
                    tooltipPlacement="top-end"
                  />
                </div>
              </div>
              <Row className="CompanyEInvoice__Row CompanyEInvoice__Row--Inner d-flex justify-content-end">
                {invoiceType === 'peppol' && (
                  <>
                    <Col sm={6} xs={12}>
                      <EInvoiceInputField
                        className="CompanyEInvoice__Input mb-0"
                        error={
                          inputs.invoiceId?.isError
                            ? inputs.invoiceId.errorMessage
                            : undefined
                        }
                        labelElement={
                          <label className="d-flex">
                            <b>{'Peppol-ID *'}</b>
                          </label>
                        }
                        maxLength={20}
                        name="invoiceId"
                        onBlur={() => validateInput('invoiceId')}
                        onChange={handleInputChange}
                        required
                        value={inputs.invoiceId.value}
                      />
                    </Col>
                    <Col sm={6} xs={12}>
                      <EInvoiceInputField
                        className="CompanyEInvoice__Input mb-0"
                        labelElement={
                          <label className="d-flex">
                            <b>{'Beställarreferens'}</b>
                            <CustomTooltip
                              className="ms-2"
                              text="Definierad av er, köparen, för att styra fakturor rätt vid mottagandet i er organisation. Är ibland
                              omnämnt som fakturareferens, referensnummer, mottagarkod eller märkning."
                              tooltipPlacement="bottom-end"
                            />
                          </label>
                        }
                        name="referenceNo"
                        onBlur={() => validateInput('referenceNo')}
                        onChange={handleInputChange}
                        value={inputs.referenceNo.value}
                      />
                    </Col>
                  </>
                )}
                {invoiceType === 'van' && (
                  <>
                    <Col sm={6} xs={12}>
                      <EInvoiceInputField
                        className="CompanyEInvoice__Input mb-0"
                        error={
                          inputs.invoiceExchance?.isError
                            ? inputs.invoiceExchance.errorMessage
                            : undefined
                        }
                        labelElement={
                          <label className="d-flex">
                            <b>{'Operatör *'}</b>
                            <CustomTooltip
                              className="ms-2"
                              text="Er VAN-operatör. Är ibland omnämnt som fakturaväxel, 
                              tjänsteleverantör, VAN-leverantör eller VAN-tjänst."
                              tooltipPlacement="bottom-end"
                            />
                          </label>
                        }
                        name="invoiceExchance"
                        onBlur={() => validateInput('invoiceExchance')}
                        onChange={handleInputChange}
                        required
                        type="text"
                        value={inputs.invoiceExchance.value}
                      />
                    </Col>
                    <Col sm={6} xs={12}>
                      <EInvoiceInputField
                        className="CompanyEInvoice__Input mb-0"
                        error={
                          inputs.invoiceId?.isError
                            ? inputs.invoiceId.errorMessage
                            : undefined
                        }
                        labelElement={
                          <label className="d-flex">
                            <b>{'Parts-ID *'}</b>
                            <CustomTooltip
                              className="ms-2"
                              text="Det ID som er operatör använder för att styra fakturan till er som mottagarpart.
                              Även kallat elektronisk adress, partskod, partsidentitet, e-fakturaadress eller e-fakturaID.
                              Beroende på operatör är ert parts-ID i form av ett GLN, organisationsnummer eller landskod följt av
                              organisationsnummer."
                              tooltipPlacement="bottom-end"
                            />
                          </label>
                        }
                        maxLength={20}
                        name="invoiceId"
                        onBlur={() => validateInput('invoiceId')}
                        onChange={handleInputChange}
                        required
                        type="number"
                        value={inputs.invoiceId.value}
                      />
                    </Col>

                    <Col sm={6} xs={12}>
                      <EInvoiceInputField
                        className="CompanyEInvoice__Input mb-0"
                        error={
                          inputs.eanCode?.isError
                            ? inputs.eanCode.errorMessage
                            : undefined
                        }
                        labelElement={
                          <span className="d-flex">
                            <label className="d-flex">
                              <b>{'GLN'}</b>
                              <CustomTooltip
                                className="ms-2"
                                text="Om ert parts-ID behöver kompletteras med ett sekundärt parts-ID på formen av ett GLN. 
                              Vid utelämnande av GLN här kommer vi återupprepa ert parts-ID."
                                tooltipPlacement="bottom-end"
                              />
                            </label>
                            <TechInfoModal title="Teknisk info - Beställningsreferens">
                              <p className="mb-2">
                                <strong>{'Återfinns i fakturan på:'}</strong>
                              </p>
                              <code className="mb-4">
                                {
                                  "/Invoice/cac:BuyerParty/cac:Party/cac:PartyIdentification/cac:ID[@identificationSchemeAgencyID='9']"
                                }
                              </code>
                              <p className="mb-2">
                                <strong>
                                  {
                                    'Vid utelämnande av GLN kommer vi återupprepa ert '
                                  }
                                  <i>{'parts-ID'}</i>
                                  {' på:'}
                                </strong>
                              </p>
                              <code>
                                {
                                  '/Invoice/cac:BuyerParty/cac:Party/cac:PartyIdentification/cac:ID'
                                }
                              </code>
                            </TechInfoModal>
                          </span>
                        }
                        maxLength={13}
                        name="eanCode"
                        onBlur={() => validateInput('eanCode')}
                        onChange={handleInputChange}
                        value={inputs.eanCode.value}
                      />
                    </Col>
                    <Col sm={6} xs={12}>
                      <EInvoiceInputField
                        className="CompanyEInvoice__Input mb-0"
                        labelElement={
                          <span className="d-flex">
                            <label className="d-flex">
                              <b>{'Beställarreferens'}</b>
                              <CustomTooltip
                                className="ms-2"
                                text="Definierad av er, köparen, för att styra fakturor rätt vid mottagandet i er organisation. Är ibland
                              omnämnt som fakturareferens, referensnummer, mottagarkod eller märkning."
                                tooltipPlacement="bottom-end"
                              />
                            </label>
                            <TechInfoModal title="Teknisk info - Beställningsreferens">
                              <p className="mb-2">
                                <strong>
                                  {'Återfinns i Peppol-fakturan på:'}
                                </strong>
                              </p>
                              <code>{'/Invoice/cbc:BuyerReference'}</code>
                            </TechInfoModal>
                          </span>
                        }
                        name="referenceNo"
                        onChange={handleInputChange}
                        value={inputs.referenceNo.value}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </Row>
          </form>
        </div>
      </div>
      <div className="ProfileSettingsModal__Actions flex-column">
        <div className="d-flex justify-content-end">
          <Button
            className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Save"
            disabled={!isValid || isLoading}
            isLoading={isLoading}
            loadingText="Skickar"
            onClick={() => setconfirmSubmit(true)}
            type="button"
          >
            {'Skicka in'}
          </Button>
          <Button
            className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Cancel"
            onClick={closeModal}
            type="button"
          >
            {'Avbryt'}
          </Button>
        </div>
      </div>
    </>
  )
}
