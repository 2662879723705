/* eslint-disable no-confusing-arrow */
import React from 'react'
import { CardModel } from '../../domain/models/CardModel'
import { isBankCard } from '../../utils/isBankCard'
import { BankCard as BankCardType } from './../../integrations/allkort/v1/schemas/Card'
import BankCard from './BankCard'
import Card from './Card'

type Props = {
  cards: (CardModel | BankCardType)[]
}

const CardGrid: React.FC<Props> = ({ cards }) => {
  return (
    <div className="CardGrid">
      {cards.map((card, index) =>
        isBankCard(card) ? (
          <BankCard card={card as BankCardType} key={index} />
        ) : (
          <Card card={card as CardModel} key={index} />
        )
      )}
    </div>
  )
}

export default CardGrid
