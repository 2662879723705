import { PagedResult } from '../../../../domain/models/PagedResult'
import { Invoice } from '../schemas/Invoice'
import { ProxyBase } from './ProxyBase'

export type InvoiceQuery = {
  PageIndex?: number
  PageSize?: number
}

export class InvoiceProxy extends ProxyBase<Invoice> {
  getInvoices(query: InvoiceQuery): Promise<PagedResult<Invoice>> {
    return this.getArr('/api/v2/Invoices', query)
  }
}
