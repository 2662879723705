import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { Card } from '../../integrations/allkort/v1/schemas/Card'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UsePutCardArgs = {
  queryOptions?: UseMutationOptions<Card, PutCardMutationArgs, unknown, unknown>
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}
export type PutCardMutationArgs = {
  cardNo: string
  cardReference1: string
  cardReference2: string
}

export const usePutCard = (args: UsePutCardArgs = {}) => {
  const {
    queryOptions = {},
    extendedOnSuccessFunction,
    extendedOnErrorFunction
  } = args
  const invalidateQuery = useInvalidateQuery()
  const { addToast } = useToasts()
  const { cardRepo } = useRepos()

  const mutation = useMutation(
    (updatedCardArgs: PutCardMutationArgs) => cardRepo.putCard(updatedCardArgs),
    {
      onError: () => {
        addToast({
          message:
            'Misslyckades att spara kort. Försök gärna igen senare. ' +
            'Om problemet består, vänligen kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: (status, cardArgs) => {
        invalidateQuery('cards')
        addToast(`Kort ${cardArgs.cardReference1} är nu uppdaterat`)

        extendedOnSuccessFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}
