import classNames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router-dom'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import NavTabs, { NavTabsObject } from '../elements/NavTabs'
import SuspenseLoaderWrapper from '../elements/SuspenseLoaderWrapper'
import HandleUsersPage from './HandleUsersPage'
import ProfileSettings from './ProfileSettings'

type ProfilePageProps = {}

const ProfilePage: React.FC<ProfilePageProps> = () => {
  const { pathname } = useLocation()
  const isBankIdUser = useIsBankIdUser()

  const tabs: NavTabsObject = {
    '/': {
      label: 'Inställningar',
      component: (
        <SuspenseLoaderWrapper noPadding text="Laddar din profil...">
          <ProfileSettings />
        </SuspenseLoaderWrapper>
      )
    }
  }

  if (isBankIdUser) {
    tabs['hantera-anvandare'] = {
      label: 'Hantera användare',
      component: (
        <SuspenseLoaderWrapper noPadding text="Laddar användare...">
          <HandleUsersPage />
        </SuspenseLoaderWrapper>
      )
    }
  }

  return (
    <div
      className={classNames('Section BgColor--Red400', {
        'user-profiles-page': pathname === '/profil/hantera-anvandare'
      })}
    >
      <div className="Row Row--JustifyLeft">
        <NavTabs tabs={tabs} />
      </div>
    </div>
  )
}

export default ProfilePage
