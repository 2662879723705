import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Icon from './Icon'

type InfoType = 'error' | 'info'

type Props = {
  children: React.ReactNode
  className?: string
  small?: boolean
  type?: InfoType
}

const Info: React.FunctionComponent<Props> = ({
  children,
  className,
  small,
  type
}) => {
  return (
    <div className={classNames('Info', small && 'Info--Small', className)}>
      {type === 'error' && (
        <Icon
          name="exclamation-triangle"
          primaryColor="black"
          secondaryColor="yellow500"
        />
      )}
      {type === 'info' && (
        <Icon
          name="info-circle"
          primaryColor="black"
          secondaryColor="pink500"
        />
      )}
      {children}
    </div>
  )
}

Info.propTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  type: PropTypes.oneOf<InfoType>(['error', 'info'])
}

export default Info
