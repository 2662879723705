import { getSixMonthsAgoDate } from '../../utils/getSixMonthsAgoDate'
import { useGetTransactions } from './useGetTransactions'

const useGetTransactionsLastSixMonths = () => {
  return useGetTransactions({
    fetchQuery: {
      endDate: new Date().toISOString(),
      startDate: getSixMonthsAgoDate().toISOString()
    },
    queryOptions: { suspense: false },
    additionalQueryKeys: ['last-six-months']
  })
}

export default useGetTransactionsLastSixMonths
