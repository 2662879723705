import React, { useState } from 'react'
import isEmail from 'validator/es/lib/isEmail'
import isEmpty from 'validator/es/lib/isEmpty'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { usePutCustomer } from '../../../hooks/queryHooks/usePutCustomer'
import useIsBankIdUser from '../../../hooks/useIsBankIdUser'
import { Email } from '../../../integrations/allkort/v1/schemas/Email'
import Button from '../../elements/Button'
import { PaymentMethodChangeConfirmation } from './Components/InvoiceMethodModal'

type Props = {
  closeModal: () => void
  setConfirmationModal: (args: PaymentMethodChangeConfirmation) => void
}

const CustomerProfileEmail: React.FC<Props> = ({
  closeModal,
  setConfirmationModal
}) => {
  const isBankIdUser = useIsBankIdUser()
  const { data: customer } = useGetCustomer()

  const updateCustomerMutation = usePutCustomer({
    queryOptions: { onSettled: closeModal }
  })

  const infoMail = customer!.emails.find(
    (element) => element.emailTypeId === 'Information'
  )?.emailAddress

  const invoiceEmails = customer!.emails.filter(
    (profileEmail) => profileEmail.emailTypeId === 'InvoiceStatement'
  )

  const [emailError, setEmailError] = useState('')
  const [newEmail, setNewEmail] = useState(
    customer!.emails.find((email) => email.emailTypeId === 'InvoiceStatement')
      ?.emailAddress ?? ''
  )

  const handleInputChange = (email: string) => {
    if (!isEmail(email)) {
      setEmailError('Mailadressen ser inte rätt ut.')
    } else if (newEmail === invoiceEmails?.at(0)?.emailAddress) {
      setEmailError('Mailadressen är oförändrad')
    } else {
      setEmailError('')
    }

    setNewEmail(email)
  }

  const saveEmailInvoiceType = () => {
    const newEmails: Email[] = [
      ...customer!.emails.filter(
        (email) => email.emailTypeId !== 'InvoiceStatement'
      ),
      {
        emailAddress: newEmail,
        emailId: 0,
        emailTypeId: 'InvoiceStatement',
        sortOrder: 0
      }
    ]

    setConfirmationModal({
      newEmail,
      onConfirm: () =>
        updateCustomerMutation.mutate({
          invoiceMailingDeliveryType: 'Email',
          emails: newEmails
        }),
      newPaymentMethod: 'Email'
    })
  }

  return (
    <>
      <div className="CustomerProfileEmail Modal__Section">
        <div className="ProfileSettings__Subheading">
          <b>{'Mail'}</b>
          {'*'}
        </div>
        <input
          className="ProfileSettingsModal__InputField ProfileSettingsModal__InputField--Email"
          disabled={!isBankIdUser}
          onChange={(e) => handleInputChange(e.currentTarget.value)}
          value={newEmail}
        />
        {emailError && (
          <div className="PaymentMethod__ErrorMessage">{emailError}</div>
        )}
        {infoMail && (
          <div>
            <small>
              {'Din nuvarande huvudmail är '}
              <b> {infoMail}</b>
            </small>
          </div>
        )}
      </div>
      <div className="ProfileSettingsModal__Actions flex-column">
        <div className="d-flex justify-content-end">
          <Button
            className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Save"
            disabled={
              Boolean(emailError) ||
              isEmpty(newEmail) ||
              (newEmail === invoiceEmails?.at(0)?.emailAddress &&
                customer!.invoiceMailingDeliveryType === 'Email')
            }
            onClick={() => saveEmailInvoiceType()}
            type="button"
          >
            {'Spara'}
          </Button>
          <Button
            className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Cancel"
            onClick={closeModal}
            type="button"
          >
            {'Avbryt'}
          </Button>
        </div>
      </div>
    </>
  )
}

export default CustomerProfileEmail
