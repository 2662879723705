import classNames from 'classnames'
import React from 'react'
import Icon, { Props as IconProps } from './Icon'

export type Props = {
  className?: string
  content: React.ReactNode | string
  icon?: IconProps
  hasBackground?: boolean
  type?: 'default' | 'warning'
}

const InlineAlert: React.FC<Props> = ({
  className,
  content,
  icon,
  type,
  hasBackground
}) => {
  return content ? (
    <div
      aria-live="polite"
      className={classNames(
        {
          InlineAlert: true,
          'InlineAlert--Warning': type === 'warning',
          'InlineAlert--WithBackground': hasBackground
        },
        className
      )}
    >
      {icon && <Icon {...icon} />}
      {typeof content === 'string' ? <span>{content}</span> : content}
    </div>
  ) : (
    <></>
  )
}

export default InlineAlert
