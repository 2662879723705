/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useDevCardValues } from '../../hooks/queryHooks/useGetCards'
import Toggle from '../elements/Toggle'

type CardsDevToolsProps = {}

const CardsDevTools: React.FC<CardsDevToolsProps> = (props) => {
  const {
    values: { isLastCard },
    toggleIsLastCard
  } = useDevCardValues()

  return (
    <div style={{ maxWidth: '40rem' }}>
      <div style={{ margin: '1rem 0 2rem 0' }}>
        <h5>{'Kort'}</h5>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '.75rem'
          }}
        >
          <Toggle
            checked={Boolean(isLastCard)}
            label="Simulera sista kortet"
            name="last-card-toggle"
            onChange={toggleIsLastCard}
          />
        </div>
      </div>
    </div>
  )
}

export default CardsDevTools
