import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Page from './components/elements/Page'
import SuspenseLoaderWrapper from './components/elements/SuspenseLoaderWrapper'
import { ScrollToTop } from './components/hocs/ScrollToTop'
import Pages from './components/pages/Pages'
import { useSetupBanners } from './hooks/useSetupAlerts'
import { useUserRights } from './hooks/useUserRights'
import './styles/style.scss'

import * as Sentry from '@sentry/react'
import ScrollToAnchor from './components/elements/ScrollToAnchor'
import useSetupMatomo from './hooks/useSetupMatomo'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const App: React.FC = () => {
  useSetupMatomo()
  useSetupBanners()
  const { isSuperUser, userHasRight } = useUserRights()

  return (
    <>
      <ScrollToTop />
      <ScrollToAnchor />
      <SuspenseLoaderWrapper text="Laddar dina sidor...">
        <Page>
          <SuspenseLoaderWrapper text="Laddar dina sidor...">
            <SentryRoutes>
              <Route
                element={<Navigate to={`/profil${window.location.search}`} />}
                path="/"
              />
              <Route element={<Pages.LogoutPage />} path="/loggaut" />
              <Route
                element={<Pages.InvoiceAccountPage />}
                path="/fakturor-kontobesked/*"
              />
              <Route element={<Pages.CardsPage />} path="/kort" />
              <Route element={<Pages.ProfilePage />} path="/profil/*" />
              <Route element={<Pages.ContactPage />} path="/kontakt" />
              {userHasRight('CardsWrite') && (
                <>
                  <Route
                    element={<Pages.RequestCardPage />}
                    path="/ersattningskort"
                  />
                  <Route element={<Pages.NewCardPage />} path="/nyttkort" />
                </>
              )}
              {isSuperUser && (
                <>
                  <Route
                    element={<Pages.DeleteAccountPage />}
                    path="/avsluta-konto"
                  />
                  <Route
                    element={<Pages.AccountDeletedPage />}
                    path="/konto-avslutat"
                  />
                </>
              )}
              <Route element={<Pages.NotFoundPage />} path="*" />
            </SentryRoutes>
          </SuspenseLoaderWrapper>
        </Page>
      </SuspenseLoaderWrapper>
    </>
  )
}

export default App
