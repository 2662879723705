/* eslint-disable no-restricted-syntax */
/* eslint-disable func-style */
import classNames from 'classnames'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import isInt from 'validator/es/lib/isInt'
import { CardType } from '../../domain/enums/CardType'
import { useGetCards } from '../../hooks/queryHooks/useGetCards'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import useGetTransactionsLastSixMonths from '../../hooks/queryHooks/useGetTransactionsLastSixMonths'
import { usePostNewCard } from '../../hooks/queryHooks/usePostNewCard'
import { InputStateObject, useForm } from '../../hooks/useForm'
import Button from '../elements/Button'
import Checkbox from '../elements/Checkbox'
import Icon from '../elements/Icon'
import Input from '../elements/Input'
import Label from '../elements/Label'
import RadioGroup from '../elements/RadioGroup'

type Form = {
  cardReference2: string
  pin: string
  pinVerify: string
}

type Props = {}

export const NewCardPage: React.FC<Props> = () => {
  const { data: cards } = useGetCards()
  const navigate = useNavigate()
  const transactionsQuery = useGetTransactionsLastSixMonths()
  const { data: customer } = useGetCustomer()

  const [cardType, setCardType] = useState<CardType | ''>('')
  const [acceptCreditInfo, setAcceptCreditInfo] = React.useState(false)

  const transactionsWithinSixMonths = transactionsQuery.data?.pages
    .at(0)
    ?.payload.at(0)

  let shouldAskForCreditCheck = false

  if (
    !transactionsWithinSixMonths &&
    cardType === CardType.CreditCard &&
    !customer!.isCompany
  ) {
    shouldAskForCreditCheck = true
  }

  const { inputs, handleInputChange, isValid, validateInput, resetForm } =
    useForm<InputStateObject<Form>>({
      cardReference2: {
        value: '',
        isError: false,
        required: false
      },
      pin: {
        value: '',
        isError: false,
        required: true,
        validationFunction: (input) => {
          return {
            isValid: !pinInputError(input),
            errorMessage: pinInputError(input)
          }
        },
        errorMessage: 'Obligatoriskt fält'
      },
      pinVerify: {
        value: '',
        isError: false,
        required: true,
        validationFunction: (input, prevInputObject) => {
          return {
            isValid: !pinInputVerifyError(input, prevInputObject.pin.value),
            errorMessage: pinInputVerifyError(input, prevInputObject.pin.value)
          }
        },
        errorMessage: 'Obligatoriskt fält'
      }
    })

  const { mutate, isSuccess } = usePostNewCard({
    queryOptions: {
      onSuccess: () => {
        resetForm()
      }
    }
  })

  function pinInputError(newPin: string): string {
    if (!isInt(newPin)) {
      return 'Koden får enbart bestå av siffror'
    }
    if (newPin.length !== 4) {
      return 'Koden måste vara exakt 4 siffror.'
    }

    const pin: string = newPin
    if (/(.)\1\1\1/.test(pin)) {
      return 'Koden måste innehålla olika siffror.'
    }

    const sequence = '0123456789 9876543210'
    if (sequence.includes(pin)) {
      return 'Nummerföljder är inte tillåtet.'
    }

    return ''
  }

  function pinInputVerifyError(newPin: string, oldPin: string): string {
    if (oldPin !== newPin) {
      return 'Koden är inte samma.'
    }

    return ''
  }

  const handleCardTypeChange = (event: React.ChangeEvent): void => {
    const element = event.currentTarget as HTMLInputElement

    setCardType(element.value as CardType)
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    mutate({
      cardReference1: 'Ignorera fältet',
      cardReference2: inputs.cardReference2.value.trim(),
      cardType: cardType as CardType,
      message: 'Beställning av nytt kort',
      pin: inputs.pin.value.trim(),
      creditCheckApproved: acceptCreditInfo
    })
  }

  return (
    <div className="Section RequestCardPage BgColor--Red400">
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          {isSuccess ? (
            <div>
              <h1 style={{ marginBottom: 64 }}>{'Tack!'}</h1>
              <p>
                <b>{'Din förfrågan om ett nytt kort har tagits emot.'}</b>
              </p>
              <p>
                {
                  'Det nya kortet kommer att skickas ut och dyker därefter upp under fliken kort.'
                }
              </p>
              <Button color="red200" url="/kort">
                {'Mina kort'}
              </Button>
            </div>
          ) : (
            <form
              className="Column Column--Md6 Column--Lg6 Column--AlignStart"
              onSubmit={handleSubmit}
            >
              <h1>{'Nytt kort'}</h1>
              <p style={{ marginBottom: 16 }}>
                {'Fyll i fälten nedan, så skickar vi ett nytt kort till dig.'}
              </p>
              <p className="Card__PriceBanner">
                <Icon
                  className="Card__Icon"
                  name="check"
                  primaryColor="green500"
                  secondaryColor="green500"
                />
                <b>{'Kostnadsfritt!'}</b>
              </p>
              <div className="NewCardPage__Area">
                <RadioGroup
                  buttons={[
                    {
                      text: 'Nytt kort',
                      value: '/nyttkort'
                    },
                    {
                      text: 'Ersättningskort',
                      value: '/ersattningskort'
                    }
                  ]}
                  className="RequestCardPage__RadioGroup"
                  onChange={(href) => {
                    navigate(href)
                  }}
                  value="/nyttkort"
                />
                <div className="NewCardPage__CardSource">
                  <Label text="Välj en korttyp:" vertical>
                    <select
                      className={classNames(
                        {
                          Dropdown: true
                        },
                        'CardSource__Dropdown NewCardPage__Dropdown'
                      )}
                      onBlur={handleCardTypeChange}
                      onChange={handleCardTypeChange}
                      value={cardType}
                    >
                      <option value="">{'Välj kort...\r'}</option>
                      <option value={CardType.SmartCard}>{'Smartkort'}</option>
                      <option value={CardType.CreditCard}>{'Tankkort'}</option>
                    </select>
                  </Label>
                </div>
                {cardType === 'CreditCard' && cards?.tankCards.length === 0 && (
                  <p className="CardsPage__Disclaimer">
                    {'En kreditupplysning tas vid ansökan om tankkort.'}
                  </p>
                )}
                <Input
                  className="NewCardPage__TextField"
                  fullWidth
                  label="Kortreferens (syns på fakturan/Kontobesked):"
                  name="cardReference2"
                  onChange={handleInputChange}
                  type="text"
                  value={inputs.cardReference2.value}
                />
                <div className="NewCardPage__PinArea">
                  <Input
                    className="NewCardPage__PinTextField"
                    error={
                      inputs.pin?.isError ? inputs.pin.errorMessage : undefined
                    }
                    fullWidth
                    label="Ny pinkod:"
                    maxLength={4}
                    name="pin"
                    onBlur={() => validateInput('pin')}
                    onChange={handleInputChange}
                    type="text"
                    value={inputs.pin.value}
                  />
                  <Input
                    className="NewCardPage__PinTextField"
                    error={
                      inputs.pinVerify?.isError
                        ? inputs.pinVerify.errorMessage
                        : undefined
                    }
                    fullWidth
                    inputMode="numeric"
                    label="Bekräfta ny pinkod:"
                    maxLength={4}
                    name="pinVerify"
                    onBlur={() => validateInput('pinVerify')}
                    onChange={(e) => {
                      handleInputChange(e)
                      validateInput('pinVerify')
                    }}
                    type="text"
                    value={inputs.pinVerify.value}
                  />
                </div>
                {shouldAskForCreditCheck && (
                  <>
                    <div>
                      <Checkbox
                        checked={acceptCreditInfo}
                        className="NewCardsPage__CheckBox"
                        labelClassName="NewCardsPage__Label"
                        onChange={(checked) => setAcceptCreditInfo(checked)}
                        text="Jag godkänner ny kreditupplysning"
                        title="Kreditupplysning"
                      />
                    </div>
                    <p className="NewCardsPage__Disclaimer">
                      {'En ny kreditupplysning måste tas'} <br />
                      {' efter inaktivitet på 6 mån.'}
                    </p>
                  </>
                )}
                <div style={{ marginTop: 12 }}>
                  <Button
                    disabled={
                      !isValid ||
                      !cardType ||
                      (shouldAskForCreditCheck && !acceptCreditInfo)
                    }
                    type="submit"
                  >
                    {'Beställ nytt kort'}
                  </Button>
                  <Button color="red200" url="/kort">
                    {'Tillbaka'}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewCardPage
