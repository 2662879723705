import React, { InputHTMLAttributes } from 'react'
import Input, { InputProps } from '../../../../elements/Input'

type EInvoiceInputFieldProps = InputProps & {
  customError?: string
  labelElement?: JSX.Element
}
type EInvoiceInputFieldType = React.FC<
  InputHTMLAttributes<HTMLInputElement> & EInvoiceInputFieldProps
>

export const EInvoiceInputField: EInvoiceInputFieldType = ({
  customError,
  labelElement,
  ...props
}) => {
  return (
    <>
      {labelElement}
      <Input {...props} />
      <p
        aria-live="assertive"
        className="ProfileSettingsModal__ErrorMessage m-0"
        style={{ height: customError ? '' : 0 }}
      >
        {customError}
      </p>
    </>
  )
}
