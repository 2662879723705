import {
  DepositHistoryTransactionQuery,
  TransactionProxy,
  TransactionQuery
} from '../proxies/TransactionProxy'

export class BalanceHistoryRepository {
  private apiToken: string

  constructor(apiToken: string) {
    this.apiToken = apiToken
  }

  async getTransactionHistory(queryObj: TransactionQuery) {
    const proxy = new TransactionProxy(this.apiToken)
    const apiResult = await proxy.getTransactions(queryObj)

    return apiResult
  }

  async getBalanceHistory(queryObj: DepositHistoryTransactionQuery) {
    const proxy = new TransactionProxy(this.apiToken)
    const apiResult = await proxy.getBalanceHistory(queryObj)

    return apiResult
  }
}
