/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-multi-comp */
import classNames from 'classnames'
import React, { AriaAttributes } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useUserRights } from '../../hooks/useUserRights'
import {
  UserProfileSortingParam,
  UserProfileSortingString
} from '../../integrations/allkort/v1/repositories/UserProfileRepository'
import Icon from '../elements/Icon'
import ToolTipWrapper from '../elements/ToolTipWrapper'
type UserProfileTableHeadProps = {
  handleSortingChange: (
    newSortingString: UserProfileSortingString | null
  ) => void
}
type SortableTitles = 'Förnamn' | 'Efternamn' | 'E-mail'
type SortableTitlesToSortingParams = Record<
  SortableTitles,
  UserProfileSortingParam
>

const sortableTitlesToSortingParams: SortableTitlesToSortingParams = {
  'E-mail': 'Email',
  Efternamn: 'LastName',
  Förnamn: 'FirstName'
} as const

export type UserProfileColumnTitle =
  | 'Personnummer'
  | 'Förnamn'
  | 'Efternamn'
  | 'E-mail'
  | 'Telefonnummer'
  | 'Administratör'
  | ''

type UserProfileTableHeadItemProps = {
  colName: UserProfileColumnTitle
  handleSortingChange: (
    newSortingString: UserProfileSortingString | null
  ) => void
}
export const UserProfileTableHeadItem: React.FC<
  UserProfileTableHeadItemProps
> = ({ colName, handleSortingChange }) => {
  const [searchParams] = useSearchParams()
  const isMobile = useIsMobile()
  const sortingParam = sortableTitlesToSortingParams[colName as SortableTitles]
  const isSortableColumn = sortableTitlesToSortingParams.hasOwnProperty(colName)
  const isCurrentlyAscSorting = decodeURIComponent(
    searchParams.toString()
  ).includes('+asc')
  const isCurrentSortingParam = Boolean(
    searchParams.get('sorting') ===
      `${sortingParam}+${isCurrentlyAscSorting ? 'asc' : 'des'}`
  )

  const sortingString: UserProfileSortingString | null =
    !isCurrentlyAscSorting && isCurrentSortingParam
      ? null
      : `${sortingParam}+${
          isCurrentlyAscSorting && isCurrentSortingParam ? 'des' : 'asc'
        }`

  const sortingLabel =
    isCurrentSortingParam && !isCurrentlyAscSorting
      ? 'Nollställ sorteringen'
      : `Sortera användare efter ${colName.toLowerCase()} i ${
          isCurrentlyAscSorting ? 'stigande' : 'fallande'
        } ordning`

  const sortAria: AriaAttributes['aria-sort'] = isCurrentSortingParam
    ? isCurrentlyAscSorting
      ? 'ascending'
      : 'descending'
    : 'none'

  return (
    <th
      aria-sort={sortAria}
      className={classNames('user-profile-table__head-cell', {
        'user-profile-table__head-cell--sortable': isSortableColumn,
        'user-profile-table__head-cell--sortable-active': isCurrentSortingParam,
        'user-profile-table__head-cell--sortable-desc':
          isCurrentSortingParam && !isCurrentlyAscSorting
      })}
    >
      {isSortableColumn ? (
        <button
          aria-label={sortingLabel}
          onClick={() => handleSortingChange(sortingString)}
        >
          <ToolTipWrapper
            enabled={!isMobile} // Only show tooltips on desktop
            noHoverState
            text={sortingLabel}
            tooltipPlacement="bottom"
          >
            <>
              {colName}
              <span style={{ position: 'relative' }}>
                <Icon name="sort" type="line" />
                <Icon name="sort-down" type="line" />
                <Icon name="sort-up" type="line" />
              </span>
            </>
          </ToolTipWrapper>
        </button>
      ) : (
        colName
      )}
    </th>
  )
}

export const UserProfileTableHead: React.FC<UserProfileTableHeadProps> = ({
  handleSortingChange
}) => {
  const { userHasRight } = useUserRights()
  const headerColumns: UserProfileColumnTitle[] = userHasRight(
    'UserProfilesWrite'
  )
    ? [
        'Personnummer',
        'Förnamn',
        'Efternamn',
        'E-mail',
        'Telefonnummer',
        'Administratör',
        '',
        ''
      ]
    : ['Förnamn', 'Efternamn', 'E-mail', 'Telefonnummer', '', '', '']

  return (
    <thead className="user-profile-table__head">
      <tr className="user-profile-table__head-row">
        {headerColumns.map((colName, index) => (
          <UserProfileTableHeadItem
            colName={colName === 'Administratör' ? '' : colName}
            handleSortingChange={handleSortingChange}
            key={colName || index}
          />
        ))}
      </tr>
    </thead>
  )
}
