import React from 'react'
import UserProfileTable from '../views/UserProfileTable'

type AdminPageProps = {}

const HandleUsersPage: React.FC<AdminPageProps> = () => {
  return (
    <>
      <h1 style={{ marginBottom: 48 }}>{'Hantera användare'}</h1>
      <UserProfileTable />
    </>
  )
}

export default HandleUsersPage
