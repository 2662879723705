import React from 'react'
import Column from '../elements/Column'
import ContentBox from '../elements/ContentBox'
import Row from '../elements/Row'
import Section from '../elements/Section'

const content = `
  <h1>
    Sidan kunde inte hittas
  </h1>
  <p>
    Söker du något särskilt? Vår kundservice finns tillgänglig på telefon alla dagar i
    veckan, för att ge personlig support.
  </p>
  <p>
    <a href="/">
    <br>
      « Gå till startsidan
    </a>
  </p>`

const NotFoundPage: React.FC<{}> = () => {
  return (
    <Section color="red400">
      <Row>
        <Column lg={6}>
          <ContentBox content={content} />
        </Column>
      </Row>
    </Section>
  )
}

export default NotFoundPage
