import React from 'react'
import Button from '../elements/Button'

export type ConfirmationModalData = {
  isOpen: boolean
  title: string
  message?: string
  content?: string | React.ReactNode
  confirmText: string
  rejectText: string
  confirmCallback: () => void
  rejectCallback: () => void
  destructive?: boolean
}

export const emptyConfirmationModalState = {
  isOpen: false,
  title: '',
  confirmText: '',
  rejectText: '',
  confirmCallback: () => undefined,
  rejectCallback: () => undefined
} as ConfirmationModalData

interface ConfimrationModalProps {
  confirmationModal: ConfirmationModalData
}

export const ConfirmationModal: React.FC<ConfimrationModalProps> = ({
  confirmationModal
}) => {
  const {
    title,
    message,
    confirmText,
    rejectText,
    content,
    confirmCallback,
    rejectCallback,
    destructive
  } = confirmationModal
  return (
    <>
      <div className="Modal__Section ConfirmationModal__Row pb-2">
        <h5>{title}</h5>
        {message && <p>{message}</p>}
      </div>
      {content && (
        <div className="Modal__Section ConfirmationModal__Row">{content}</div>
      )}
      <div className="ConfirmationModal__Actions Modal__Section">
        <Button
          className={`ConfirmationModal__Button${
            destructive ? '' : ' ConfirmationModal__Button--Alert'
          }`}
          onClick={() => rejectCallback()}
        >
          {rejectText}
        </Button>
        <Button
          className={`ConfirmationModal__Button${
            destructive ? ' ConfirmationModal__Button--Alert' : ''
          }`}
          onClick={() => confirmCallback()}
        >
          {confirmText}
        </Button>
      </div>
    </>
  )
}
