import React, { useEffect } from 'react'
import { useGetFooter } from '../../hooks/queryHooks/useGetFooter'

type Props = {}

const Footer: React.FC<Props> = () => {
  const { isLoading, data: footerMarkup } = useGetFooter()

  useEffect(() => {
    if (!isLoading && footerMarkup) {
      const scriptUrl = `${
        import.meta.env.VITE_WORDPRESS_URL
      }/content/themes/qstar-web/build/footerAccordions.js`

      const script = document.createElement('script')
      script.src = scriptUrl
      script.async = true

      document.head.appendChild(script)

      return () => {
        document.head.removeChild(script)
      }
    }
  }, [isLoading, footerMarkup])

  return footerMarkup ? (
    <div dangerouslySetInnerHTML={{ __html: footerMarkup }} />
  ) : (
    <></>
  )
}
export default Footer
