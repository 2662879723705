/* eslint-disable react/no-multi-comp */
/* eslint-disable no-confusing-arrow */
import classNames from 'classnames'
import React from 'react'
import { Statement } from '../../hooks/queryHooks/useGetStatements'
import Link from '../elements/Link'

type InvoiceLinkProps = {
  statement: Statement
  isOdd: boolean
}

const StatementLink: React.FC<InvoiceLinkProps> = ({ statement, isOdd }) => {
  return (
    <tr
      className={classNames({
        Invoice__Row: true,
        'Invoice__Row--IsOdd': isOdd,
        'Invoice__Row--Transaction': true
      })}
    >
      <td className="Invoice__Cell Invoice__Cell--Date" colSpan={3}>
        <Link
          className="Link--Subtle"
          href={`${
            import.meta.env.VITE_BACKEND_URL
          }/api/Statements/Kontobesked ${statement.statementNo}.pdf`}
          openNewTab
          styled
        >
          {'Se kontobesked'}
        </Link>
      </td>
    </tr>
  )
}

export default StatementLink
