import { SaveState } from '../enums/SaveState'

export type PropertyErrorMap<P extends string = string> = {
  [K in P]?: string | (string | undefined)[]
}

export class EditableModel<P extends string = string> {
  saveState: SaveState = SaveState.OK
  errorMessage: string = ''
  propertyErrors: PropertyErrorMap<P> = {}

  isSaving(): boolean {
    return SaveState.Saving === this.saveState
  }

  setSaving(): void {
    this.saveState = SaveState.Saving
  }

  addError(property: P, message: string): void {
    this.propertyErrors[property] = message
  }

  resetErrors(cardReference: string = ''): void {
    if (cardReference) {
      for (const key in this.propertyErrors) {
        if (key === cardReference) {
          this.propertyErrors[key] = ''
        }
      }
    } else {
      this.propertyErrors = {}
    }
    this.errorMessage = ''
  }

  hasErrors(): boolean {
    for (const key in this.propertyErrors) {
      if (Object.prototype.hasOwnProperty.call(this.propertyErrors, key)) {
        return true
      }
    }

    return false
  }

  isValid(): boolean {
    return !this.hasErrors()
  }
}
