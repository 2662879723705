import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { CardReplacement } from '../../integrations/allkort/v1/schemas/Card'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UsePostCardReplacementArgs = {
  queryOptions?: UseMutationOptions<
    void,
    ReplacementCardMutationArgs,
    unknown,
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}

type ReplacementCardMutationArgs = CardReplacement

export const usePostCardReplacement = ({
  queryOptions = {},
  extendedOnSuccessFunction,
  extendedOnErrorFunction
}: UsePostCardReplacementArgs = {}) => {
  const invalidateQuery = useInvalidateQuery()
  const { addToast } = useToasts()
  const { cardRepo } = useRepos()

  const mutation = useMutation(
    (replacementCardArgs: ReplacementCardMutationArgs) =>
      cardRepo.postCardReplacement(replacementCardArgs),
    {
      onError: () => {
        addToast({
          message:
            'Lyckades inte beställa ersättningskort. ' +
            'Försök igen senare eller kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: (status, cardArgs) => {
        addToast(`Ersättningskort för ${cardArgs.cardReference1} beställt`)
        invalidateQuery('cards')

        extendedOnSuccessFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}
