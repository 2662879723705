export const getUrlParam = (name: string): string | null => {
  const url = new URL(window.location.href)
  return url.searchParams.get(name)
}

export const getUrlParams = (): Record<string, string> => {
  const url = new URL(window.location.href)
  const params: Record<string, string> = {}

  url.searchParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
