import { useQueryClient } from '@tanstack/react-query'
import { QueryKey } from './QueryKeys'

const useInvalidateQuery = () => {
  const queryClient = useQueryClient()

  const invalidateQuery = (keys: QueryKey | QueryKey[]) => {
    if (typeof keys === 'string') {
      queryClient.invalidateQueries([keys])
    } else {
      queryClient.invalidateQueries(keys)
    }
  }

  return invalidateQuery
}

export default useInvalidateQuery
