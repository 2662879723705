import { InvoicesModel } from '../../../../domain/models/InvoicesModel'
import { mockInvoices } from '../../../../legacy/mock/data/mockInvoices'
import { InvoiceModelMapper } from '../mappers/InvoiceModelMapper'
import { InvoiceProxy, InvoiceQuery } from '../proxies/InvoiceProxy'

export const INVOICE_PAGE_SIZE = 10

export class InvoicesRepository {
  private apiToken: string
  private proxy: InvoiceProxy

  constructor(apiToken: string) {
    this.apiToken = apiToken
    this.proxy = new InvoiceProxy(apiToken)
  }

  async getInvoices(query: InvoiceQuery): Promise<InvoicesModel> {
    if (
      process.env.NODE_ENV === 'development' &&
      import.meta.env.VITE_DEBUG_USE_MOCK_DATA === 'true'
    ) {
      return mockInvoices
    }

    const proxy = new InvoiceProxy(this.apiToken)
    const invoices = await proxy.getInvoices({
      PageSize: INVOICE_PAGE_SIZE,
      ...query
    })
    const mapper = new InvoiceModelMapper()

    return new InvoicesModel(
      invoices.payload.map((invoice) => mapper.getInvoiceModel(invoice, [])),
      invoices.nextPage,
      invoices.hasMore
    )
  }
}
