/* eslint-disable no-nested-ternary */
import React from 'react'
import Button from '../../elements/Button'
import InlineAlert from '../../elements/InlineAlert'
import Modal from '../../elements/Modal'
import { CheckboxAlternative } from './HasRemainingBalance'
import { BankInfo } from './ReclaimBalance'

type DeleteAccountModalProps = {
  bankInfo: BankInfo | undefined
  hasBalance: boolean
  checkedValue?: CheckboxAlternative
  onCloseModal: () => void
  onConfirmDelete: () => void
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = (props) => {
  const { bankInfo, checkedValue, onConfirmDelete, onCloseModal, hasBalance } =
    props

  return (
    <Modal onClose={onCloseModal}>
      <div
        className="DeleteAccountPage__Modal"
        style={{ maxWidth: 'fit-content' }}
      >
        <div className="DeleteAccountPage__Modal__Info">
          <h5 className="">{'Bekräfta avslut av konto'}</h5>
          {hasBalance ? (
            checkedValue === 'charity' ? (
              <>
                <small style={{ fontStyle: 'italic' }}>
                  {
                    'Jag är medveten om att Qstar väljer mottagare, att gåvan inte sker i '
                  }
                  {
                    'mitt namn och därför inte berättigar till skattereduktion samt att jag '
                  }
                  {
                    'inte längre har någon rätt till saldo på Smartkortet som avslutas.\r'
                  }
                </small>
              </>
            ) : (
              <>
                <p>
                  {
                    'Ditt återstående saldo kommer att utbetalas till kontonummer: '
                  }
                  <b>{`${bankInfo?.clearingNo}-${bankInfo?.accountNo}`}</b>
                </p>
              </>
            )
          ) : null}

          <InlineAlert
            className="Profile__Alert"
            content={<b>{'OBS: Detta går inte att ångra senare.'}</b>}
            icon={{
              name: 'exclamation-triangle',
              primaryColor: 'black',
              secondaryColor: 'yellow500'
            }}
            type="default"
          />
        </div>
        <div className="mt-2 d-flex justify-content-end">
          <Button onClick={onConfirmDelete}>{'Ja, bekräfta'}</Button>
          <Button className="Profile__CancelButton" onClick={onCloseModal}>
            {'Stäng'}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteAccountModal
