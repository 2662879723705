/* eslint-disable require-atomic-updates */
import { SaveState } from '../../../../domain/enums/SaveState'
import { UseCustomerData } from '../../../../hooks/queryHooks/useGetCustomer'
import { NewCard } from '../../../../integrations/allkort/v1/schemas/NewCard'
import { getArrayDifference } from '../../../../utils/getArrayDifference'
import { CardProxy } from '../proxies/CardProxy'
import { CustomerProxy, CustomerUpdate } from '../proxies/CustomerProxy'

export type updateCustomerArgs = {
  valuesToUpdate: CustomerUpdate
  oldCustomer: UseCustomerData
}

export class ProfileRepository {
  private apiToken: string

  constructor(apiToken: string) {
    this.apiToken = apiToken
  }

  async saveCardNew(model: NewCard): Promise<SaveState> {
    let saveState: SaveState = SaveState.OK
    try {
      const proxy = new CardProxy(this.apiToken)
      const {
        cardReference1,
        cardReference2,
        cardType,
        message,
        pin,
        creditCheckApproved
      } = model

      // API claims that this returns a card object, but the body is empty upon successful requests
      await proxy.postNewCard({
        cardReference1: cardReference1 ?? '',
        cardReference2: cardReference2 ?? '',
        cardType,
        pin: pin ?? '',
        message: message ?? 'Beställning av nytt kort',
        creditCheckApproved
      })
      saveState = SaveState.OK
    } catch (ex) {
      saveState = SaveState.Failed
      throw new Error(ex as string)
    }

    return saveState
  }

  async updateCustomer(args: updateCustomerArgs): Promise<unknown> {
    const { valuesToUpdate, oldCustomer } = args
    const proxy = new CustomerProxy(this.apiToken)
    const updateCustomerObject: CustomerUpdate = {}

    const phonesIdsToRemove = valuesToUpdate.phones
      ? (getArrayDifference(
          oldCustomer.phones.map((phone) => phone.phoneId),
          valuesToUpdate.phones.map((phone) => phone.phoneId)
        ) as number[])
      : null

    const emailIdsToRemove = valuesToUpdate.emails
      ? (getArrayDifference(
          oldCustomer.emails.map((email) => email.emailId),
          valuesToUpdate.emails.map((email) => email.emailId)
        ) as number[])
      : null

    await Promise.all(
      [
        phonesIdsToRemove?.map((phoneId) => proxy.deletePhone(phoneId)),
        emailIdsToRemove?.map((emailId) => proxy.deleteEmail(emailId))
      ].filter((p) => p)
    )

    Object.keys(valuesToUpdate).forEach((key) => {
      const valueToUpdate = key as keyof CustomerUpdate
      // @ts-ignore
      updateCustomerObject[valueToUpdate] = valuesToUpdate[valueToUpdate]
    })

    const customer = await proxy.putCustomer(updateCustomerObject)

    return customer
  }
}
