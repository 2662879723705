import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AccountInformationModel } from '../../domain/models/AccountInformationModel'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetAccountInformation
export type GetAccountInformationParams = {
  queryOptions?: Omit<
    UseQueryOptions<
      AccountInformationModel,
      unknown,
      AccountInformationModel,
      unknown[]
    >,
    'initialData'
  >
  additionalQueryKeys?: unknown[]
}

export const useGetAccountInformation = (
  options: GetAccountInformationParams = {
    queryOptions: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, additionalQueryKeys = [] } = options
  const { accountInformationRepo } = useRepos()

  const query = useQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: () => accountInformationRepo.getAccountInformation(),
    keepPreviousData: true,
    ...queryOptions
  })

  return query
}
