import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { SaveState } from '../../domain/enums/SaveState'
import { CardType } from '../../integrations/allkort/v1/schemas/CardType'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UsePostCardReplacementArgs = {
  queryOptions?: UseMutationOptions<
    SaveState,
    unknown,
    NewCardMutationArgs,
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}
type NewCardMutationArgs = {
  cardType: CardType
  cardReference1: string
  cardReference2: string
  message: string
  pin: string | null
  creditCheckApproved: boolean
}

export const usePostNewCard = ({
  queryOptions = {},
  extendedOnSuccessFunction,
  extendedOnErrorFunction
}: UsePostCardReplacementArgs = {}) => {
  const invalidateQuery = useInvalidateQuery()
  const { addToast } = useToasts()
  const { profileRepo } = useRepos()

  const mutation = useMutation(
    (newCardArgs: NewCardMutationArgs) => profileRepo.saveCardNew(newCardArgs),
    {
      onError: () => {
        addToast({
          message:
            'Lyckades inte beställa nytt kort. ' +
            'Försök igen senare eller kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: (status, cardArgs) => {
        addToast(`Kortet ${cardArgs.cardReference1} är nu beställt`)
        invalidateQuery('cards')

        extendedOnSuccessFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}
