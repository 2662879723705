/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Input from '../../elements/Input'

export interface BankInfo {
  clearingNo: string
  accountNo: string
  hasClearingError: boolean
  hasAccountNoError: boolean
  customError: string
}

type ReclaimBalanceProps = {
  bankInfo: BankInfo
  inputFieldValue: (bankInfo: BankInfo) => void
}

const ReclaimBalance: React.FC<ReclaimBalanceProps> = (props) => {
  const { bankInfo, inputFieldValue } = props

  const checkClearingNumberValidity = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const clearingNo = event.target.value
    let hasClearingError = false
    let customError = ''

    if (clearingNo.length < 4 || clearingNo.length > 5) {
      customError = 'Ett clearingnummer är mellan 4 och 5 siffor'
      hasClearingError = true
    }

    inputFieldValue({ ...bankInfo, clearingNo, customError, hasClearingError })
  }

  const checkAccountNumberValidity = (
    event: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    const accountNo = event.target.value
    let hasAccountNoError = false
    let customError = ''

    if (accountNo.length < 5 || accountNo.length > 11) {
      customError = 'Ett kontonummer är mellan 5 och 11 '
      hasAccountNoError = true
    }

    inputFieldValue({ ...bankInfo, accountNo, customError, hasAccountNoError })
  }

  return (
    <>
      <Row className="DeleteAccountPage__Form">
        <Col xs={4}>
          <p className="mb-0">{'Clearingnummer'}</p>
          <Input
            className="DeleteAccountPage__Input mb-0"
            name="invoiceExchance"
            onChange={checkClearingNumberValidity}
            required
            type="number"
            value={bankInfo.clearingNo}
          />
        </Col>
        <Col className="ps-0" xs={6}>
          <p className="mb-0">{'Kontonummer'}</p>
          <Input
            className="DeleteAccountPage__Input mb-0"
            name="invoiceExchance2"
            onChange={checkAccountNumberValidity}
            type="text"
            value={bankInfo.accountNo}
          />
        </Col>
        <Col xs={10}>
          <p
            aria-live="assertive"
            className={`ProfileSettingsModal__ErrorMessage mt-2 ${
              bankInfo.customError ? 'DeleteAccountPage__Error' : ''
            } `}
            style={{ height: bankInfo.customError ? '' : 0 }}
          >
            {bankInfo.customError}
          </p>
        </Col>
      </Row>
    </>
  )
}

export default ReclaimBalance
