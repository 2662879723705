import AuthorizePage from './AuthorizePage'
import CallbackPage from './CallbackPage'
import CardsPage from './CardsPage'
import ContactPage from './ContactPage'
import CreatePasswordPage from './CreatePasswordPage'
import AccountDeletedPage from './DeleteAccount/AccountDeletedPage'
import DeleteAccountPage from './DeleteAccount/DeleteAccountPage'
import ForgotPasswordPage from './ForgotPasswordPage'
import HandleUsersPage from './HandleUsersPage'
import InvoiceAccountPage from './InvoiceAccountPage'
import LoginPage from './LoginPage'
import LogoutPage from './LogoutPage'
import NewCardPage from './NewCardPage'
import NotFoundPage from './NotFoundPage'
import ProfilePage from './ProfilePage'
import RequestCardPage from './RequestCardPage'
import ResetPasswordPage from './ResetPasswordPage'

const Pages = {
  AccountDeletedPage,
  AuthorizePage,
  CallbackPage,
  CardsPage,
  ContactPage,
  CreatePasswordPage,
  DeleteAccountPage,
  ForgotPasswordPage,
  LoginPage,
  LogoutPage,
  NewCardPage,
  NotFoundPage,
  ProfilePage,
  ResetPasswordPage,
  RequestCardPage,
  HandleUsersPage,
  InvoiceAccountPage
}

export default Pages
