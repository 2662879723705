import React from 'react'

const Wrapper = (props: {
  children: React.ReactNode
  if: unknown
  wrap: (children: React.ReactNode) => React.ReactElement
}): React.ReactElement => {
  return props.if ? props.wrap(props.children) : <>{props.children}</>
}

export default Wrapper
