import classNames from 'classnames'
import React from 'react'
import ReactModal from 'react-modal'
import Icon from './Icon'

type Props = {
  className?: string
  onClose: () => void
}

const Modal: React.FC<Props> = ({ children, className, onClose }) => {
  const appElement = document.getElementById('App') as HTMLElement

  return (
    <ReactModal
      appElement={appElement}
      className={classNames('Modal', className)}
      isOpen
      onRequestClose={onClose}
    >
      <button className="Modal__CloseButton" onClick={onClose} type="button">
        <Icon name="times" />
      </button>
      <div className="Modal__Content">{children}</div>
    </ReactModal>
  )
}

export default Modal
