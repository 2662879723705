/* eslint-disable no-negated-condition */
import classNames from 'classnames'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import slugify from 'slugify'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import { MenuItem } from '../../legacy/mock/components/menuItems'
import { handleNewLine } from '../../utils/formatText'
import Icon from './Icon'
import Link from './Link'

type Props = {
  headerItems: MenuItem[]
  isCompany: boolean
  sideItems: MenuItem[]
}

const ANIMATION_MIN_DURATION = 0.3
const ANIMATION_ITEM_DURATION = 0.1

const getAnimationDuration = (index: number): string => {
  return `${(ANIMATION_MIN_DURATION + index * ANIMATION_ITEM_DURATION).toFixed(
    1
  )}s`
}

const MobileMenu: React.FC<Props> = ({ headerItems, sideItems, isCompany }) => {
  const [active, setActive] = useState(false)

  const handleLinkClick = (): void => {
    setActive(false)
  }

  const location = useLocation()
  const { data: customer } = useGetCustomer()

  const handleMenuClick = (): void => {
    setActive((oldState) => !oldState)
  }

  const createItems = (
    items: MenuItem[],
    additionalClasses: string[],
    renderIcon: boolean,
    animationStartIndex: number
  ): JSX.Element[] => {
    return items.map((item, i) => {
      const slug = slugify(item.title).toLowerCase()
      return (
        <li
          className={classNames('MobileMenu__Item', ...additionalClasses)}
          data-name={slug}
          key={item.url ? item.url : item.title}
          style={
            !item.shouldBeLink
              ? {}
              : {
                  animationDuration: getAnimationDuration(
                    animationStartIndex + i
                  )
                }
          }
        >
          {renderIcon && item.icon && <Icon {...item.icon} />}
          <Link 
            href={`/${item.url}`}
            onClick={handleLinkClick}
            className={
            location.pathname.includes(`/${item.url}`) &&
              item.title !== customer!.name
                ? 'active'
                : ''
          }>
            <span
              aria-label={item.title}
              className={!item.shouldBeLink ? '' : 'MobileMenu__LinkText'}
            >
              {handleNewLine(item.title).length > 1
                ? handleNewLine(item.title).map((t) => (
                    <p className="m-0" key={item.title + t}>
                      {t}
                    </p>
                  ))
                : item.title}
            </span>
          </Link>
        </li>
      )
    })
  }

  const classItems = []
  const nonClassItems = []

  for (const item of sideItems) {
    if (item.classes && item.classes.length >= 1) {
      classItems.push(item)
    } else {
      nonClassItems.push(item)
    }
  }

  return (
    <nav className={classNames('MobileMenu', { 'MobileMenu--Active': active })}>
      <div className="MobileMenu__LogoContainer">
        <div className="d-flex justify-content-center align-items-center flex-column MobileMenu__LogoBox">
          <Link ariaLabel="Qstars logotyp" href="/">
            <img
              alt="Qstars logotyp"
              className="MobileMenu__Logo"
              src={
                active
                  ? '/static/images/qstar_logo_red.png'
                  : '/static/images/qstar_logo_yellow.png'
              }
            />
          </Link>
          {isCompany && (
            <div className="MobileMenu__CompanyHeader">{'FÖRETAG'}</div>
          )}
        </div>
        <button
          aria-label="Öppna meny"
          className="MobileMenu__ToggleButton"
          onClick={handleMenuClick}
          type="button"
        >
          <span className="MobileMenu__MenuText">
            {active ? 'Stäng meny' : 'Öppna meny'}
          </span>
          <Icon
            className="MobileMenu__ButtonIcon MobileMenu__ButtonIcon--Open"
            name="grip-lines"
            primaryColor="yellow500"
            secondaryColor="yellow500"
          />
          <Icon
            className="MobileMenu__ButtonIcon MobileMenu__ButtonIcon--Close"
            name="times"
            primaryColor="black"
            secondaryColor="black"
          />
        </button>
      </div>
      <div className="MobileMenu__Content">
        <ul className="List--Unstyled MobileMenu__Items MobileMenu__Items--Navigation">
          {createItems(headerItems, [], true, 0)}
        </ul>
        <hr className="MobileMenu__HorizontalRule" />
        <ul className="List--Unstyled MobileMenu__Items MobileMenu__Items--Subtle">
          {createItems(
            classItems,
            ['MobileMenu__Item--Cta'],
            true,
            headerItems.length
          )}
          <hr className="MobileMenu__HorizontalRule" />
          {createItems(
            nonClassItems,
            [],
            false,
            headerItems.length + classItems.length
          )}
        </ul>
      </div>
    </nav>
  )
}

export default MobileMenu
