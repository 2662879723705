import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { updateCustomerArgs } from '../../integrations/allkort/v1/repositories/ProfileRepository'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import { useGetCustomer } from './useGetCustomer'
import useInvalidateQuery from './useInvalidateQuery'

type UsePutCustomerArgs = {
  queryOptions?: UseMutationOptions<
    unknown,
    updateCustomerArgs['valuesToUpdate'],
    updateCustomerArgs['valuesToUpdate'],
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}
export type PutCardMutationArgs = updateCustomerArgs['valuesToUpdate']
export const usePutCustomer = (args: UsePutCustomerArgs = {}) => {
  const {
    queryOptions = {},
    extendedOnSuccessFunction,
    extendedOnErrorFunction
  } = args
  const { data: customer } = useGetCustomer()
  const { profileRepo } = useRepos()
  const { addToast } = useToasts()
  const invalidateQuery = useInvalidateQuery()

  const mutation = useMutation(
    (updatedCustomerArgs: PutCardMutationArgs) =>
      profileRepo.updateCustomer({
        oldCustomer: customer!,
        valuesToUpdate: updatedCustomerArgs
      }),
    {
      onError: () => {
        addToast({
          message:
            'Misslyckades att spara kort. Försök gärna igen senare. ' +
            'Om problemet består, vänligen kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: () => {
        addToast('Din profil är uppdaterad')
        invalidateQuery('customer')

        extendedOnSuccessFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}
