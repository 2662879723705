/* eslint-disable no-confusing-arrow */
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions
} from '@tanstack/react-query'
import { CardType } from '../../domain/enums/CardType'
import { TransactionsModel } from '../../domain/models/transactionsModel'
import { TransactionQuery } from '../../integrations/allkort/v1/proxies/TransactionProxy'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetTransactions

type UserGetInvoicesParams = {
  queryOptions?: UseInfiniteQueryOptions<
    TransactionsModel,
    unknown,
    TransactionsModel
  >
  fetchQuery?: TransactionQuery
  additionalQueryKeys?: unknown[]
}

export const useGetBankCardTransactions = (
  options: UserGetInvoicesParams = {
    queryOptions: {},
    fetchQuery: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, fetchQuery, additionalQueryKeys = [] } = options
  const { transactionsRepo } = useRepos()
  return useInfiniteQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: ({ pageParam = 1 }) =>
      transactionsRepo.getTransactions({
        PageIndex: pageParam,
        PageSize: 10,
        cardType: CardType.BankCard,
        ...fetchQuery
      }),
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.nextPage : undefined,
    keepPreviousData: true,
    ...queryOptions
  })
}
