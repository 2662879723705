import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { ColorName } from '../../domain/utils/theme'
import upperFirst from '../../utils/upperFirst'

export type Props = {
  className?: string
  fixedWidth?: boolean
  name: string
  primaryColor?: ColorName
  secondaryColor?: ColorName
  spin?: boolean
  type?: 'duotone' | 'line'
  style?: CSSProperties
}

// eslint-disable-next-line react/display-name
const Icon = React.forwardRef<HTMLElement, Props>(
  (
    {
      className,
      fixedWidth,
      name,
      primaryColor,
      secondaryColor,
      spin,
      style,
      type = 'duotone'
    },
    ref
  ) => {
    return (
      <i
        aria-hidden="true"
        className={classNames(
          'Icon',
          primaryColor && `Icon--Primary${upperFirst(primaryColor)}`,
          secondaryColor && `Icon--Secondary${upperFirst(secondaryColor)}`,
          'fa',
          `fa-${name}`,
          fixedWidth && 'fa-fw',
          spin && 'fa-spin',
          type === 'duotone' && 'fad',
          type === 'line' && 'fal',
          className
        )}
        ref={ref}
        style={style}
      />
    )
  }
)

export default Icon
