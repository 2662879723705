import { TransactionsModel } from '../../../../domain/models/transactionsModel'
import { TransactionModelMapper } from '../mappers/TransactionModelMapper'
import { TransactionProxy, TransactionQuery } from '../proxies/TransactionProxy'

export class TransactionsRepository {
  private proxy: TransactionProxy

  constructor(apiToken: string) {
    this.proxy = new TransactionProxy(apiToken)
  }

  async getTransactions(query: TransactionQuery) {
    const transactions = await this.proxy.getTransactions({
      PageSize: 10,
      ...query
    })

    const mapper = new TransactionModelMapper()
    return new TransactionsModel(
      transactions.payload.map((invoice) =>
        mapper.getTransactionModel(invoice)
      ),
      transactions.nextPage,
      transactions.hasMore,
      transactions.maxPages
    )
  }

  // async getBankCardTransactions(query: TransactionQuery) {
  //   const transactions = await this.proxy.getBankCardTransactions({
  //     PageSize: 10,
  //     ...query
  //   })

  //   const mapper = new TransactionModelMapper()
  //   return new TransactionsModel(
  //     transactions.payload.map((invoice) =>
  //       mapper.getTransactionModel(invoice)
  //     ),
  //     transactions.nextPage,
  //     transactions.hasMore,
  //     transactions.maxPages
  //   )
  // }
}
