import React from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { InputStateObject, useForm } from '../../hooks/useForm'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AutogiroForm } from '../views/Profile/Components/AutogiroModal'
import Input from './Input'
type formType = ReturnType<typeof useForm<InputStateObject<AutogiroForm>>>
type Props = { closeModal: () => void, autogiroForm: formType }

const PaymentAutogiroGroup: React.FC<Props> = ({ closeModal, autogiroForm }) => {
  const { inputs, handleInputChange, validateInput } = autogiroForm

  return (
    <>
      <h5>{'Betalinformation'}</h5>
      <Input
        error={
          inputs.autogiroBank.isError
            ? inputs.autogiroBank.errorMessage
            : undefined
        }
        fullWidth
        label='Bank'
        name="autogiroBank"
        onBlur={() => validateInput("autogiroBank")}
        onChange={handleInputChange}
        type="text"
        value={inputs.autogiroBank.value}
      />
      <Input
        error={
          inputs.autogiroClearingNo.isError
            ? inputs.autogiroClearingNo.errorMessage
            : undefined
        }
        fullWidth
        label='Clearingnummer'
        name="autogiroClearingNo"
        onBlur={() => validateInput("autogiroClearingNo")}
        onChange={handleInputChange}
        type="text"
        value={inputs.autogiroClearingNo.value}
      />
      <Input
        error={
          inputs.autogiroAccountNo.isError
            ? inputs.autogiroAccountNo.errorMessage
            : undefined
        }
        fullWidth
        label='Kontonummer'
        name="autogiroAccountNo"
        onBlur={() => validateInput("autogiroAccountNo")}
        onChange={handleInputChange}
        type="text"
        value={inputs.autogiroAccountNo.value}
      />
    </>
  )
}
export default PaymentAutogiroGroup
