import classNames from 'classnames'
import React from 'react'
import Icon from './Icon'

type Props = {
  buttons: {
    text: string
    value: string
  }[]
  className?: string
  direction?: 'vertical' | 'horizontal'
  labelElementId?: string
  onChange: (value: string) => void
  value: string
}

/**
 * @see https://www.w3.org/TR/2016/WD-wai-aria-practices-1.1-20160317/examples/radio/radio.html
 */
const RadioGroup: React.FC<Props> = ({
  buttons,
  onChange,
  value,
  className,
  direction,
  labelElementId
}) => {
  const handleKeyDown = (
    event: React.KeyboardEvent,
    buttonValue: string,
    index: number
  ) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      onChange(buttonValue)
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      if (index !== 0) {
        onChange(buttons[index - 1]!.value)
      }
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      if (index !== buttons.length - 1) {
        onChange(buttons[index + 1]!.value)
      }
    }
  }

  return (
    <div
      className={classNames(
        {
          RadioGroup: true,
          'RadioGroup--Horizontal': direction === 'horizontal'
        },
        className
      )}
      role="radiogroup"
    >
      {buttons.map((button, index) => {
        const checked = button.value === value

        return (
          <div
            aria-checked={checked}
            aria-labelledby={labelElementId}
            className="RadioGroup__Button"
            key={index}
            onClick={() => onChange(button.value)}
            onKeyPress={(event) => handleKeyDown(event, button.value, index)}
            role="radio"
            tabIndex={index === 0 ? 0 : -1}
          >
            <Icon
              className="RadioGroup__ButtonIcon"
              name="circle"
              primaryColor={checked ? 'green300' : 'blue800'}
              secondaryColor={checked ? 'green500' : 'blue800'}
            />
            {button.text && (
              <label className="RadioGroup__ButtonLabel">{button.text}</label>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default RadioGroup
