import { EffectCallback, FormEventHandler, useEffect, useState } from 'react'

type PickByValue<T, V> = Pick<
  T,
  { [K in keyof T]: T[K] extends V ? K : never }[keyof T]
>

type Entries<T> = {
  [K in keyof T]: [keyof PickByValue<T, T[K]>, T[K]]
}[keyof T][]

export type InputState<T> = {
  isError?: boolean
  value: string
  required?: boolean
  validationFunction?: (
    input: string,
    prevInputs: InputStateObject<T>
  ) => boolean | { isValid: boolean; errorMessage: string }
  errorMessage?: string
}

export type InputStateObject<T> = {
  [Property in keyof T]: InputState<T>
}

let currentDebouce: unknown = null

export const useForm = <T>(formObject: T) => {
  const [inputs, setInputs] = useState<T>(formObject)
  type inputKeys = keyof typeof inputs

  const [isValid, setIsValid] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [inputToValidate, setInputToValidate] = useState<{
    name: inputKeys
  } | null>(null)
  const resetForm = () => {
    const newInputs = { ...inputs }
    // eslint-disable-next-line guard-for-in
    for (const inputName in newInputs) {
      // @ts-ignore
      newInputs[inputName].value = ''
      // @ts-ignore
      newInputs[inputName].isError = false
      // @ts-ignore
      if (newInputs[inputName]?.required && isValid) {
        setIsValid(false)
      }
    }
    setInputs(newInputs)
  }

  const clearInput = (inputName: inputKeys) => {
    const newInputs = { ...inputs }
    // @ts-ignore
    newInputs[inputName].value = ''
    setInputs(newInputs)
    validateInput(inputName)
  }

  const validateInput = (inputKey: inputKeys) => {
    /**
     * The following logic is wrapped in the setInput callback to make sure
     * it does not use stale state from the current scope
     */
    setInputs((prevInputs) => {
      // @ts-ignore
      const input = prevInputs[inputKey] as InputState
      const newInputState: InputState<T> = { ...input }

      if (input.required && !input.value) {
        newInputState.isError = true
        const newInputsState = { ...prevInputs, [inputKey]: newInputState }
        setIsValid(isFormValid(newInputsState))
        return newInputsState
      }

      const validationFunction = input?.validationFunction

      if (validationFunction) {
        const validationValue = validationFunction(
          newInputState.value,
          prevInputs
        )
        if (typeof validationValue === 'boolean') {
          newInputState.isError = !validationValue
        } else {
          newInputState.isError = !validationValue.isValid
          newInputState.errorMessage = validationValue.errorMessage
        }
        const newInputsState = { ...prevInputs, [inputKey]: newInputState }
        setIsValid(isFormValid(newInputsState))
        return newInputsState
      }

      return prevInputs
    })
  }

  useEffect((): ReturnType<EffectCallback> => {
    const inputName = inputToValidate?.name
    if (!inputName) {
      return
    }

    if (currentDebouce) {
      // @ts-ignore
      clearTimeout(currentDebouce)
    }

    currentDebouce = setTimeout(() => {
      validateInput(inputName)
      setInputToValidate(null)
    }, 600)

    /* eslint-disable consistent-return */
    return () => {
      if (currentDebouce) {
        // @ts-ignore
        clearTimeout(currentDebouce)
      }
    }
    /* eslint-enable consistent-return */
  }, [inputToValidate])

  const handleInputChange: FormEventHandler<HTMLInputElement> &
    FormEventHandler<HTMLSelectElement> = ({ currentTarget }) => {
    const value = currentTarget.value
    const name = currentTarget.name as inputKeys

    const newInputState: InputState<T> = { ...inputs[name], value }
    const newInputsState = { ...inputs, [name]: newInputState }

    if (!hasChanged) {
      setHasChanged(true)
    }

    setInputToValidate({ name })
    setIsValid(isFormValid(newInputsState))
    setInputs(newInputsState)
  }

  const isFormValid = (newInputState: typeof inputs) => {
    // @ts-ignore
    const formValues = Object.values(newInputState) as InputState[]

    for (const value of formValues) {
      if (value?.isError) {
        return false
      }

      if (value?.required && !value.value) {
        return false
      }
    }

    return true
  }

  const getValues = <R>(inputStateObject: {
    [key: string]: InputState<R>
  }): R => {
    const entries = Object.entries(inputStateObject) as Entries<
      InputStateObject<R>
    >
    const values = entries.reduce((acc, [key, value]) => {
      // @ts-ignore
      acc[key] = value.value
      return acc
    }, {})

    return values as R
  }

  return {
    inputs,
    setInputs,
    validateInput,
    handleInputChange,
    isValid,
    getValues,
    hasChanged,
    resetForm,
    clearInput
  }
}
