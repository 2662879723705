import classNames from 'classnames'
import React from 'react'
import { useGetAccountInformation } from '../../../hooks/queryHooks/useGetAccountInformation'
import { formatSEK } from '../../../utils/formatSEK'

type SmartCardBalanceProps = {}

const SmartCardBalance: React.FC<SmartCardBalanceProps> = (props) => {
  const { data: accountInformation } = useGetAccountInformation()

  return (
    <div className="SmartCardBalance">
      <h4 className="SmartCardBalance__Heading">{'Aktuellt saldo'}</h4>
      <div
        className={classNames({
          SmartCardBalance__Amount: true,
          'SmartCardBalance__Amount--Negative':
            accountInformation!.prepaidBalance < 0,
          'SmartCardBalance__Amount--Total': true
        })}
      >
        {formatSEK(accountInformation!.prepaidBalance)}
      </div>
    </div>
  )
}

export default SmartCardBalance
