import classNames from 'classnames'
import React from 'react'
import Icon from './Icon'
import Label from './Label'

type Props = {
  checked: boolean
  className?: string
  iconClassName?: string
  labelClassName?: string
  onChange: (checked: boolean, id?: string) => void
  ariaLabel?: string
  ariaLabelledBy?: string
  text?: string
  disabled?: boolean
  textElement?: JSX.Element
  title?: string
  propId?: string
}

const Checkbox: React.FC<Props> = ({
  checked,
  ariaLabel,
  text,
  textElement,
  title,
  disabled,
  propId = '',
  iconClassName = '',
  ariaLabelledBy = '',
  onChange,
  labelClassName,
  className
}) => {
  const handleClick = () => {
    if (!disabled) {
      onChange(!checked, propId)
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (
      (event.key === 'Enter' ||
        event.key === ' ' ||
        event.key === 'Spacebar') &&
      !disabled
    ) {
      event.preventDefault()
      onChange(!checked, propId)
    }
  }

  return (
    <div
      aria-checked={checked}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      className={classNames(
        {
          Checkbox: true,
          'Checkbox--Checked': checked,
          'Checkbox--Disabled': disabled
        },
        className
      )}
      id={propId}
      onClick={handleClick}
      onKeyPress={handleKeyPress}
      role="checkbox"
      tabIndex={0}
    >
      <Icon
        className={`Checkbox__Icon ${iconClassName}`}
        name={checked ? 'check-circle' : 'circle'}
        primaryColor={checked ? 'green300' : 'blue800'}
        secondaryColor={checked ? 'green500' : 'blue800'}
      />
      {text && (
        <Label
          className={`Checkbox__Label ${labelClassName}`}
          text={text}
          title={title}
        />
      )}
      {textElement && textElement}
    </div>
  )
}

export default Checkbox
