/* eslint-disable no-confusing-arrow */
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions
} from '@tanstack/react-query'
import { PagedResult } from '../../domain/models/PagedResult'
import { castUtcDate } from '../../utils/castUtcDate'
import { authorizedGetPaged } from '../../utils/fetchUtils/authorizedGetPaged'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetStatements

const DEFAULT_PAGE_SIZE = 10

export type FetchedStatement = {
  statementNo: number
  statementDate: string
  deliveryType: string
  ocr: string
}

export type Statement = {
  statementNo: number
  statementDate: Date
  deliveryType: string
  ocr: string
}

type StatementsQuery = {
  PageIndex?: number
  PageSize?: number
}

const getStatements = async (query: StatementsQuery) => {
  const results = await authorizedGetPaged<FetchedStatement>(
    '/Statements',
    query
  )
  return {
    ...results,
    payload: results.payload.map((statement) => ({
      ...statement,
      statementDate: castUtcDate(statement.statementDate)
    }))
  } satisfies PagedResult<Statement>
}

type UserGetInvoicesParams = {
  queryOptions?: UseInfiniteQueryOptions<
    PagedResult<Statement>,
    unknown,
    PagedResult<Statement>
  >
  fetchQuery?: StatementsQuery
  additionalQueryKeys?: unknown[]
}

export const useGetStatements = (
  options: UserGetInvoicesParams = {
    queryOptions: {},
    fetchQuery: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, fetchQuery = {}, additionalQueryKeys = [] } = options

  return useInfiniteQuery({
    queryKey: [queryKey, DEFAULT_PAGE_SIZE, fetchQuery, ...additionalQueryKeys],
    queryFn: ({ pageParam = 1 }) =>
      getStatements({
        PageIndex: pageParam,
        PageSize: DEFAULT_PAGE_SIZE,
        ...fetchQuery
      }),
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.nextPage : undefined,
    /**
     * Only refetch and validate invoices evey minute
     * as invoices are unlikely to change often
     */
    staleTime: 1000 * 60,
    ...queryOptions
  })
}
