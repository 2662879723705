import { CardType } from '../../../../domain/enums/CardType'
import { CardModel } from '../../../../domain/models/CardModel'
import { mockCards } from '../../../../legacy/mock/data/mockCards'
import { Card } from '../schemas/Card'

export const getCardModels = (cards: Card[]): CardModel[] => {
  if (
    process.env.NODE_ENV === 'development' &&
    import.meta.env.VITE_DEBUG_USE_MOCK_DATA === 'true'
  ) {
    return mockCards
  }

  const result: CardModel[] = []

  for (const card of cards) {
    result.push(getCardModel(card))
  }

  return result
}

export const getCardModel = (card: Card): CardModel => {
  const result = new CardModel(
    String(card.cardNo),
    card.magstripe ?? '',
    card.cardReference1 ?? '',
    card.cardReference2 ?? '',
    card.cardType === CardType.SmartCard,
    card.isDisabled,
    card.disabledDate,
    card.isCompany,
    card.requiresVehicle,
    card.requiresMilage
  )

  return result
}
export default {
  getCardModel,
  getCardModels
}
