import React, { Suspense } from 'react'
import Loader, { LoaderProps } from './Loader'

type SuspenseLoaderWrapperProps = LoaderProps

const SuspenseLoaderWrapper: React.FC<SuspenseLoaderWrapperProps> = ({
  children,
  ...loaderProps
}) => {
  return <Suspense fallback={<Loader {...loaderProps} />}>{children}</Suspense>
}

export default SuspenseLoaderWrapper
