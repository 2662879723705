import classNames from 'classnames'
import format from 'date-fns/format'
import React, { useState } from 'react'
import { CardModel } from '../../domain/models/CardModel'
import {
  useDevCardValues,
  useGetCards
} from '../../hooks/queryHooks/useGetCards'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import CardChangeName from './CardChangeName'
import CardDisable from './CardDisable'
import { CardMenu } from './CardMenu'
import CardNewPin from './CardNewPin'
import CardReplacement from './CardReplacement'
import { DisableLastCardModal } from './DisableLastCardModal'

export enum CardEditState {
  Initial,
  DisableCard,
  ChangePin,
  ChangeName,
  ReplacementCard
}

type Props = {
  card: CardModel
}

const Card: React.FC<Props> = ({ card }) => {
  const { values: devValues } = useDevCardValues()
  const cardsQuery = useGetCards()
  const isBankIdUser = useIsBankIdUser()
  const [editState, setEditState] = useState(CardEditState.Initial)
  const allActiveCards = [
    ...cardsQuery.data!.smartCards,
    ...cardsQuery.data!.tankCards
  ].filter((value) => !value.disabled)
  const isLastActiveCard = devValues?.isLastCard ?? allActiveCards.length === 1

  const handleEditChangeNameClick = (): void => {
    if (isBankIdUser) {
      setEditState(CardEditState.ChangeName)
    } else {
      window.location.href = '/login-bankid'
    }
  }

  const handleEditReplacementCardClick = (): void => {
    if (isBankIdUser) {
      setEditState(CardEditState.ReplacementCard)
    } else {
      window.location.href = '/login-bankid'
    }
  }

  const handleEditChangePinClick = (): void => {
    if (isBankIdUser) {
      setEditState(CardEditState.ChangePin)
    } else {
      window.location.href = '/login-bankid'
    }
  }

  const handleEditDisableCardClick = (): void => {
    setEditState(CardEditState.DisableCard)
  }

  const handleCancel = (): void => {
    setEditState(CardEditState.Initial)
  }

  return (
    <div className="Card__Container">
      {(editState === CardEditState.Initial ||
        (editState === CardEditState.DisableCard && isLastActiveCard)) && (
        <div
          className={classNames({
            Card: true,
            'Card--Disabled': card.disabled,
            'Card--SmartCard': card.isSmartCard
          })}
        >
          {card.disabled && (
            <div className="Card__DisabledBanner">
              {'Spärrat '}
              {card.disabledDate ? format(card.disabledDate, 'yyyy-MM-dd') : ''}
            </div>
          )}
          {!card.disabled && (
            <div className="Card__Buttons">
              <CardMenu
                onChangeNameClick={handleEditChangeNameClick}
                onChangePinClick={handleEditChangePinClick}
                onChangeReplacementCardClick={handleEditReplacementCardClick}
                onDisableCardClick={handleEditDisableCardClick}
              />
            </div>
          )}
          <div className="Card__Content">
            <div className="Card__Top">
              <img
                alt="Qstars logotyp"
                className="Card__Logo"
                src="/static/images/qstar_logo_yellow.png"
              />
              <h6 className="Card__Header">
                {card.isSmartCard ? 'Smartkort' : 'Tankkort'}
              </h6>
            </div>
            <div className="Card__Bottom">
              <div
                className="Card__CardRow Card__CardReference Card__CardReference--Primary"
                title={card.cardReference1}
              >
                {card.cardReference1}
              </div>
              <div
                className="Card__CardRow Card__CardReference Card__CardReference--Secondary"
                title={card.cardReference2}
              >
                {card.cardReference2}
              </div>
            </div>
          </div>
        </div>
      )}

      {editState === CardEditState.DisableCard && isLastActiveCard && (
        <DisableLastCardModal card={card} onClose={handleCancel} />
      )}
      {editState === CardEditState.DisableCard && !isLastActiveCard && (
        <CardDisable card={card} onCancel={handleCancel} />
      )}

      {editState === CardEditState.ChangePin && (
        <CardNewPin card={card} onCancel={handleCancel} />
      )}

      {editState === CardEditState.ReplacementCard && (
        <CardReplacement card={card} onCancel={handleCancel} />
      )}

      {editState === CardEditState.ChangeName && (
        <CardChangeName card={card} onCancel={handleCancel} />
      )}
    </div>
  )
}

export default Card
