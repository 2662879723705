/* eslint-disable react/no-multi-comp */
/* eslint-disable no-confusing-arrow */
import classNames from 'classnames'
import { subYears } from 'date-fns'
import React, { Fragment } from 'react'
import { CardType } from '../../domain/enums/CardType'
import { InvoiceModel } from '../../domain/models/InvoiceModel'
import { TRANSACTION_MAX_AGE_YEARS } from '../../domain/models/TransactionModel'
import { useGetTransactions } from '../../hooks/queryHooks/useGetTransactions'
import { isBonusTransaction } from '../../utils/isBonusTransaction'
import { isManualTransaction } from '../../utils/isManualTransaction'
import stringDate from '../../utils/stringDate'
import Icon from '../elements/Icon'
import Link from '../elements/Link'
import { InformationRow } from '../InformationRow'

type InvoiceRelatedTransactionsProps = {
  invoice: InvoiceModel
  previousInvoiceDate: Date
  isOdd: boolean
}

const InvoiceRelatedTransactions: React.FC<InvoiceRelatedTransactionsProps> = ({
  invoice,
  previousInvoiceDate,
  isOdd
}) => {
  const currentInvoiceDate = invoice.invoiceDate.toISOString()

  const checkInvoiceAge = (invoiceDate: Date): boolean => {
    const limitDate = subYears(new Date(), TRANSACTION_MAX_AGE_YEARS)
    return invoiceDate <= limitDate
  }

  const { isLoading, fetchNextPage, hasNextPage, data, isFetchingNextPage } =
    useGetTransactions({
      additionalQueryKeys: [invoice.invoiceID, currentInvoiceDate],
      fetchQuery: {
        endDate: currentInvoiceDate,
        startDate: previousInvoiceDate.toISOString(),
        cardType: CardType.CreditCard
      },
      queryOptions: {
        enabled: !checkInvoiceAge(invoice.invoiceDate),
        staleTime: 30000
      }
    })

  if (checkInvoiceAge(invoice.invoiceDate)) {
    return (
      <InformationRow
        colSpan={4}
        isOdd={isOdd}
        text={`Transaktioner äldre än ${TRANSACTION_MAX_AGE_YEARS} år kan inte laddas.`}
      />
    )
  }

  if (isLoading) {
    return (
      <InformationRow
        colSpan={4}
        isOdd={isOdd}
        text="Laddar relaterade transaktioner..."
      />
    )
  }

  if (data?.pages[0].payload.length === 0) {
    return (
      <InformationRow
        colSpan={4}
        isOdd={isOdd}
        text="Inga transaktioner hittades."
      />
    )
  }

  return (
    <>
      {data?.pages.map((transactionsPage, pageIndex) => (
        <Fragment key={pageIndex}>
          {transactionsPage.payload.map((transaction, transactionIndex) => {
            const href =
              `${import.meta.env.VITE_BACKEND_URL}/api/Receipts` +
              `/Kvitto ${transaction.transactionId}.pdf`

            if (isManualTransaction(transaction)) {
              return <Fragment key={String(transaction.transactionId)} />
            }

            return (
              <tr
                className={classNames(
                  'Invoice__Row Invoice__Row--Transaction',
                  {
                    'Invoice__Row--IsOdd': isOdd,
                    'Invoice__Row--TransactionFirst':
                      pageIndex === 0 && transactionIndex === 0,
                    'Invoice__Row--TransactionLast':
                      !hasNextPage &&
                      !isFetchingNextPage &&
                      pageIndex === data?.pages.length - 1 &&
                      transactionIndex === transactionsPage.payload.length - 1
                  }
                )}
                key={String(transaction.transactionId)}
              >
                <td className="Invoice__Cell Invoice__Cell--Date">
                  {!isBonusTransaction(transaction) && (
                    <Link
                      className="Invoice__DownloadLink"
                      href={href}
                      openNewTab
                    >
                      <Icon
                        className="Invoice__ReceiptIcon"
                        name="receipt"
                        primaryColor="blue400"
                        secondaryColor="yellow500"
                      />
                    </Link>
                  )}
                  <Link href={href} openNewTab styled>
                    {stringDate(transaction.dateTime)}
                  </Link>
                </td>
                <td
                  className="Invoice__Cell Invoice__Cell--ProductAndSiteName"
                  colSpan={4}
                >
                  <span className="Invoice__CellRow Invoice__CellRow--Product">
                    {transaction.productName}
                  </span>
                  {' / '}
                  <span className="Invoice__CellRow">
                    {transaction.siteName}
                  </span>
                </td>
              </tr>
            )
          })}
        </Fragment>
      ))}
      {isFetchingNextPage && (
        <InformationRow
          colSpan={4}
          isLastRow={!hasNextPage}
          text="Laddar fler relaterade transaktioner..."
        />
      )}
      {hasNextPage && (
        <InformationRow
          colSpan={4}
          isLastRow
          text={
            <button
              className="Invoice__Row--Button"
              onClick={() => fetchNextPage()}
            >
              {'Visa fler transaktioner'}
            </button>
          }
        />
      )}
    </>
  )
}

export default InvoiceRelatedTransactions
