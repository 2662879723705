import jwtDecode from 'jwt-decode'
import Cookie from 'react-cookies'
import { AuthenticationRight } from '../../../allkort/v1/schemas/LoginAuthenticationRole'
import { AuthProxy } from '../proxies/AuthProxy'

type ApiName = 'IdentityServerApi' | 'MyPagesApi'

export type AuthMethod = 'password' | 'bankid'

export type AuthenticationRole = 'Reader' | 'Editor' | 'Administrator'

type TokenPayload = {
  nbf: number
  exp: number
  iss: string
  aud: ApiName | ApiName[]
  client_id: string
  client_owner_name: string
  client_owner_id: string
  authentication_role: AuthenticationRight[] | 'Superuser'
  sub: string
  auth_time: number
  idp: 'local'
  customer_no: string
  customer_id: string
  given_name: string
  family_name: string
  email: string
  scope: ApiName[]
  amr: ('bankid' | 'pwd')[]
}

const ACCESS_TOKEN_COOKIE_NAME = 'token'
const AUTHORIZE_BANKID_ERROR_URL = `/?error=${encodeURIComponent(
  'Lyckades inte ansluta till BankID. Försök igen senare eller kontakta kundservice.'
)}`
const VERIFIER_ALPHABET = '0123456789abcdef'
const VERIFIER_COOKIE_EXPIRES_MS = 15 * 60 * 1000 // eslint-disable-line @typescript-eslint/no-magic-numbers
const VERIFIER_COOKIE_NAME = 'verifier'
const VERIFIER_SIZE = 64

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const crypto = window.crypto ?? (window as any).msCrypto

export class AuthService {
  /**
   * @description
   * Create a code verifier in accordance with CNS's provided examples.
   * The alphabet is 16 characters, so 256 % 16 will generate an even distribution of characters.
   *
   * @example
   * this.createVerifier()
   * // 'bec80b8e16a1e2b344f7746f489467647b460788b978cb547b6e3fd05dbe7e25'
   */
  private static _createVerifier(): string {
    const bytes = crypto.getRandomValues(new Uint8Array(VERIFIER_SIZE))
    let verifier = ''

    for (let i = 0; i < bytes.length; i++) {
      verifier += VERIFIER_ALPHABET[bytes[i]! % VERIFIER_ALPHABET.length]
    }

    return verifier
  }

  private static _loadVerifier(): string | undefined {
    return Cookie.load(VERIFIER_COOKIE_NAME)
  }

  private static _saveVerifier(verifier: string): void {
    Cookie.save(VERIFIER_COOKIE_NAME, verifier, {
      expires: new Date(Date.now() + VERIFIER_COOKIE_EXPIRES_MS),
      path: '/'
    })
  }

  static async authorizeBankIDLogin(): Promise<string> {
    try {
      const verifier = this._createVerifier()
      const redirectUrl = await AuthProxy.authorizeBankIDLogin(verifier)

      if (redirectUrl) {
        this._saveVerifier(verifier)

        return redirectUrl
      }
    } catch {}

    return AUTHORIZE_BANKID_ERROR_URL
  }

  static generateResetPasswordToken(
    customerNo: number,
    email: string
  ): Promise<Response> {
    return AuthProxy.generateResetPasswordToken(customerNo, email)
  }

  static getAuthenticationMethod(): AuthMethod | null {
    if (
      ['bankid', 'password'].includes(import.meta.env.VITE_DEBUG_AUTH_METHOD)
    ) {
      return import.meta.env.VITE_DEBUG_AUTH_METHOD as AuthMethod
    }

    const token = this.loadAccessToken()

    if (token) {
      const decoded = jwtDecode<Partial<TokenPayload>>(token)
      if (decoded?.amr) {
        return decoded.amr.includes('bankid') ? 'bankid' : 'password'
      }
    }

    return null
  }

  static getAuthenticatedRights(): AuthenticationRight[] | 'Superuser' {
    const token = this.loadAccessToken()

    if (token) {
      const decoded = jwtDecode<Partial<TokenPayload>>(token)

      if (decoded?.authentication_role) {
        return decoded.authentication_role
      }
    }

    return []
  }

  static loadAccessToken(): string | undefined {
    return Cookie.load(ACCESS_TOKEN_COOKIE_NAME)
  }

  static isAuthenticated(): boolean {
    return Boolean(this.loadAccessToken())
  }

  static loginBankIDToken(code: string): Promise<void> {
    const verifier = this._loadVerifier()

    if (!verifier) {
      throw new Error('BankID-sessionen har gått ut. Försök igen.')
    }

    return AuthProxy.loginBankIDToken(code, verifier)
  }

  static loginCustomerNumber(
    username: string,
    password: string
  ): Promise<boolean> {
    return AuthProxy.loginCustomerNumber(username, password)
  }

  static removeAccessToken(): void {
    Cookie.remove(ACCESS_TOKEN_COOKIE_NAME)
  }

  static resetPassword(token: string, newPassword: string): Promise<Response> {
    return AuthProxy.resetPassword(token, newPassword)
  }

  static saveAccessToken = (token: string, expires: Date) => {
    Cookie.save(ACCESS_TOKEN_COOKIE_NAME, token, { expires, path: '/' })
  }

  static getPasswordRegex(): Promise<Response> {
    return AuthProxy.getPasswordRegex()
  }
}
