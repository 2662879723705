import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import Icon from '../elements/Icon'

type UserDevToolsProps = {}

const UserDevToolsContainer: React.FC<UserDevToolsProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '4rem',
        right: '.75rem',
        marginLeft: '.75rem',
        zIndex: 9999999
      }}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            initial={{ scale: 0 }}
            style={{
              background: '#eee',
              borderRadius: '8px',
              maxHeight: '80vh',
              overflow: 'auto',
              transformOrigin: 'bottom right',
              padding: '1.5rem',
              width: '100%'
            }}
          >
            <button
              className="Modal__CloseButton"
              onClick={() => setIsOpen(false)}
              style={{ top: '-.5rem' }}
            >
              <Icon name="times" />
            </button>
            <h4>{'Testverktyg'}</h4>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        style={{
          background: '#eee',
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '2.75rem',
          height: '2.75rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '1.5rem',
          borderRadius: '100%',
          padding: 0,
          margin: 0,
          zIndex: -1
        }}
      >
        <Icon name="cog" />
      </button>
    </div>
  )
}

export default UserDevToolsContainer
