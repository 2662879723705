import React from 'react'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { usePutCustomer } from '../../../hooks/queryHooks/usePutCustomer'
import Button from '../../elements/Button'
import Link from '../../elements/Link'

type Props = { closeModal: () => void }

const CustomerProfileAddress: React.FC<Props> = ({ closeModal }) => {
  const { data: customer } = useGetCustomer()

  const updateCustomerMutation = usePutCustomer({
    queryOptions: { onSettled: closeModal }
  })

  const handleSavePaymentMethod = () => {
    updateCustomerMutation.mutate({
      invoiceMailingDeliveryType: 'PaperMailing'
    })
  }
  return (
    <>
      <div className="ProfileSettings__Column Modal__Section">
        <b>{'Adress'}</b>
        {customer!.address1 +
          ', ' +
          customer!.postalCode +
          '  ' +
          customer!.city}
        <small>
          {'För att ändra din adress behöver du '}
          <Link href="/kontakt" styled>
            {'kontakta oss.'}
          </Link>
        </small>
      </div>
      <div className="ProfileSettingsModal__Actions flex-column">
        <div className="d-flex justify-content-end">
          <Button
            className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Save"
            disabled={customer!.invoiceMailingDeliveryType === 'PaperMailing'}
            onClick={handleSavePaymentMethod}
            type="button"
          >
            {'Spara'}
          </Button>
          <Button
            className="ProfileSettingsModal__Button ProfileSettingsModal__Button--Cancel"
            onClick={closeModal}
            type="button"
          >
            {'Avbryt'}
          </Button>
        </div>
      </div>
    </>
  )
}
export default CustomerProfileAddress
