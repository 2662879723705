import { AgreementApprovalModel } from '../../../../domain/models/AgreementApprovalModel'
import { AgreementApproval } from '../schemas/AgreementApproval'
import { ProxyBase } from './ProxyBase'

export class AgreementApprovalProxy extends ProxyBase<AgreementApproval[]> {
  getAgreementApproval(): Promise<AgreementApproval[]> {
    try {
      return this.get('/api/v2/Customer/Agreements')
    } catch (ex) {
      throw new Error(
        'Lyckades inte hämta avtal. Försök igen senare eller kontakta kundservice.'
      )
    }
  }

  postAgreementApproval(
    agreements: AgreementApprovalModel[]
  ): Promise<unknown> {
    try {
      return this.post('/api/v2/Customer/Agreements', agreements)
    } catch (ex) {
      throw new Error(
        'Lyckades inte uppdatera avtalsinställningar. ' +
          'Försök igen senare eller kontakta kundservice.'
      )
    }
  }
}
