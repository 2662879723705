import { FC } from 'react'
import { Placement } from 'react-bootstrap/esm/types'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import { useUserRights } from '../../hooks/useUserRights'
import { WriteAuthenticationRight } from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import ActionButton, { ActionButtonProps } from './ActionButton'
import { ToolTipWrapperProps } from './ToolTipWrapper'
import UserRightTooltip from './UserRightTooltip'

type RightsActionButtonProps = {
  userRights: WriteAuthenticationRight | WriteAuthenticationRight[]
  tooltipProps?: Partial<ToolTipWrapperProps>
  tooltipPlacement?: Placement
  disableOnFrozenCustomer?: boolean
  disableOnPasswordLogin?: boolean
} & ActionButtonProps

const RightsActionButton: FC<RightsActionButtonProps> = ({
  userRights,
  children,
  tooltipProps,
  disableOnFrozenCustomer = true,
  disableOnPasswordLogin = true,
  tooltipPlacement,
  disabled,
  ...props
}) => {
  const { userHasRight } = useUserRights()
  const { data: customer } = useGetCustomer()
  const isBankIdUser = useIsBankIdUser()
  const disableBecauseFrozen = customer!.isFrozen && disableOnFrozenCustomer
  const disableBecausePasswordLogin = !isBankIdUser && disableOnPasswordLogin

  const getPasswordOrFrozenText = () => {
    if (disableBecauseFrozen) {
      return 'Du kan inte använda denna funktion när ditt konto är spärrat'
    }

    return 'Logga in med BankID för att använda denna funktion'
  }

  return (
    <UserRightTooltip
      enabled={
        disableBecauseFrozen ||
        disableBecausePasswordLogin ||
        !userHasRight(userRights)
      }
      text={
        disableBecauseFrozen || disableBecausePasswordLogin
          ? getPasswordOrFrozenText()
          : undefined
      }
      tooltipPlacement={tooltipPlacement ?? tooltipProps?.tooltipPlacement}
      userRights={userRights}
      {...tooltipProps}
    >
      <ActionButton
        isVisuallyDisabled={
          disabled ||
          disableBecauseFrozen ||
          disableBecausePasswordLogin ||
          !userHasRight(userRights)
        }
        {...props}
      >
        {children}
      </ActionButton>
    </UserRightTooltip>
  )
}

RightsActionButton.displayName = 'RightsActionButton'

export default RightsActionButton
