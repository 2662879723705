import React from 'react'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import RightsActionButton from '../elements/RightsActionButton'

type UserEditButtonsProps = {
  userProfile: UserProfile
  triggerConfirmDeleteModal: (userName: string, userId: string) => void
  setSelectedUserId: () => void
}

const UserEditButtons: React.FC<UserEditButtonsProps> = ({
  userProfile: { userProfileId, firstName, lastName },
  triggerConfirmDeleteModal,
  setSelectedUserId
}) => {
  return (
    <>
      <th className="user-profile-table__body-cell--button">
        <RightsActionButton
          onClick={setSelectedUserId}
          title={`Redigera ${firstName}`}
          tooltipPlacement="left"
          userRights="UserProfilesWrite"
          variation="edit"
        />
      </th>
      <th className="user-profile-table__body-cell--button">
        <RightsActionButton
          onClick={() =>
            triggerConfirmDeleteModal(
              `${firstName} ${lastName || ''}`,
              userProfileId
            )
          }
          title={`Radera ${firstName}`}
          tooltipPlacement="left"
          userRights="UserProfilesWrite"
          variation="delete"
        />
      </th>
    </>
  )
}

export default UserEditButtons
