import React from 'react'
import Icon from './Icon'

type Props = {
  iconOnly?: boolean
}

const PaymentMethodBadge: React.FC<Props> = ({ iconOnly }) => {
  return (
    <div className="PaymentMethodBadge__Box">
      <Icon
        className="PaymentMethodBadge__CheckIcon"
        name="check-circle"
        primaryColor="green300"
        secondaryColor="green500"
      />
      {!iconOnly && (
        <span className="PaymentMethodBadge__Text">{'Valt fakturasätt'}</span>
      )}
    </div>
  )
}

export default PaymentMethodBadge
