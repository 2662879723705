import React, { useState } from 'react'
import {
  MailingDeliveryType,
  MailingDeliveryTypes
} from '../../../../domain/enums/MailingDeliveryType'
import { useGetCustomer } from '../../../../hooks/queryHooks/useGetCustomer'
import Modal from '../../../elements/Modal'
import PaymentChangeConfirmation from '../../../elements/PaymentChangeConfirmation'
import PaymentRadioGroup from '../../../elements/PaymentRadioGroup'
import CustomerProfileAddress from '../CustomerProfileAddress'
import CustomerProfileEmaiInfo from '../CustomerProfileEmaiInfo'
import CustomerProfileEmail from '../CustomerProfileEmail'
import { EInvoiceCompany } from './EInvoiceCompany/EInvoiceCompany'

const paymentMethodMessageMap: Record<MailingDeliveryType, string> = {
  EInvoice: 'Du ansöker om e-faktura hos din bank.',
  EInvoiceCompany: `Fyll i informationen nedan. Är du osäker eller behöver hjälp vänligen kontaka kundservice.`,
  EInvoiceCompanyAndEmail: `Fyll i informationen nedan. Är du osäker eller behöver hjälp vänligen kontaka kundservice.`,
  Email: 'En faktura skickas till dig varje månad via mail.',
  PaperMailing:
    'En pappersfaktura skickas till dig varje månad till en kostnad av 25 kr.'
}

type Props = {
  closeModal: () => void
}

export type PaymentMethodChangeConfirmation = null | {
  onConfirm: () => void
  newPaymentMethod: MailingDeliveryTypes
  newEmail?: string
}

export const InvoiceMethodModal: React.FC<Props> = ({ closeModal }) => {
  const { data: customer } = useGetCustomer()
  const [confirmationModal, setConfirmationModal] =
    useState<PaymentMethodChangeConfirmation>(null)
  const [selectedInvoiceDeliveryType, setSelectedInvoiceDeliveryType] =
    useState(customer!.invoiceMailingDeliveryType ?? 'Email')

  const invoiceEmails = customer!.emails.filter(
    (profileEmail) => profileEmail.emailTypeId === 'InvoiceStatement'
  )

  return (
    <Modal className="ProfileSettingsModal" onClose={closeModal}>
      <div className="Modal__Section ProfileSettings__Row">
        <div className="ProfileSettings__Column ProfileSettings__Column--FixedWidth">
          <h5>{'Fakturasätt'}</h5>
          <PaymentRadioGroup
            onPaymentMethodChange={setSelectedInvoiceDeliveryType}
            selectedPaymentMethod={
              selectedInvoiceDeliveryType as MailingDeliveryType
            }
          />
        </div>
        <div className="ProfileSettings__Column">
          <div className="ProfileSettings__Box">
            {paymentMethodMessageMap[selectedInvoiceDeliveryType]}
          </div>
        </div>
      </div>

      <>
        {selectedInvoiceDeliveryType !== 'EInvoice' && (
          <>
            {selectedInvoiceDeliveryType === 'Email' &&
              ((customer!.isCompany ? invoiceEmails.length === 0 : true) ? (
                <CustomerProfileEmail
                  closeModal={closeModal}
                  setConfirmationModal={setConfirmationModal}
                />
              ) : (
                <CustomerProfileEmaiInfo
                  closeModal={closeModal}
                  setConfirmationModal={setConfirmationModal}
                />
              ))}
            {selectedInvoiceDeliveryType === 'PaperMailing' && (
              <CustomerProfileAddress closeModal={closeModal} />
            )}
            {selectedInvoiceDeliveryType === 'EInvoiceCompany' && (
              <EInvoiceCompany closeModal={closeModal} />
            )}
          </>
        )}
        {confirmationModal && (
          <PaymentChangeConfirmation
            hasChangedEmail={Boolean(confirmationModal.newEmail)}
            newEmail={confirmationModal.newEmail ?? ''}
            newPaymentMethod={confirmationModal.newPaymentMethod}
            onBack={() => setConfirmationModal(null)}
            onCancel={() => setConfirmationModal(null)}
            onConfirm={() => confirmationModal.onConfirm()}
            onSave={() => confirmationModal.onConfirm()}
            saved
          />
        )}
      </>
    </Modal>
  )
}
