/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-multi-comp */
import { AnimatePresence } from 'framer-motion'
import { forwardRef } from 'react'
import { useBanners } from '../../hooks/useBanners'
import Banner from './Banner'

type BannersProps = {}

export const Banners = forwardRef<HTMLDivElement, BannersProps>(
  (props, ref) => {
    const { banners, removeBanner } = useBanners()

    return (
      <div className="Banners" ref={ref}>
        {banners && (
          <AnimatePresence>
            {Object.values(banners).map((banner) => (
              <Banner
                closeBanner={() => {
                  removeBanner(banner.id)
                }}
                key={banner.id}
                {...banner}
              />
            ))}
          </AnimatePresence>
        )}
      </div>
    )
  }
)

export default Banners
