const MS_PER_MINUTE = 60000

/**
 * @description
 * A "new Date()" wrapper that circumvents JavaScript's horrible local timezone conversion system.
 *
 * @example
 * // Assuming that the user's timezone is GMT+2:
 *
 * new Date('2019-08-15T00:00:00').toISOString()
 * // 2019-08-14T22:00:00.000Z
 *
 * castUtcDate('2019-08-15T00:00:00').toISOString()
 * // 2019-08-15T00:00:00.000Z
 */
export const castUtcDate = (input?: number | string | Date): Date => {
  const date = input ? new Date(input) : new Date()
  const userTimezoneOffset = date.getTimezoneOffset() * MS_PER_MINUTE
  return new Date(date.getTime() - userTimezoneOffset)
}
