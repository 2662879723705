import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { atom, useAtom } from 'jotai'
import { CardModel } from '../../domain/models/CardModel'
import CardMapper from '../../integrations/allkort/v1/mappers/CardMapper'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetCards
export type GetCardsParams = {
  queryOptions?: Omit<
    UseQueryOptions<Cards, unknown, Cards, unknown[]>,
    'initialData'
  >
  additionalQueryKeys?: unknown[]
}

const devCardsAtom = atom<{
  isLastCard: null | boolean
}>({ isLastCard: null })
devCardsAtom.debugLabel = 'devCardsAtom'

export const useDevCardValues = (additionalQueryKeys: unknown[] = []) => {
  const [devCardValues, setDevCardValues] = useAtom(devCardsAtom)

  const toggleIsLastCard = () => {
    setDevCardValues((prev) => ({ ...prev, isLastCard: !prev.isLastCard }))
  }

  return { values: devCardValues, toggleIsLastCard }
}

export type Cards = {
  smartCards: CardModel[]
  tankCards: CardModel[]
}

export const useGetCards = (
  options: GetCardsParams = {
    queryOptions: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, additionalQueryKeys = [] } = options
  const { cardRepo } = useRepos()

  const query = useQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: async () => {
      const fetchedCards = await cardRepo.getCards()
      const cardModels = CardMapper.getCardModels(fetchedCards)

      const cards: Cards = {
        smartCards: cardModels.filter((cardModel) => cardModel.isSmartCard),
        tankCards: cardModels.filter((cardModel) => !cardModel.isSmartCard)
      }

      return cards
    },
    keepPreviousData: true,
    ...queryOptions
  })
  return query
}
