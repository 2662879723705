import { CardType } from '../../../../domain/enums/CardType'
import { PagedResult } from '../../../../domain/models/PagedResult'
import { DepositHistoryTransaction, Transaction } from '../schemas/Transaction'
import { ProxyBase } from './ProxyBase'

export type TransactionQuery = {
  PageIndex?: number
  PageSize?: number
  startDate?: string
  endDate?: string
  cardType?: CardType
  cardNo?: number
  magstripe?: string
}

type DepositHistoryTransactionSortParam =
  | 'SiteName'
  | 'CardNo'
  | 'CardType'
  | 'ProductName'
  | 'VehicleNo'
  | 'ProcessedDate'
  | 'Price'
  | 'Amount'
  | 'Quantity'
  | 'Magstripe'

export type DepositHistoryTransactionSortingOrder = 'des' | 'asc'
export type UserProfileSortingString =
  `${DepositHistoryTransactionSortParam}+${DepositHistoryTransactionSortingOrder}`

export type DepositHistoryTransactionQuery = {
  PageIndex?: number
  PageSize?: number
  startDate?: string
  endDate?: string
  cardNo?: number
  isDeposit?: boolean
  magstripe?: string
  sortingParameters?: UserProfileSortingString
}

export class TransactionProxy extends ProxyBase<Transaction> {
  getTransactions(query: TransactionQuery): Promise<PagedResult<Transaction>> {
    try {
      return this.getArr('/api/v2/Transactions', query)
    } catch (ex) {
      throw new Error(
        'Lyckades inte hämta transaktioner. Försök igen senare eller kontakta kundservice.'
      )
    }
  }

  // getBankCardTransactions(
  //   query: TransactionQuery
  // ): Promise<PagedResult<Transaction>> {
  //   try {
  //     return this.getArr('/api/v2/Transactions/Bankcard', query)
  //   } catch (ex) {
  //     throw new Error(
  //       'Lyckades inte hämta transaktioner. Försök igen senare eller kontakta kundservice.'
  //     )
  //   }
  // }

  getBalanceHistory(
    query: DepositHistoryTransactionQuery
  ): Promise<PagedResult<DepositHistoryTransaction>> {
    try {
      // @ts-ignore
      return this.getArr('/api/v2/Transactions/PrepaidBalanceHistory', query)
    } catch (ex) {
      throw new Error(
        'Lyckades inte hämta transaktioner. Försök igen senare eller kontakta kundservice.'
      )
    }
  }
}
