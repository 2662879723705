import React from 'react'
import isEmail from 'validator/es/lib/isEmail'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import InlineAlert from '../../elements/InlineAlert'
import Input from '../../elements/Input'
import ProfileSettingsModal from '../../views/ProfileSettingsModal'

type CustomerServiceModalProps = {
  contactString: string
  onCloseModal: () => void
  onConfirmDelete: () => void
  onChangeContact: (contactString: string) => void
  heading?: string
  warning?: boolean
}

const CustomerServiceModal: React.FC<CustomerServiceModalProps> = (props) => {
  const { onCloseModal, onConfirmDelete, onChangeContact } = props
  const { data: customer } = useGetCustomer()
  const [contact, setContact] = React.useState<string>(props.contactString)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const disabled = Boolean(errorMessage || !contact)

  const contactList = customer
    ? [
        ...new Set([
          ...customer.emails.map((e) => e.emailAddress),
          ...customer.phones.map((p) => p.phoneNo)
        ])
      ]
    : []

  const handleInputChange = (contactString: string): void => {
    if (isMobilePhone(contactString) || isEmail(contactString)) {
      setContact(contactString)
      props.onChangeContact(contactString)
      setErrorMessage('')
    } else {
      setErrorMessage('Felaktigt format')
    }
  }

  const handleCancel = () => {
    setErrorMessage('')
    onChangeContact('')
    onCloseModal()
  }

  return (
    <ProfileSettingsModal
      onBack={handleCancel}
      onCancel={handleCancel}
      onConfirm={onConfirmDelete}
      onSave={onConfirmDelete}
      saveButtonState={disabled ? 'disabled' : 'enabled'}
      saveButtonText="Skicka in"
    >
      <div className="Modal__Section">
        <div className="DeleteAccountPage__Modal__Info">
          {props.heading ? (
            <h5 className="">{props.heading}</h5>
          ) : (
            <h5 className="">{'Ange kontaktuppgifter för avslut'}</h5>
          )}
          <p>
            {
              'Ange ett telefonnummer eller en mailadress vi kan kontakta dig på.'
            }
          </p>
          <Input
            className="CustomerServiceInput mt-4"
            error={errorMessage}
            errorClassName="CustomerServiceInput"
            id="contact"
            list="contacts"
            onChange={(event) => handleInputChange(event.currentTarget.value)}
          />
          <datalist id="contacts">
            {contactList.map((item, index) => (
              <option key={index} value={item} />
            ))}
          </datalist>
          {props.warning && props.warning === true && (
            <InlineAlert
              className="CustomerServiceInput"
              content={<b>{'OBS: Detta går inte att ångra senare.'}</b>}
              icon={{
                name: 'exclamation-triangle',
                primaryColor: 'black',
                secondaryColor: 'yellow500'
              }}
              type="default"
            />
          )}
        </div>
      </div>
    </ProfileSettingsModal>
  )
}

export default CustomerServiceModal
