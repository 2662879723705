import React from 'react'
import { AccountInformationModel } from '../../../domain/models/AccountInformationModel'
import { formatSEK } from '../../../utils/formatSEK'
import Accordion from '../../elements/Accordion'
import Checkbox from '../../elements/Checkbox'
import Icon from '../../elements/Icon'
import Label from '../../elements/Label'
import ReclaimBalance, { BankInfo } from './ReclaimBalance'

interface HasRemainingBalanceProps {
  bankInfo: BankInfo
  accountInformation: AccountInformationModel | null
  selectedValue: (checkedValue: CheckboxAlternative) => void
  inputFieldValue: (bankInfo: BankInfo) => void
}

export type CheckboxAlternative = 'charity' | 'reclaim' | 'none'

export const HasRemainingBalance: React.FC<HasRemainingBalanceProps> = (
  props
) => {
  const { accountInformation, bankInfo, inputFieldValue, selectedValue } = props

  const [checkedValue, setCheckValue] =
    React.useState<CheckboxAlternative>('none')

  const handleChecked = (checked: boolean, name: string | undefined) => {
    if (checked) {
      setCheckValue(name as CheckboxAlternative)
      selectedValue(name as CheckboxAlternative)
    } else {
      setCheckValue('none')
      selectedValue('none')
    }
  }

  return (
    <div className="DeleteAccountPage__Balance">
      {accountInformation && (
        <>
          <div>
            <p>
              {
                'Du har saldo kvar på ditt konto vilket kräver att du hanterar ärendet innan avslut.'
              }
            </p>

            <p className="HasRemainingBalance__Balance">
              <Icon
                className="Card__Icon"
                name="credit-card"
                primaryColor="blue400"
                secondaryColor="blue500"
              />
              <b>
                {'Aktuellt saldo: '}
                {formatSEK(accountInformation.prepaidBalance)}
              </b>
            </p>
            <p>
              {
                'Önskar du att Qstar skänker ditt kvarstående saldo till välgörande ändamål, t.ex. Cancerfonden, Barncancerfonden, Hjärt-Lungfonden, Hjärnfonden, Rädda Barnen, Läkare utan gränser eller annan som bedriver hjälpverksamhet eller vetenskaplig forskning?\r'
              }
            </p>
            <Checkbox
              aria-controls="charity_collapsable"
              aria-expanded={checkedValue === 'charity'}
              checked={checkedValue === 'charity'}
              className="d-flex align-items-center"
              onChange={handleChecked}
              propId="charity"
              textElement={
                <>
                  <Label
                    className="Checkbox__Label mb-0"
                    text="Ja!"
                    title="charity"
                  />
                </>
              }
            />
            <Checkbox
              aria-controls="reclaim_collapsable"
              aria-expanded={checkedValue === 'reclaim'}
              checked={checkedValue === 'reclaim'}
              className="d-flex align-items-center"
              onChange={handleChecked}
              propId="reclaim"
              textElement={
                <>
                  <Label
                    className="Checkbox__Label mb-0"
                    text="Nej!"
                    title="reclaim"
                  />
                </>
              }
            />
            <div className="DeleteAccountPage__Collapsable">
              <Accordion expanded={checkedValue === 'reclaim'}>
                <>
                  <p>
                    <b>
                      {
                        'För att betala ut det återstående saldot till dig behöver vi dina bankkontouppgifter.\r'
                      }
                    </b>
                  </p>
                  <ReclaimBalance
                    bankInfo={bankInfo}
                    inputFieldValue={inputFieldValue}
                  />
                </>
              </Accordion>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
