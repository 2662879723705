import {
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'
import { useEffect } from 'react'
import { PagedResult } from '../../domain/models/PagedResult'
import { UserProfileQuery } from '../../integrations/allkort/v1/repositories/UserProfileRepository'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetUserProfiles
export type GetUserProfilesParams = {
  queryOptions?: UseQueryOptions<
    PagedResult<UserProfile>,
    unknown,
    PagedResult<UserProfile>
  >
  fetchQuery: UserProfileQuery
  additionalQueryKeys?: unknown[]
}

export const useGetUserProfiles = (
  options: GetUserProfilesParams = {
    queryOptions: {},
    fetchQuery: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, fetchQuery, additionalQueryKeys = [] } = options
  const { userProfileRepo } = useRepos()
  const queryClient = useQueryClient()

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      queryKey,
      fetchQuery.PageIndex || 1,
      fetchQuery.sortingParameters,
      fetchQuery.includeLogins,
      fetchQuery.PageSize,
      ...additionalQueryKeys
    ],
    queryFn: () =>
      userProfileRepo.getUserProfilesPaged({
        PageIndex: 1,
        includeLogins: true,
        PageSize: 10,
        ...fetchQuery
      }),
    keepPreviousData: true,
    ...queryOptions
  })

  useEffect(() => {
    if (!query.isPreviousData && query.data?.hasMore) {
      const currentPage = fetchQuery.PageIndex || 1

      queryClient.prefetchQuery(
        [
          queryKey,
          currentPage + 1,
          fetchQuery.sortingParameters,
          fetchQuery.includeLogins,
          fetchQuery.PageSize,
          ...additionalQueryKeys
        ],
        {
          queryFn: queryOptions?.queryFn
            ? queryOptions.queryFn
            : () =>
                userProfileRepo.getUserProfilesPaged({
                  includeLogins: true,
                  PageSize: 10,
                  ...fetchQuery,
                  PageIndex: currentPage + 1
                })
        }
      )
    }
  }, [query.data, fetchQuery])

  return query
}
