import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetFooter
export type GetFooterParams = {
  queryOptions?: Omit<
    UseQueryOptions<Footer, unknown, Footer, unknown[]>,
    'initialData'
  >
  additionalQueryKeys?: unknown[]
}

export type Footer = string

export const useGetFooter = (
  options: GetFooterParams = {
    queryOptions: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, additionalQueryKeys = [] } = options

  const query = useQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: async () => {
      const response = await fetch(
        `${import.meta.env.VITE_WORDPRESS_URL}/wp-json/qstar-api/v1/footer-html`
      )
      const footerMarkup = await response.json()

      return footerMarkup
    },
    keepPreviousData: true,
    ...queryOptions
  })

  return query
}
