import { UseInfiniteQueryResult } from '@tanstack/react-query'
import React from 'react'
import { TransactionsModel } from '../../domain/models/transactionsModel'
import Icon from '../elements/Icon'
import Loader from '../elements/Loader'
import TransactionTable from './TransactionTable'

type Props = {
  transactionsQuery: UseInfiniteQueryResult<TransactionsModel, unknown>
}

const Uninvoiced: React.FC<Props> = ({ transactionsQuery }) => {
  const { isLoading, data, hasNextPage, isFetchingNextPage, fetchNextPage } =
    transactionsQuery
  if (isLoading) {
    return <Loader noPadding noText style={{ padding: '.5rem 0 2.5rem' }} />
  }

  if (!isLoading && !data) {
    return <p>{'Något gick fel'}</p>
  }

  if (data!.pages.at(0)?.payload?.length === 0) {
    return <p>{'Du har inget ofakturerat belopp.'}</p>
  }

  return (
    <>
      <div className="Uninvoiced">
        <TransactionTable
          hasMore={hasNextPage}
          hideRecieptForBonusTransactions
          isOdd
          transactionsQuery={data}
          isBankCard={false}
		  magstripe=''
        />
        <div className="Uninvoiced__ActionContainer">
          {hasNextPage && (
            <button
              className="Uninvoiced__LoadMoreButton"
              onClick={() => fetchNextPage()}
              type="button"
            >
              <Icon
                className="Uninvoiced__ButtonIcon"
                fixedWidth
                name="plus"
                type="line"
              />
              {isFetchingNextPage ? 'Laddar fler' : 'Visa fler'}
              {isFetchingNextPage && (
                <Icon
                  className="Uninvoiced__LoadingSpinner"
                  fixedWidth
                  name="spinner"
                  spin
                  type="line"
                />
              )}
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default Uninvoiced
