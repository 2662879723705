import { TransactionModel } from '../../../../domain/models/TransactionModel'
import { castUtcDate } from '../../../../utils/castUtcDate'
import { Transaction } from '../schemas/Transaction'

export class TransactionModelMapper {
  getTransactionModel = (transaction: Transaction): TransactionModel => {
    return new TransactionModel(
      transaction.transactionId,
      castUtcDate(transaction.dateTime),
      transaction.siteName,
      transaction.productName,
      transaction.amount,
      transaction.transactionType,
      transaction.mailingNo,
	  transaction.magstripe
    )
  }
}
