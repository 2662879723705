import { useRef } from 'react'
import { AgreementApprovalProxy } from '../integrations/allkort/v1/proxies/AgreementApprovalProxy'
import { BankCardProxy } from '../integrations/allkort/v1/proxies/BankCardProxy'
import { BankCardReceipsProxy } from '../integrations/allkort/v1/proxies/BankCardReceiptsProxy'
import { CardProxy } from '../integrations/allkort/v1/proxies/CardProxy'
import { CustomerProxy } from '../integrations/allkort/v1/proxies/CustomerProxy'
import { AccountInformationRepository } from '../integrations/allkort/v1/repositories/AccountInformationRepository'
import { BalanceHistoryRepository } from '../integrations/allkort/v1/repositories/BalanceHistoryRepository'
import { InvoicesRepository } from '../integrations/allkort/v1/repositories/InvoicesRepository'
import { ProfileRepository } from '../integrations/allkort/v1/repositories/ProfileRepository'
import { TransactionsRepository } from '../integrations/allkort/v1/repositories/TransactionsRepository'
import { UserProfileRepository } from '../integrations/allkort/v1/repositories/UserProfileRepository'
import { AgreementsProxy } from '../integrations/backend/v1/proxies/AgreementsProxy'
import { AuthService } from '../integrations/backend/v1/services/AuthService'

export const useRepos = () => {
  const apiToken = AuthService.loadAccessToken() as string

  const { current: repos } = useRef({
    profileRepo: new ProfileRepository(apiToken),
    userProfileRepo: new UserProfileRepository(apiToken),
    invoiceRepo: new InvoicesRepository(apiToken),
    accountInformationRepo: new AccountInformationRepository(apiToken),
    balanceHistoryRepo: new BalanceHistoryRepository(apiToken),
    transactionsRepo: new TransactionsRepository(apiToken),
    customerRepo: new CustomerProxy(apiToken),
    cardRepo: new CardProxy(apiToken),
    BankCardRepo: new BankCardProxy(apiToken),
    BankCardReceipsRepo: new BankCardReceipsProxy(apiToken),

    agreementApprovalRepo: new AgreementApprovalProxy(apiToken),
    agreementRepo: AgreementsProxy
  })

  return repos
}
