/* eslint-disable react/no-multi-comp */
/* eslint-disable no-confusing-arrow */
import classNames from 'classnames'
import React from 'react'
import { InvoiceModel } from '../../domain/models/InvoiceModel'
import Link from '../elements/Link'

type InvoiceLinkProps = {
  invoice: InvoiceModel
  isOdd: boolean
}

const InvoiceLink: React.FC<InvoiceLinkProps> = ({ invoice, isOdd }) => {
  return (
    <tr
      className={classNames({
        Invoice__Row: true,
        'Invoice__Row--IsOdd': isOdd,
        'Invoice__Row--Transaction': true
      })}
    >
      <td className="Invoice__Cell Invoice__Cell--Date" colSpan={4}>
        <div className="Invoice__Cell--DeliveryType">
          {invoice.deliveryType}
          <br />
          <Link
            className="Link--Subtle"
            href={`${import.meta.env.VITE_BACKEND_URL}/api/Invoices/Faktura ${
              invoice.invoiceID
            }.pdf`}
            openNewTab
            styled
          >
            {'Se faktura'}
          </Link>
        </div>
      </td>
    </tr>
  )
}

export default InvoiceLink
