/* eslint-disable react/no-multi-comp */
import classNames from 'classnames'
import React, { Suspense, useState } from 'react'
import { PaymentStatus } from '../../domain/enums/PaymentStatus'
import { InvoiceModel } from '../../domain/models/InvoiceModel'
import { useIsMobile } from '../../hooks/useIsMobile'
import { formatSEK } from '../../utils/formatSEK'
import stringDate from '../../utils/stringDate'
import Icon from '../elements/Icon'
import PaymentBadge from '../elements/PaymentBadge'
import { InformationRow } from '../InformationRow'
import InvoiceLink from './InvoiceLink'
import InvoiceRelatedTransactions from './InvoiceRelatedTransactions'

type Props = {
  invoice: InvoiceModel
  previousInvoiceDate: Date
  isOdd: boolean
}

const Invoice: React.FC<Props> = ({ invoice, previousInvoiceDate, isOdd }) => {
  const isMobile = useIsMobile()
  const [expanded, setExpanded] = useState(false)

  const paymentStatus = invoice.getPaymentStatus()
  const shouldShowExpDate =
    paymentStatus === PaymentStatus.Due ||
    paymentStatus === PaymentStatus.Overdue

  const handleInvoiceClick = (): void => {
    setExpanded((oldState) => !oldState)
  }

  /**
   * This is only unsed to align the items on the rows
   * that include the "förfaller:" text.
   */
  const AlignElement = () => (!isMobile && shouldShowExpDate ? <br /> : <></>)

  return (
    <>
      <tr
        className={classNames({
          Invoice: true,
          Invoice__Row: true,
          'Invoice__Row--Expanded': expanded,
          'Invoice__Row--IsOdd': isOdd,
          'Invoice__Row--Main': true,
          'Invoice--Overdue': paymentStatus === PaymentStatus.Overdue,
          'Invoice--Due': paymentStatus === PaymentStatus.Due
        })}
        onClick={handleInvoiceClick}
      >
        <td className="Invoice__Cell Invoice__Cell--Date">
          <div>
            <span>
              <AlignElement />
              <Icon
                className={classNames(
                  'Invoice__ExpandIcon',
                  expanded && 'Invoice__ExpandIcon--Expanded'
                )}
                name={expanded === true ? 'chevron-down' : 'chevron-right'}
                primaryColor={
                  paymentStatus === PaymentStatus.Overdue ? 'red400' : 'blue400'
                }
                secondaryColor={
                  paymentStatus === PaymentStatus.Overdue ? 'red400' : 'blue400'
                }
              />
            </span>
            <span>
              <AlignElement />
              {stringDate(invoice.invoiceDate)}
            </span>
          </div>
        </td>
        <td className="Invoice__Cell">
          {!isMobile && shouldShowExpDate && (
            <span className="Invoice__Cell--ExpirationDate">
              {'Förfaller: '}
              <br />
              {stringDate(invoice.expirationDate)}
            </span>
          )}
        </td>
        <td className="Invoice__Cell Invoice__Cell--InvoiceID">
          {invoice.invoiceID}
        </td>
        <td className="Invoice__Cell Invoice__Cell--TotalAmount">
          {shouldShowExpDate ? (
            <>
              {'Återstår:'}
              <br />
              {formatSEK(invoice.remainingAmount)}
            </>
          ) : (
            <>
              <AlignElement />
              {formatSEK(invoice.totalAmount)}
            </>
          )}
        </td>
        <td className="Invoice__Cell Invoice__Cell--Status">
          <div>
            {shouldShowExpDate && isMobile && (
              <span style={{ display: 'block' }}>
                {'Förfaller'}
                <br />
                {stringDate(invoice.expirationDate)}
              </span>
            )}
            <span>
              <AlignElement />
              <PaymentBadge invoice={invoice} />
            </span>
          </div>
        </td>
      </tr>
      {expanded && (
        <>
          <InvoiceLink invoice={invoice} isOdd={isOdd} />
          <Suspense
            fallback={
              <InformationRow
                colSpan={4}
                text="Laddar relaterade transaktioner..."
              />
            }
          >
            <InvoiceRelatedTransactions
              invoice={invoice}
              isOdd={isOdd}
              previousInvoiceDate={previousInvoiceDate}
            />
          </Suspense>
        </>
      )}
    </>
  )
}

export default Invoice
