import React from 'react'
import CardsDevTools from './CardsDevTools'
import CustomerDevTools from './CustomerDevTools'
import UserDevToolsContainer from './UserDevToolsContainer'
import UserRightDevTools from './UserRightDevTools'

type UserDevToolsProps = {}

const UserDevTools: React.FC<UserDevToolsProps> = (props) => {
  return (
    <UserDevToolsContainer>
      <UserRightDevTools />
      <CustomerDevTools />
      <CardsDevTools />
    </UserDevToolsContainer>
  )
}

export default UserDevTools
