import React, { CSSProperties, ReactElement } from 'react'
import { Placement } from 'react-bootstrap/esm/types'
import { CustomTooltip } from './CustomTooltip'

export type ToolTipWrapperProps = {
  text: string
  enabled?: boolean
  tooltipPlacement?: Placement
  children: ReactElement<unknown>
  noHoverState?: boolean
  style?: CSSProperties
}

const ToolTipWrapper: React.FC<ToolTipWrapperProps> = ({
  text,
  enabled = true,
  noHoverState,
  tooltipPlacement,
  children,
  style
}) => {
  if (!enabled) {
    return children
  }

  return (
    <CustomTooltip
      noHoverState={noHoverState}
      style={style}
      text={text}
      tooltipPlacement={tooltipPlacement}
    >
      {children}
    </CustomTooltip>
  )
}

export default ToolTipWrapper
