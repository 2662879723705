import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetWordpressBanners

export type FetchedBanner = {
  acf_fc_layout: 'banner'
  text: string
  url?: string
  show: boolean
  show_on_pages: string | string[]
  hide_on_pages: string | string[]
  show_on_my_pages: boolean
}

const getWordpressBanners = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_WORDPRESS_URL}/wp-json/wp/v2/acf/options`
  )
  const data = await response.json()
  const banners = data?.banners as FetchedBanner[]

  if (!banners) {
    return []
  }

  return banners
}

type UserGetInvoicesParams = {
  queryOptions?: UseQueryOptions<
    FetchedBanner[],
    unknown,
    FetchedBanner[],
    unknown[]
  >
  additionalQueryKeys?: unknown[]
}

export const useGetWordpressBanners = (
  options: UserGetInvoicesParams = {
    queryOptions: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, additionalQueryKeys = [] } = options

  return useQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: getWordpressBanners,
    /**
     * Only refetch on page reload
     */
    staleTime: Infinity,
    ...queryOptions
  })
}
