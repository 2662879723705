import classNames from 'classnames';
import React from 'react';
import Icon from './Icon';

type ScrollindicatorProps = {
	mobileOnly: boolean,
	topMargin?: string,
	bottomPadding?: string,
	customStyles?: Object
}

const ScrollIndicator: React.FC<ScrollindicatorProps> = ({
	mobileOnly,
	topMargin,
	bottomPadding,
	customStyles
}) => {
	let wrapperClasses = ''
	if ( mobileOnly ) {
		wrapperClasses += 'mobile-only'
	}

	let wrapperStyles = {
		marginTop: topMargin ? topMargin : '',
		paddingBottom: bottomPadding ? bottomPadding : '',
	}

	let mergedStyles = {}
	if ( customStyles ) {
		mergedStyles = { ...wrapperStyles, ...customStyles };
	} else {
		mergedStyles = wrapperStyles;
	}
	return (
		<div className={classNames('scroll-indicator', wrapperClasses)} style={mergedStyles}>
			<div className="scroll-indicator__icons">
				<Icon name="arrow-left" />
				<Icon name="arrow-right" />
			</div>
		</div>
	)
}

export default ScrollIndicator
