import PropTypes from 'prop-types'
import React from 'react'
import * as WPAPI from '../../legacy/wpapi'

type Props = {
  name: WPAPI.File['name']
}

const Illustration: React.FunctionComponent<Props> = ({ name }) => {
  return (
    <img
      alt=""
      className="Illustration"
      data-name={name}
      src={`/static/images/${name}.png`}
    />
  )
}

Illustration.propTypes = {
  name: PropTypes.string.isRequired
}

export default Illustration
