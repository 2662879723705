import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import upperFirst from '../../utils/upperFirst'
import Illustration from './Illustration'

type Props = {
  align?: 'center'
  background?: string
  color?: string
  illustrations?: string[]
  name?: string
  style?: React.CSSProperties
  children: React.ReactNode
}

const detachedIllustrations: Record<string, boolean> = {
  bransle_mobile_2: true,
  bransle_mobile_3: true,
  kund_mobile_1: true,
  'old/kontakt_slang1': true,
  'old/omoss_slang1': true,
  'old/omoss_slang3': true,
  'old/startsida_slang1': true,
  'old/startsida_slang1a': true,
  'old/startsida_slang1b': true,
  startsida_mobile_2: true
}

const Section: React.FunctionComponent<Props> = ({
  align,
  background,
  children,
  color = 'white',
  illustrations,
  name,
  style
}) => {
  const illustrationData = illustrations?.map((illustration) => {
    return {
      detached: detachedIllustrations[illustration],
      element: <Illustration key={illustration} name={illustration} />
    }
  })

  return (
    <>
      <div
        className={classNames(
          'Section',
          align && `Section--Align${upperFirst(align)}`,
          'Section--Block',
          name && `Section--${upperFirst(name)}`,
          `BgColor--${upperFirst(color)}`
        )}
        style={{
          backgroundImage: background ? `url(${background})` : undefined,
          ...style
        }}
      >
        {illustrationData
          ?.filter(({ detached }) => !detached)
          .map(({ element }) => element)}
        {children}
      </div>
      {illustrationData
        ?.filter(({ detached }) => detached)
        .map(({ element }) => element)}
    </>
  )
}

Section.propTypes = {
  align: PropTypes.oneOf(['center']),
  background: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  illustrations: PropTypes.any,
  name: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default Section
