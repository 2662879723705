import classNames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router-dom'
import parseUrl from 'url-parse'
import { MenuItem } from '../../legacy/mock/components/menuItems'
import Button from './Button'
import Icon from './Icon'
import Link from './Link'

type Props = {
  headerItems: MenuItem[]
  sideItems: MenuItem[]
  isCompany: boolean
  transparent?: boolean
}

const Menu: React.FC<Props> = ({
  headerItems,
  sideItems,
  isCompany,
  transparent
}) => {
  const location = useLocation()
  const prettyfyName = (name: string) => {
    if ( ! name ) {
      return 'Användare'
    }
    const newName = name.split(' ').map( (singleName) => (
      singleName = singleName.charAt(0).toUpperCase() + singleName.slice(1).toLowerCase()
    ) ).join(' ')
    return newName;
  }

  return (
    <nav
      className={classNames({
        Menu: true,
        'Menu--Transparent': transparent
      })}
    >
      <div className="Menu__Child Menu__LogoContainer">
        <Link href="/">
          <img
            alt="Qstars logotyp"
            className="Menu__Logo"
            src="/static/images/qstar_logo_yellow.png"
          />
          {isCompany && <div className="Menu__CompanyHeader">{'FÖRETAG'}</div>}
        </Link>
      </div>
      <ul className="Menu__Child List--Unstyled Menu__Items Menu__Items--Navigation">
        {headerItems.map((item) => {
          const itemClass = classNames(
            'Menu__Item',
            item.url && {
              'Menu__Item--Active':
                parseUrl(item.url).pathname === location.pathname ||
                location.pathname.includes(`/${item.url}`)
            },
            item.classes
          )
          return (
            <li className={itemClass} key={item.url ? item.url : item.title}>
              <Link ariaLabel={item.title} href={`/${item.url}`}>
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
      <ul className="Menu__Child List--Unstyled Menu__Items Menu__Items--Subtle">
        {sideItems.map((item) => {
          return (
            <li
              className={classNames({
                Menu__Item: true,
                'Menu__Item--Cta': item.classes?.includes('Button')
              })}
              key={item.url ? item.url : item.title}
            >
              {
                // If item specically has the shouldBeLink key set to false
                // then render it outside of the button
                // eslint-disable-next-line no-negated-condition
                item.shouldBeLink === false ? (
                  <>
                    <div className={classNames(item.classes)}>
                      {item.icon && <Icon name={item.icon.name} type="line" />}
                      <p className="ms-2 mb-0">{item.title}</p>
                    </div>
                  </>
                ) : (
                  <Button
                    className={classNames(item.classes)}
                    icon={item.icon}
                    url={`/${item.url}`}
                  >
                    { 'profil' === item.url ? (
                      // Sometimes the name is capitalized so this fixes the profile name
                      prettyfyName(item.title)
                    ) : (
                      item.title 
                    )
                    }
                  </Button>
                )
              }
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Menu
