import * as React from 'react'
import { InputStateObject } from '../../../../../hooks/useForm'
import ProfileSettingsModal from '../../ProfileSettingsModal'
import { EInvoiceType, SubmitObject } from './EInvoiceCompany'

interface ConfirmEInvoiceProps {
  invoiceType: EInvoiceType
  onBack: () => void
  onCancel: () => void
  onSave: () => void
  saved: boolean
  submitObject: InputStateObject<SubmitObject>
}

export const ConfirmEInvoice: React.FC<ConfirmEInvoiceProps> = (props) => {
  const { invoiceType, onBack, onCancel, onSave, saved, submitObject } = props

  return (
    <>
      <ProfileSettingsModal
        isPaymentSettings={false}
        onBack={onBack}
        onCancel={onCancel}
        onSave={onSave}
        saveButtonText="Bekräfta"
        saved={saved}
      >
        <div className="Modal__Section">
          {invoiceType === 'peppol' ? (
            <h5>
              {'Vill du byta fakturasätt till E-faktura via Peppol-nätverket?'}
            </h5>
          ) : (
            <h5>
              {'Vill du byta fakturasätt till E-faktura via VAN-operatör?'}
            </h5>
          )}
          <p className="p-0 m-0">
            {'Kontrollera uppgifterna innan du skickar in:'}
          </p>
          <p className="p-0 m-0" hidden={invoiceType === 'peppol'}>
            {'Operatör: '}
            <b>{submitObject.invoiceExchance.value}</b>
          </p>
          <p className="p-0 m-0">
            {invoiceType === 'peppol' ? (
              <>{'Peppol-ID: '}</>
            ) : (
              <>{'Parts-ID: '}</>
            )}
            <b>{submitObject.invoiceId.value}</b>
          </p>
          <p className="p-0 m-0" hidden={invoiceType === 'peppol'}>
            {'GLN: '}
            <b>{submitObject.eanCode.value}</b>
          </p>
          <p className="p-0 m-0">
            {'Beställarreferens: '}
            <b>{submitObject.referenceNo.value}</b>
          </p>
        </div>
      </ProfileSettingsModal>
    </>
  )
}
