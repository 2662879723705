import { InvoiceModel } from '../../../../domain/models/InvoiceModel'
import { TransactionModel } from '../../../../domain/models/TransactionModel'
import { castUtcDate } from '../../../../utils/castUtcDate'
import { Invoice } from '../schemas/Invoice'

export class InvoiceModelMapper {
  getInvoiceModel = (
    invoice: Invoice,
    transactions: TransactionModel[]
  ): InvoiceModel => {
    const result = new InvoiceModel(
      invoice.deliveryType ?? '',
      invoice.invoiceNo,
      castUtcDate(invoice.invoiceDate),
      invoice.amount,
      castUtcDate(invoice.dueDate),
      null,
      invoice.remains,
      transactions,
      invoice.ocr
    )

    return result
  }
}
