import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { UpdateUserProfileInfoArgs } from '../../integrations/allkort/v1/repositories/UserProfileRepository'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UsePutUserProfileInfoArgs = {
  queryOptions?: UseMutationOptions<
    UserProfile,
    Error,
    UpdateUserProfileInfoArgs,
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}

export type UsePutUserProfileInfoMutationArgs = UpdateUserProfileInfoArgs

export const usePutUserProfileInfo = (args: UsePutUserProfileInfoArgs = {}) => {
  const {
    queryOptions = {},
    extendedOnSuccessFunction,
    extendedOnErrorFunction
  } = args
  const { userProfileRepo } = useRepos()
  const { addToast } = useToasts()
  const invalidateQuery = useInvalidateQuery()

  return useMutation(
    (newUserInfoArgs: UsePutUserProfileInfoMutationArgs) =>
      userProfileRepo.updateUserProfileInfo(newUserInfoArgs),
    {
      onSuccess: (status, { updatedUserData: { firstName, lastName } }) => {
        addToast(`Uppgifterna för ${firstName} ${lastName} är nu uppdaterade`)
        setTimeout(() => invalidateQuery('user-profiles'), 200)

        extendedOnSuccessFunction?.()
      },
      onError: (status, { updatedUserData: { firstName, lastName } }) => {
        addToast({
          message: `Lyckades inte uppdatera ${firstName} ${lastName}`,
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      ...queryOptions
    }
  )
}
