import { SaveState } from '../enums/SaveState'

export type State = {
  saveState: SaveState
  errorMessage: string
  propertyErrors: { [property: string]: string }
}

export const initialState = (): State => {
  return {
    errorMessage: '',
    propertyErrors: {},
    saveState: SaveState.OK
  }
}

export const setSaving = (state: State): State => {
  return {
    ...state,
    saveState: SaveState.Saving
  }
}

export const setFailed = (state: State, errorMessage: string): State => {
  return {
    ...state,
    errorMessage,
    saveState: SaveState.Failed
  }
}

export const hasErrors = (state: State): boolean => {
  for (const key in state.propertyErrors) {
    if (Object.prototype.hasOwnProperty.call(state.propertyErrors, key)) {
      return true
    }
  }

  return false
}

export const isValid = (state: State): boolean => {
  return !hasErrors(state)
}
