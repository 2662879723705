/* eslint-disable no-confusing-arrow */
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions
} from '@tanstack/react-query'
import { InvoicesModel } from '../../domain/models/InvoicesModel'
import { InvoiceQuery } from '../../integrations/allkort/v1/proxies/InvoiceProxy'
import { INVOICE_PAGE_SIZE } from '../../integrations/allkort/v1/repositories/InvoicesRepository'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetInvoices

type UserGetInvoicesParams = {
  queryOptions?: UseInfiniteQueryOptions<InvoicesModel, unknown, InvoicesModel>
  fetchQuery?: InvoiceQuery
  additionalQueryKeys?: unknown[]
}

export const useGetInvoices = (
  options: UserGetInvoicesParams = {
    queryOptions: {},
    fetchQuery: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, fetchQuery = {}, additionalQueryKeys = [] } = options

  const { invoiceRepo } = useRepos()
  return useInfiniteQuery({
    queryKey: [queryKey, INVOICE_PAGE_SIZE, fetchQuery, ...additionalQueryKeys],
    queryFn: ({ pageParam = 1 }) =>
      invoiceRepo.getInvoices({
        PageIndex: pageParam,
        PageSize: INVOICE_PAGE_SIZE,
        ...fetchQuery
      }),
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.nextPage : undefined,
    /**
     * Only refetch and validate invoices evey minute
     * as invoices are unlikely to change often
     */
    staleTime: 1000 * 60,
    ...queryOptions
  })
}
