/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { usePostCustomerServiceTask } from '../../../hooks/queryHooks/usePostCustomerServiceTask'
import useIsBankIdUser from '../../../hooks/useIsBankIdUser'
import { useToasts } from '../../../hooks/useToasts'
import { useUserRights } from '../../../hooks/useUserRights'
import CustomerServiceModal from '../../pages/DeleteAccount/CustomerServiceModal'
import ProfileBox from './ProfileBox'

type Props = {}

export const CustomerProfileVechicleSpecification: React.FC<Props> = () => {
  const isBankIdUser = useIsBankIdUser()
  const { addToast } = useToasts()
  const { userHasRight } = useUserRights()
  const { mutate: postCustomerServiceTask } = usePostCustomerServiceTask({
    queryOptions: {
      onSuccess: () => {
        addToast('Ansökan om fordonspecifikation skickad')
      },
      onError: () =>
        addToast({
          message: 'Din ansökan gick inte att skicka. Försök igen senare.',
          isError: true
        })
    }
  })

  const { data: customer } = useGetCustomer()
  const hasVehicleSpecification = customer!.hasVehicleSpecification

  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [contactString, setContactString] = React.useState<string>('')

  const handleRequestVehicleSpecification = () => {
    const message =
      'Ansökan om fordonspecifikation. \n Kontakt via: ' + contactString

    postCustomerServiceTask(message)
    setEditMode(false)
  }

  return (
    <div className="VehicleSpecification">
      {editMode && (
        <CustomerServiceModal
          contactString={contactString}
          heading="Ange kontaktuppgifter för ansökan"
          onChangeContact={setContactString}
          onCloseModal={() => setEditMode(false)}
          onConfirmDelete={handleRequestVehicleSpecification}
        />
      )}
      <h4>{'Fordonspecifikation'}</h4>
      <ProfileBox>
        <p className="VehicleSpecification__ShortDescription">
          {
            'Fordonspecifikation är en detaljerad vy med dina tankningar över månaden som skickas med din faktura.'
          }
        </p>
        <p className="VehicleSpecification__LongDescription">
          {
            'Detta är ett bra komplement för dig med flera kort och som vill ha koll på förbrukning per kort. Kan kompletteras med fordons-id och även km om man önskar.'
          }
        </p>
        {hasVehicleSpecification ? (
          <>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginBottom: '18px'
              }}
            >
              <b>{' Fordonspecifikation är aktiverat.'}</b>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                marginBottom: '18px'
              }}
            >
              <b>{'Fordonspecifikation är inte aktiverat.'}</b>
            </div>
            {!customer!.isFrozen &&
              isBankIdUser &&
              userHasRight('CustomerWrite') && (
                <div>
                  <small>
                    <a
                      className="Link"
                      onClick={() => setEditMode(true)}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                    >
                      {'Klicka här'}
                    </a>
                    {' för att ansöka om fordonspecifikation.'}
                  </small>
                </div>
              )}
          </>
        )}
      </ProfileBox>
    </div>
  )
}
