/* eslint-disable no-restricted-syntax */
/* eslint-disable func-style */
import React, { Suspense, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardModel } from '../../domain/models/CardModel'
import { useGetCards } from '../../hooks/queryHooks/useGetCards'
import useGetTransactionsLastSixMonths from '../../hooks/queryHooks/useGetTransactionsLastSixMonths'
import Icon from '../elements/Icon'
import Label from '../elements/Label'
import RadioGroup from '../elements/RadioGroup'
import CardReplacementForm from '../views/CardReplacementForm'
import CardsList from '../views/CardsList'

export type LocationState = {
  cardNo?: string
}

type Props = {}

export const RequestCardPage: React.FC<Props> = () => {
  /**
   * Fetching beforehand to prevent loading state
   * in replacement card view
   */
  useGetTransactionsLastSixMonths()

  const { data } = useGetCards()
  const navigate = useNavigate()

  const cards = data ? [...data.smartCards, ...data.tankCards] : []

  const [selectedCard, setSelectedCard] = useState<CardModel>()

  const handleSelectedCardChange = (event: React.ChangeEvent): void => {
    const element = event.currentTarget as HTMLInputElement

    const card = cards.find((c) => c.cardNo === element.value)

    setSelectedCard(card)
  }

  return (
    <div className="Section RequestCardPage BgColor--Red400">
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          <div className="Column Column--Md6 Column--Lg6 Column--AlignStart">
            <h1>{'Ersättningskort'}</h1>
            <p style={{ marginBottom: 16 }}>
              {'Ifall ditt kort är slitet eller borttappat.'}
              {' Fyll i fälten nedan, så skickar vi ut ett ersättningskort.'}
            </p>
            <p className="Card__PriceBanner">
              <Icon
                className="Card__Icon"
                name="check"
                primaryColor="green500"
                secondaryColor="green500"
              />
              <b>{'Kostnadsfritt!'}</b>
            </p>
            <div className="RequestCardPage__Area">
              <RadioGroup
                buttons={[
                  {
                    text: 'Nytt kort',
                    value: '/nyttkort'
                  },
                  {
                    text: 'Ersättningskort',
                    value: '/ersattningskort'
                  }
                ]}
                className="ProfileSettings__RadioGroup"
                onChange={(href) => {
                  navigate(href)
                }}
                value="/ersattningskort"
              />
              <div className="CardSource">
                <Label text="Välj ett orginalkort:" vertical>
                  <CardsList
                    className="CardSource__Dropdown RequestCardPage__Dropdown"
                    dropDownCardValue={selectedCard?.cardNo ?? 'none'}
                    model={cards}
                    onChangeDropdownCard={handleSelectedCardChange}
                    showOldCards={false}
                  />
                </Label>
              </div>
              {selectedCard && (
                <Suspense fallback={<p>{'Laddar kort!'}</p>}>
                  <CardReplacementForm
                    card={selectedCard}
                    onCancel={() => setSelectedCard(undefined)}
                  />
                </Suspense>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestCardPage
