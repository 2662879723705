import { useRef } from 'react'

export const useScrollToPosition = () => {
  const ref = useRef<HTMLTableElement>(null)

  const scrollTo = (position: 'top' | 'bottom') => {
    if (ref.current?.scroll) {
      ref.current.scroll({
        top: position === 'bottom' ? ref.current.scrollHeight : 0,
        behavior: 'smooth'
      })
    }
  }
  return { ref, scrollTo }
}
