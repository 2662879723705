import parseUrl from 'url-parse'

const WORDPRESS_URL = import.meta.env.VITE_WORDPRESS_URL

export default (
  to: string
): { hash: string; isInternal: boolean; url: string } => {
  const { hash, origin, pathname } = parseUrl(to)

  const url = origin === WORDPRESS_URL ? pathname + hash : to
  const isInternal = !url.includes('//') && !url.includes(':')

  return {
    hash,
    isInternal,
    url
  }
}
