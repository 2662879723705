import React from 'react'
import Button from '../elements/Button'
import Icon from '../elements/Icon'

type PaginationProps = {
  onChange: (pageNr: number) => void
  maxPages: number
  currentPage: number
}

const Pagination: React.FC<PaginationProps> = ({
  onChange,
  maxPages,
  currentPage
}) => {
  return (
    <nav
      aria-label="Pagination Navigation"
      className="Pagination"
      role="navigation"
    >
      <ul
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '.4rem',
          paddingLeft: '0'
        }}
      >
        <li>
          <Button
            aria-disabled={currentPage === 1}
            aria-label="Föregående sida"
            design="round"
            onClick={() => onChange(currentPage - 1)}
            style={{
              paddingRight: '3px'
            }}
            title={`Navigera till föregående sida (${currentPage - 1})`}
          >
            <Icon name="chevron-left" />
          </Button>
        </li>
        {new Array(maxPages).fill(maxPages).map((_, index) => {
          const pageNumber = index + 1

          if (pageNumber === currentPage) {
            return (
              <li key={pageNumber}>
                <span
                  aria-label={`Sida ${pageNumber}`}
                  className="Pagination__Button Pagination__Button--Current"
                  title="Nuvarande sida"
                >
                  {pageNumber}
                </span>
              </li>
            )
          }

          if (
            pageNumber === 1 ||
            pageNumber === currentPage + 1 ||
            pageNumber === currentPage - 1 ||
            pageNumber === maxPages
          ) {
            return (
              <li key={pageNumber}>
                <button
                  aria-label={`Sida ${pageNumber}`}
                  className="Pagination__Button"
                  onClick={() => onChange(pageNumber)}
                  title={`Navigera till sida ${pageNumber}`}
                >
                  {pageNumber}
                </button>
              </li>
            )
          }

          if (
            (pageNumber === 2 && currentPage > 3) ||
            (currentPage < maxPages - 2 && pageNumber === maxPages - 1)
          ) {
            return (
              <li key={pageNumber}>
                <span>{'...'}</span>
              </li>
            )
          }

          return null
        })}
        <li>
          <Button
            aria-disabled={currentPage === maxPages}
            aria-label="Nästa sida"
            design="round"
            onClick={() => onChange(currentPage + 1)}
            style={{
              paddingLeft: '3px'
            }}
            title={`Navigera till nästa sida (${currentPage + 1})`}
          >
            <Icon name="chevron-right" />
          </Button>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
