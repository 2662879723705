import classnames from 'classnames'
import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react'
import Icon from './Icon'
const variationAttributes = {
  edit: {
    name: 'pencil'
  },
  delete: {
    name: 'trash',
    type: 'line'
  }
} as const

const buttonSizes = {
  small: {
    fontSize: '1.2rem'
  },
  medium: {
    fontSize: '1.4rem'
  },
  large: {
    fontSize: '1.6rem'
  }
} as const

export type ActionButtonProps = {
  title: string
  isVisuallyDisabled?: boolean
  variation?: keyof typeof variationAttributes
  size?: keyof typeof buttonSizes
  onClick: React.MouseEventHandler<HTMLButtonElement>
} & ButtonHTMLAttributes<HTMLButtonElement>

const ActionButton: React.FC<ActionButtonProps> = ({
  className,
  variation,
  type,
  children,
  size,
  isVisuallyDisabled,
  onClick,
  ...props
}) => {
  const classNames = classnames('action-button', {
    [`action-button--${variation}`]: Boolean(variation),
    'action-button--visually-disabled': isVisuallyDisabled
  })

  const handleOnClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    if (isVisuallyDisabled) {
      return
    }
    onClick(e)
  }

  return (
    <button
      aria-disabled={isVisuallyDisabled}
      className={classNames}
      onClick={handleOnClick}
      style={{ ...buttonSizes[size ?? 'medium'] }}
      type={type ?? 'button'}
      {...props}
    >
      {children ?? (
        <Icon
          className="action-button__icon"
          {...variationAttributes[variation ?? 'edit']}
        />
      )}
    </button>
  )
}

export default ActionButton
