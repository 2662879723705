/* eslint-disable sort-keys */

import { InvoicesModel } from '../../../domain/models/InvoicesModel'
import { InvoiceModelMapper } from '../../../integrations/allkort/v1/mappers/InvoiceModelMapper'
import { mockTransactions } from './mockTransactions'

const mapper = new InvoiceModelMapper()

export const mockInvoices = new InvoicesModel([], 1, false)

mockInvoices.payload = [
  mapper.getInvoiceModel(
    {
      invoiceNo: 9121285,
      invoiceDate: '2020-11-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '912128592',
      amount: 3206,
      dueDate: '2021-01-28T00:00:00',
      payed: 50,
      remains: 3156
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 9069639,
      invoiceDate: '2020-11-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '906963897',
      amount: 3120,
      dueDate: '2020-12-28T00:00:00',
      payed: 0,
      remains: 3120
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 9069638,
      invoiceDate: '2020-11-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '906963897',
      amount: 2948,
      dueDate: '2020-10-28T00:00:00',
      payed: 2948,
      remains: 0
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 9016993,
      invoiceDate: '2020-09-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '901699397',
      amount: 3700,
      dueDate: '2020-09-28T00:00:00',
      payed: 3700,
      remains: 0
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 8963753,
      invoiceDate: '2020-08-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '896375391',
      amount: 4907,
      dueDate: '2020-08-28T00:00:00',
      payed: 4907,
      remains: 0
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 8911994,
      invoiceDate: '2020-07-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '891199499',
      amount: 7207,
      dueDate: '2020-07-28T00:00:00',
      payed: 7207,
      remains: 0
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 8859295,
      invoiceDate: '2020-06-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '885929596',
      amount: 703,
      dueDate: '2020-06-28T00:00:00',
      payed: 703,
      remains: 0
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 8808873,
      invoiceDate: '2020-05-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '880887393',
      amount: 1572,
      dueDate: '2020-05-28T00:00:00',
      payed: 1572,
      remains: 0
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 8758771,
      invoiceDate: '2020-04-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '875877193',
      amount: 1618,
      dueDate: '2020-04-28T00:00:00',
      payed: 1618,
      remains: 0
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 8707866,
      invoiceDate: '2020-03-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '870786696',
      amount: 144,
      dueDate: '2020-03-28T00:00:00',
      payed: 144,
      remains: 0
    },
    mockTransactions
  ),
  mapper.getInvoiceModel(
    {
      invoiceNo: 8606878,
      invoiceDate: '2020-01-15T00:00:00',
      deliveryType: 'Mailutskick',
      ocr: '860687896',
      amount: 1569,
      dueDate: '2020-01-28T00:00:00',
      payed: 1569,
      remains: 0
    },
    mockTransactions
  )
]
