/* eslint-disable no-nested-ternary */
import classNames from 'classnames'
import React, { CSSProperties, InputHTMLAttributes } from 'react'
import InlineAlert from './InlineAlert'
import Label from './Label'

export type InputProps = {
  errorClassName?: string
  label?: string
  value?: string
  error?: string
  hasAlertBackground?: boolean
  labelStyle?: CSSProperties
  fullWidth?: boolean
} & InputHTMLAttributes<HTMLInputElement>

// eslint-disable-next-line react/display-name
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      error,
      errorClassName,
      fullWidth,
      label,
      style,
      labelStyle,
      hasAlertBackground,
      ...restProps
    }: InputProps,
    ref
  ) => {
    let inputBox = (
      <input
        className="Input__InputBox"
        // eslint-disable-next-line jsx-a11y/no-autofocus
        ref={ref}
        {...restProps}
      />
    )

    if (restProps.type === 'textarea') {
      inputBox = (
        // @ts-ignore
        <textarea
          className="Input__InputBox"
          cols={30}
          id=""
          name=""
          rows={6}
          style={{ resize: 'none' }}
          {...restProps}
        />
      )
    }

    return (
      <div
        className={classNames('Input', className, {
          'Input--FullWidth': fullWidth
        })}
        style={style}
      >
        {label ? (
          <Label style={labelStyle} text={label}>
            {inputBox}
          </Label>
        ) : (
          inputBox
        )}
        {error && (
          <InlineAlert
            className={classNames('Input__Error', errorClassName)}
            content={error}
            hasBackground={hasAlertBackground}
            type="warning"
          />
        )}
      </div>
    )
  }
)

export default Input
