import classNames from 'classnames'
import { ReactNode } from 'react'

export const InformationRow: React.FC<{
  text: string | ReactNode
  isLastRow?: boolean
  isOdd?: boolean
  className?: string
  colSpan?: number
}> = ({ text, isLastRow, isOdd, className = '', colSpan = 3 }) => (
  <tr
    className={classNames('Invoice__Row Invoice__Row--Transaction', className, {
      'Invoice__Row--IsOdd': isOdd,
      'Invoice__Row--TransactionLast': isLastRow
    })}
  >
    <td className="Invoice__Cell Invoice__Cell--Date" colSpan={colSpan}>
      <div className="Invoice__Information">{text}</div>
    </td>
  </tr>
)
