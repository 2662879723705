import classNames from 'classnames'
import React, { CSSProperties } from 'react'

type Props = {
  children?: React.ReactNode
  className?: string
  text: string
  title?: string
  vertical?: boolean
  style?: CSSProperties
}

const Label: React.FunctionComponent<Props> = ({
  children,
  className,
  text,
  title,
  vertical,
  style
}) => {
  return (
    <label
      className={classNames('Label', vertical && 'Label--Vertical', className)}
      style={style}
      title={title}
    >
      <div className="Label__Text" dangerouslySetInnerHTML={{ __html: text }} />
      {children}
    </label>
  )
}

export default Label
