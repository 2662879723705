import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Banners from '../elements/Banners'
import Footer from '../elements/Footer'
import Pages from './Pages'

type LoginFlowProps = {}

const LoginFlow: React.FC<LoginFlowProps> = () => {
  const BaseLoginComponent =
    process.env.NODE_ENV === 'development' ? (
      <Pages.LoginPage />
    ) : (
      <Pages.AuthorizePage />
    )

  return (
    <>
      <Banners />
      <Routes>
        <Route element={BaseLoginComponent} path="/" />
        <Route element={<Pages.LoginPage />} path="/loggain" />
        <Route element={<Pages.CallbackPage />} path="/callback" />
        <Route element={<Pages.AuthorizePage />} path="/login-bankid" />
        <Route
          element={<Pages.ResetPasswordPage />}
          path="/aterstall-losenord"
        />
        <Route element={<Pages.ForgotPasswordPage />} path="/glomt-losenord" />
        <Route element={<Pages.CreatePasswordPage />} path="/skapa-losenord" />
        <Route element={<Navigate to="/" />} path="*" />
      </Routes>
      <Footer />
    </>
  )
}

export default LoginFlow
