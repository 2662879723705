/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-multi-comp */
import { AnimatePresence } from 'framer-motion'
import React from 'react'
import { useToasts } from '../../hooks/useToasts'
import Toast from './Toast'

type ToastsProps = {
  headerHeight: number
}

export const Toasts: React.FC<ToastsProps> = ({ headerHeight }) => {
  const { toasts, removeToast } = useToasts()

  return (
    <div className="Toasts" style={{ top: headerHeight }}>
      <AnimatePresence>
        {toasts &&
          Object.values(toasts).map((toast) => (
            <Toast
              closeToast={() => {
                removeToast(toast.id)
              }}
              key={toast.id}
              {...toast}
            />
          ))}
      </AnimatePresence>
    </div>
  )
}

export default Toasts
