import * as React from 'react'
import { AccountInformationModel } from '../../../domain/models/AccountInformationModel'
import InlineAlert from '../../elements/InlineAlert'
import DeleteAccountButtons from './DeleteAccountButtons'
import { CheckboxAlternative, HasRemainingBalance } from './HasRemainingBalance'
import { BankInfo } from './ReclaimBalance'

interface DeleteAccountContainerProps {
  phoneNo: string
  hasBalance: boolean
  accountInformation: AccountInformationModel | null
  onConfirmDelete: (bankInfo?: BankInfo) => void
  selectedValue: (checkedValue: CheckboxAlternative) => void
}

export const DeleteAccountContainer: React.FC<DeleteAccountContainerProps> = (
  props
) => {
  const { accountInformation, hasBalance, onConfirmDelete, selectedValue } =
    props

  const [checkedValue, setCheckedValue] =
    React.useState<CheckboxAlternative | null>(null)
  const [bankInfo, setBankInfo] = React.useState<BankInfo>({
    accountNo: '',
    clearingNo: '',
    customError: '',
    hasAccountNoError: true,
    hasClearingError: true
  })

  const canDelete = (): boolean => {
    if (!hasBalance) {
      return true
    }
    if (
      checkedValue === 'reclaim' &&
      (bankInfo.hasClearingError || bankInfo.hasAccountNoError)
    ) {
      return false
    }
    return Boolean(checkedValue) && checkedValue !== 'none'
  }

  return (
    <>
      <h2>{'Ta bort konto'}</h2>
      {accountInformation && hasBalance ? (
        <HasRemainingBalance
          {...props}
          bankInfo={bankInfo}
          inputFieldValue={(info) => setBankInfo(info)}
          selectedValue={(val) => {
            setCheckedValue(val)
            selectedValue(val)
          }}
        />
      ) : (
        <>
          <p>
            {
              'Ditt konto kommer att raderas efter att kundservice har behandlat ärendet.'
            }
          </p>
          {hasBalance && (
            <InlineAlert
              className="Profile__Alert"
              content={<b>{'OBS: Detta går inte att ångra senare.'}</b>}
              icon={{
                name: 'exclamation-triangle',
                primaryColor: 'black',
                secondaryColor: 'yellow500'
              }}
              type="default"
            />
          )}
        </>
      )}
      <DeleteAccountButtons
        canDelete={canDelete()}
        onConfirmDelete={() => {
          if (canDelete()) {
            if (checkedValue === 'reclaim') {
              onConfirmDelete(bankInfo)
            } else {
              onConfirmDelete()
            }
          }
        }}
      />
    </>
  )
}
