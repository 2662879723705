/* eslint-disable react/no-multi-comp */
import React from 'react'
import {
  useDevCustomerValues,
  useGetCustomer
} from '../../hooks/queryHooks/useGetCustomer'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import { Customer } from '../../integrations/allkort/v1/schemas/Customer'
import { KeysMatching } from '../../utils/KeysMatching'
import { ShowIf } from '../elements/ShowIf'
import Toggle from '../elements/Toggle'

const CustomerDevToggle = ({
  label,
  valueKey
}: {
  label: string
  valueKey: KeysMatching<Customer, boolean>
}) => {
  const { data } = useGetCustomer({
    queryOptions: { suspense: false }
  })
  const { toggleBooleanValue, values } = useDevCustomerValues()

  return (
    <Toggle
      checked={values?.[valueKey] ?? data?.[valueKey] ?? false}
      label={label}
      name={`${valueKey}-toggle`}
      onChange={() => toggleBooleanValue(valueKey)}
    />
  )
}

type CustomerDevToolsProps = {}

const CustomerDevTools: React.FC<CustomerDevToolsProps> = (props) => {
  const { isLoading, data: customer } = useGetCustomer({
    queryOptions: { suspense: false }
  })
  const { isBankIdUser, toggleBankIdUser } = useIsBankIdUser(true)

  return (
    <div style={{ maxWidth: '40rem' }}>
      <div style={{ margin: '1rem 0 2rem 0' }}>
        <h5>{'Kund'}</h5>
        <ShowIf isTrue={!isLoading}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '.75rem'
            }}
          >
            <CustomerDevToggle
              label={
                customer!.isFrozen
                  ? 'Kontot är spärrat'
                  : 'Kontot är inte spärrat'
              }
              valueKey="isFrozen"
            />
            <CustomerDevToggle
              label={
                customer!.isCompany
                  ? 'Kontot är ett företag'
                  : 'Kontot är ett privatkonto'
              }
              valueKey="isCompany"
            />
            <CustomerDevToggle
              label={
                customer!.hasVehicleSpecification
                  ? 'Kontot har fordonsspecifikation'
                  : 'Kontot har inte fordonsspecifikation'
              }
              valueKey="hasVehicleSpecification"
            />
            <Toggle
              checked={isBankIdUser}
              label={
                isBankIdUser
                  ? 'Användaren är inloggad med BankID'
                  : 'Användaren är inte inloggad med BankID'
              }
              name="bankid-toggle"
              onChange={toggleBankIdUser}
            />
          </div>
        </ShowIf>
      </div>
    </div>
  )
}

export default CustomerDevTools
