import React, { useState } from 'react'
import { CardModel } from '../../domain/models/CardModel'
import { usePostCardReplacement } from '../../hooks/queryHooks/usePostCardReplacement'
import { usePutCardDisable } from '../../hooks/queryHooks/usePutCardDisable'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import Button from '../elements/Button'
import Checkbox from '../elements/Checkbox'
import Icon from '../elements/Icon'

type Props = {
  card: CardModel
  onCancel: () => void
}

const CardDisable: React.FC<Props> = ({ card, onCancel }) => {
  const isBankIdUser = useIsBankIdUser()
  const disableCardMutation = usePutCardDisable({
    extendedOnSuccessFunction: onCancel
  })

  const replacementCardMutation = usePostCardReplacement({
    extendedOnSuccessFunction: onCancel
  })

  const [confirmed, setConfirmed] = useState(false)
  const [sendNewCard, setSendNewCard] = useState(false)

  const handleDisabledCard = () => {
    const { cardNo, cardReference1, cardReference2 } = card

    if (sendNewCard) {
      replacementCardMutation.mutate({
        cardNo: parseInt(cardNo),
        cardReference1,
        cardReference2,
        disableCurrentCard: true,
        pin: null,
        useSamePin: true,
        creditCheckApproved: false
      })
    } else {
      disableCardMutation.mutate({
        cardNo: card.cardNo,
        cardName: card.cardReference1
      })
    }
  }

  const setField = (
    name: 'confirmed' | 'sendNewCard',
    value: boolean
  ): void => {
    if (name === 'confirmed') {
      setConfirmed(value)
    } else if (name === 'sendNewCard') {
      setSendNewCard(value)
    }
  }

  return (
    <>
      <div className="Card Card--Disable">
        <h6 className="Card__Heading">
          <Icon name="ban" primaryColor="blue400" secondaryColor="green500" />
          {'Spärra kort'}
        </h6>
        <h6 className="Card__DisableHeading">{'Vill du spärra ditt kort?'}</h6>
        <ul className="Card__CardInfo">
          <li className="Card__CardInfoItem">
            <b>{card.cardReference1}</b>
          </li>
          <li className="Card__CardInfoItem">{card.cardReference2}</li>
        </ul>
        <div className="Card__Description">
          <p className="Card__DisableBody">
            {
              'Spärrningen kommer att slå igenom direkt, och kortet kommer inte längre gå att '
            }
            {'använda. '}
          </p>
          <p className="Card__DisableWarning">
            <Icon
              className="Card__Icon"
              name="exclamation-triangle"
              primaryColor="yellow500"
              secondaryColor="red400"
            />
            <b>{'Detta går inte att ångra senare.'}</b>
          </p>
        </div>
        <div className="Card__DisableCheckboxes">
          <Checkbox
            checked={confirmed}
            onChange={(value) => setField('confirmed', value)}
            text="Ja, spärra mitt kort"
          />
          {isBankIdUser && (
            <Checkbox
              checked={sendNewCard}
              onChange={(value) => setField('sendNewCard', value)}
              text="Skicka ett nytt kort med samma kortreferens och kod"
            />
          )}
        </div>
        <div className="Card__ActionButtons">
          <Button
            className="Button Card__Button Card__Button--No mb-2"
            disabled={!confirmed}
            isLoading={
              disableCardMutation.isLoading || replacementCardMutation.isLoading
            }
            loadingText="Spärrar kortet..."
            onClick={handleDisabledCard}
            type="button"
          >
            {'Spärra kort'}
          </Button>
          <button
            className="Button Card__Button Card__Button--Yes"
            onClick={() => onCancel()}
            type="button"
          >
            {'Avbryt'}
          </button>
        </div>
        {(disableCardMutation.isError || replacementCardMutation.isError) && (
          <div>{'Något gick fel. Vänligen försök igen'}</div>
        )}
      </div>
    </>
  )
}

export default CardDisable
