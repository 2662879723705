import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useBanners } from '../../hooks/useBanners'
import { AuthService } from '../../integrations/backend/v1/services/AuthService'
import Button from '../elements/Button'
import Input from '../elements/Input'
import Link from '../elements/Link'
import ProfileBox from '../views/Profile/ProfileBox'

type FormErrors = {
  customerNo?: string
  email?: string
}

type Props = {}

type ForgotPasswordForm = {
  customerNo: string
  email: string
}

type LocationState = { forced: boolean }

const ForgotPasswordPage: React.FC<Props> = () => {
  const location = useLocation()
  const { addBanner } = useBanners()

  const [form, setForm] = useState<ForgotPasswordForm>({
    customerNo: '',
    email: ''
  })
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null)

  useEffect(() => {
    const costumerNoUrl = new URLSearchParams(location.search).get('customerNo')

    if (costumerNoUrl !== null) {
      setForm({
        customerNo: costumerNoUrl,
        email: ''
      })
    }
  }, [])

  const handleInputChange = (event: React.ChangeEvent): void => {
    const element = event.target as HTMLInputElement
    const name = element.name as keyof ForgotPasswordForm

    setForm((oldForm) => ({
      ...oldForm,
      [name]: element.value.trim()
    }))
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()

    const newFormErrors = getFormValidationErrors()

    if (newFormErrors) {
      setFormErrors(newFormErrors)
    } else {
      try {
        const customerNo = parseInt(form.customerNo)

        const response = await AuthService.generateResetPasswordToken(
          customerNo,
          form.email
        )

        if (!response.ok) {
          throw new Error(
            'Lyckades inte återställa lösenord. Försök igen senare eller kontakta kundservice.'
          )
        }

        addBanner({
          content: (
            <span>
              {
                'En återställningslänk har skickats till den angivna mailadressen.'
              }
            </span>
          ),
          id: 'recover-password',
          type: 'info'
        })
      } catch (ex) {
        addBanner({
          content:
            'Lyckades inte återställa lösenord. ' +
            'Försök igen senare eller kontakta kundservice.',
          id: 'recover-fail',
          type: 'warning'
        })
      }
    }
  }

  const getFormValidationErrors = () => {
    const newFormErrors: FormErrors = {}

    if (!form.customerNo.trim()) {
      newFormErrors.customerNo = 'Du måste fylla i ett kundnummer.'
    } else if (Number.isNaN(parseInt(form.customerNo))) {
      newFormErrors.customerNo = 'Kundnumret får bara bestå av siffror.'
    }

    if (!form.email) {
      newFormErrors.email = 'Du måste fylla i en mailadress.'
    }

    return Object.values(newFormErrors).length === 0 ? null : newFormErrors
  }

  const wasForced = () => (location.state as LocationState)?.forced ?? false

  return (
    <div className="Section ForgotPasswordPage BgColor--Red400">
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          <ProfileBox style={{ paddingTop: '3rem' }}>
            <form
              className="Column Column--Md6 Column--Lg6 Column--AlignStart"
              onSubmit={handleSubmit}
            >
              <h1
                style={
                  wasForced() ? { marginBottom: 32, maxWidth: 500 } : undefined
                }
              >
                {wasForced() ? 'Innan vi loggar in...' : 'Glömt lösenord'}
              </h1>
              <p>
                {wasForced()
                  ? 'Av säkerhetsskäl behöver vi först återställa ditt lösenord. '
                  : ''}
                {
                  'Fyll i fälten nedan, så skickar vi en länk till din registrerade mailadress där du kan '
                }
                {wasForced() ? 'göra detta' : 'återställa ditt lösenord'}
                {'. Ditt kundnummer kan du hitta på din senaste faktura.'}
              </p>
              <p>
                {
                  'Om du saknar en mailadress kan du logga in med BankID eller kontakta kundservice.'
                }
              </p>
              <br />
              <Input
                autoComplete="username"
                className="ForgotPasswordPage__Input"
                error={formErrors?.customerNo}
                label="Kundnummer:"
                name="customerNo"
                onChange={handleInputChange}
                type="text"
                value={form.customerNo}
              />
              <Input
                autoComplete="email"
                className="ForgotPasswordPage__Input"
                error={formErrors?.email}
                label="Mailadress:"
                name="email"
                onChange={handleInputChange}
                type="email"
                value={form.email}
              />
              <div>
                <Button type="submit">{'Återställ lösenord'}</Button>
                <Link className="Login__ForgotPassword" href="/">
                  {'Avbryt'}
                </Link>
              </div>
            </form>
          </ProfileBox>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
