export type LocalStorage = {
  'qstar.mypages.cardView': string
}

export const get = (key: keyof LocalStorage): string | null => {
  return window.localStorage.getItem(key)
}

export const set = (key: keyof LocalStorage, value: string): void => {
  window.localStorage.setItem(key, value)
}
