import React from 'react'
import { MenuItem } from '../../legacy/mock/components/menuItems'
import { HamburgerMenu } from '../elements/HamburgerMenu'

type Props = {
  onChangeNameClick: () => void
  onChangePinClick: () => void
  onDisableCardClick: () => void
  onChangeReplacementCardClick: () => void
}

export const CardMenu: React.FC<Props> = ({
  onChangeNameClick,
  onChangePinClick,
  onDisableCardClick,
  onChangeReplacementCardClick
}) => {
  const getMenuItems = (): MenuItem[] => {
    const items: MenuItem[] = [
      {
        icon: {
          fixedWidth: true,
          name: 'ban',
          primaryColor: 'blue400',
          secondaryColor: 'green500'
        },
        onClick: onDisableCardClick,
        requiresBankId: false,
        title: 'Spärra kort'
      }
    ]

    items.push(
      {
        icon: {
          fixedWidth: true,
          name: 'plus-circle',
          primaryColor: 'green500',
          secondaryColor: 'blue400'
        },
        onClick: onChangeReplacementCardClick,
        requiresBankId: true,
        title: 'Ersättningskort'
      },
      {
        icon: {
          fixedWidth: true,
          name: 'lock',
          primaryColor: 'blue400',
          secondaryColor: 'green500'
        },
        onClick: onChangePinClick,
        requiresBankId: true,
        title: 'Byt pinkod'
      },
      {
        icon: {
          fixedWidth: true,
          name: 'edit',
          primaryColor: 'green500',
          secondaryColor: 'blue400'
        },
        onClick: onChangeNameClick,
        requiresBankId: true,
        title: 'Redigera kort'
      }
    )

    return items
  }

  return <HamburgerMenu align="right" items={getMenuItems()} />
}
