import sv from 'date-fns/locale/sv'
import * as React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useGetBankCards } from '../../hooks/queryHooks/useGetBankCards'
import { useGetInvoices } from '../../hooks/queryHooks/useGetInvoices'
import { getSixMonthsAgoDate } from '../../utils/getSixMonthsAgoDate'
import { AllBankCardsHistory } from './AllBankCardHistory'
import {
  BankCardSelectable,
  SpecificBankCardHistory
} from './SpecificBankCardHistory'
registerLocale('sv', sv)

type HistoryTabsProps = {}

var todaysDate = new Date()
var sixMonthsAgo = getSixMonthsAgoDate()

type BankCardTabs = 'all' | 'singleCard'

export const BankCardTransactions: React.FC<HistoryTabsProps> = () => {
  const { data: cards } = useGetBankCards()

  const invoicesQuery = useGetInvoices()

  React.useEffect(() => {
    /**
     * Fetch two pages initially to make sure we can keep track
     * of the previous invoice date when showing related transactions
     */
    if (
      invoicesQuery.hasNextPage &&
      invoicesQuery.data!.pageParams.length === 1
    ) {
      invoicesQuery.fetchNextPage()
    }
  }, [invoicesQuery.isLoading])

  const [selectedCard, setSelectedCard] =
    React.useState<BankCardSelectable | null>(null)

  const [SelectedStartDate, setSelectedStartDate] = React.useState(sixMonthsAgo)
  const [SelectedEndDate, setSelectedEndDate] = React.useState(todaysDate)

  const [selectedKey, setSelectedKey] = React.useState<BankCardTabs>('all')

  return (
    <div className="Column Column--Lg12 p-0 History_Tab">
      <h5 className="mt-4">{'Transaktioner'}</h5>
      <Tabs
        activeKey={selectedKey}
        className="mb-2"
        id="historyTabsId"
        onSelect={(key) => {
          if (key) {
            setSelectedKey(key as BankCardTabs)
          }
        }}
      >
        <Tab eventKey="all" title={<h6 className="m-0">{'Alla'}</h6>}>
          <p className="HistoryTab__SupportText">
            {'Visar alla transaktioner'}
          </p>
          <AllBankCardsHistory
            setSelectedStartDate={setSelectedStartDate}
            setSelectedEndDate={setSelectedEndDate}
            startDate={SelectedStartDate}
            endDate={SelectedEndDate}
          />
        </Tab>
        {cards && cards.length > 0 && (
          <Tab
            eventKey="singleCard"
            title={<h6 className="m-0">{'Per kort'}</h6>}
          >
            <p className="HistoryTab__SupportText">
              {'Visar transaktioner för valt bankkort'}
            </p>
            <div className="SmartCardView__Container">
              <SpecificBankCardHistory
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
                startDate={SelectedStartDate}
                endDate={SelectedEndDate}
              />
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  )
}
