import classNames from 'classnames'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CardModel } from '../../domain/models/CardModel'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import useGetTransactionsLastSixMonths from '../../hooks/queryHooks/useGetTransactionsLastSixMonths'
import { usePostCardReplacement } from '../../hooks/queryHooks/usePostCardReplacement'
import { CardReplacement } from '../../integrations/allkort/v1/schemas/Card'
import { castUtcDate } from '../../utils/castUtcDate'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import Input from '../elements/Input'
import Wrapper from '../elements/Wrapper'
import { pinInputError, pinInputVerifyError } from './CardNewPin'

type ConditionallyHideDisableCheckboxProp =
  | {
      hideDisableCardCheckbox?: boolean
      disableCardAsDefault: boolean
    }
  | {
      hideDisableCardCheckbox?: never
      disableCardAsDefault?: never
    }

type Props = {
  card: CardModel
  onCancel: () => void
  isModalSection?: boolean
  postponeReplacementCard?: boolean
} & ConditionallyHideDisableCheckboxProp

type ReplacementCardForm = {
  cardReference2: string
  disableCurrentCard: boolean
  useSamePin: boolean
  pin?: string
  verifyPin?: string
  postponeReplacementCard?: 'true' | 'false'
  creditCheckApproved?: boolean
}

const CardReplacementForm: React.FC<Props> = ({
  card,
  onCancel,
  isModalSection,
  disableCardAsDefault,
  hideDisableCardCheckbox,
  postponeReplacementCard = false
}) => {
  const transactionsQuery = useGetTransactionsLastSixMonths()
  const { data: customer } = useGetCustomer()

  const {
    mutate: postCardReplacement,
    isLoading,
    isError
  } = usePostCardReplacement({
    extendedOnSuccessFunction: onCancel
  })

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors, isValid: isFormValid },
    getValues,
    watch
  } = useForm<ReplacementCardForm>({
    defaultValues: {
      cardReference2: card.cardReference2,
      useSamePin: true,
      disableCurrentCard: disableCardAsDefault ?? false,
      postponeReplacementCard: 'false',
      creditCheckApproved: false
    },
    mode: 'all',
    delayError: 200
  })

  const [acceptCreditInfo, setAcceptCreditInfo] = React.useState(false)

  const transactionsWithinSixMonths = transactionsQuery.data?.pages
    .at(0)
    ?.payload.at(0)

  let shouldAskForCreditCheck = false

  if (
    !transactionsWithinSixMonths &&
    !card.isSmartCard &&
    !customer!.isCompany
  ) {
    shouldAskForCreditCheck = true
  }

  const pinValue = watch('pin')
  const pinVerifyValue = watch('verifyPin')
  const disableCurrentCardValue = watch('disableCurrentCard')

  const handlePinValidation = (pin: string | undefined) => {
    if (!pin) return false
    return !pinInputError(pin)
  }

  const handlePinVerifyValidation = (verifyPin: string | undefined) => {
    if (!verifyPin) return false
    return !pinInputVerifyError(verifyPin, pinValue!)
  }

  const watchUseSamePin = watch('useSamePin')
  useEffect(() => {
    if (!watchUseSamePin) {
      unregister('pin')
      unregister('verifyPin')
    }
  }, [register, unregister, watchUseSamePin])

  const onSubmit: SubmitHandler<ReplacementCardForm> = (data, event) => {
    event?.preventDefault()
    const submitObject: CardReplacement = {
      cardNo: parseInt(card.cardNo),
      cardReference1: card.cardReference1,
      cardReference2: getValues('cardReference2').trim(),
      disableCurrentCard: getValues('disableCurrentCard'),
      useSamePin: getValues('useSamePin'),
      pin: getValues('useSamePin') ? null : getValues('pin')?.trim()!,
      creditCheckApproved: acceptCreditInfo
    }

    if (getValues('postponeReplacementCard') === 'true') {
      submitObject.disableCurrentCard = true

      const dateInTwoWeeks = new Date()
      dateInTwoWeeks.setDate(dateInTwoWeeks.getDate() + 14)
      submitObject.replacementDueDate =
        castUtcDate(dateInTwoWeeks).toISOString()
    }

    postCardReplacement(submitObject)
  }

  return (
    <>
      <form
        className={classNames('CardReplacementForm', {
          'CardReplacementForm--Modal': isModalSection
        })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Wrapper
          if={isModalSection}
          wrap={(child) => <div className="Modal__Section">{child}</div>}
        >
          <Input
            label={`Kortreferens (syns på ${
              card.isSmartCard ? 'kontobesked' : 'fakturan'
            })`}
            {...register('cardReference2')}
          />
          {!hideDisableCardCheckbox && (
            <>
              <div className="Input mb-3">
                <input
                  id="disableCurrentCard"
                  type="checkbox"
                  {...register('disableCurrentCard')}
                />
                <label htmlFor="disableCurrentCard">
                  {'Spärra nuvarande kort'}
                </label>
              </div>
              {disableCurrentCardValue && (
                <div className="Card__DisableWarning">
                  <p className="Card__DisableBody">
                    {
                      'Spärrningen kommer att slå igenom direkt och kortet kommer inte längre gå att använda. '
                    }
                  </p>
                  <p className="Card__DisableWarningAlert">
                    <Icon
                      className="Card__Icon"
                      name="exclamation-triangle"
                      primaryColor="yellow500"
                      secondaryColor="red400"
                    />
                    <b>{'Detta går inte att ångra senare.'}</b>
                  </p>
                </div>
              )}
            </>
          )}
          {postponeReplacementCard && (
            <div>
              <p className="mb-1">{'Det nya kortet skickas:'}</p>
              <div className="Input mb-1">
                <input
                  id="postponeReplacementCard-1"
                  type="radio"
                  value="false"
                  {...register('postponeReplacementCard')}
                />
                <label htmlFor="postponeReplacementCard-1">{'Omgående'}</label>
              </div>
              <div className="Input mb-3">
                <input
                  id="postponeReplacementCard-2"
                  type="radio"
                  value="true"
                  {...register('postponeReplacementCard')}
                />
                <label htmlFor="postponeReplacementCard-2">
                  {'Om 14 dagar'}
                </label>
              </div>
            </div>
          )}
          <div className="Input">
            <input
              id="useSamePin"
              type="checkbox"
              {...register('useSamePin')}
            />
            <label htmlFor="useSamePin">{'Behåll pinkod'}</label>
          </div>
          {!watchUseSamePin && (
            <div className="Card__NewPinContainer">
              <Input
                error={errors.pin ? pinInputError(pinValue!) : ''}
                label="Nytt pin *"
                {...register('pin', {
                  required: true,
                  validate: handlePinValidation
                })}
              />
              <Input
                error={
                  errors.verifyPin
                    ? pinInputVerifyError(pinVerifyValue!, pinValue!)
                    : ''
                }
                label="Bekräfta pin *"
                {...register('verifyPin', {
                  required: true,
                  validate: handlePinVerifyValidation
                })}
              />
            </div>
          )}
        </Wrapper>
        {shouldAskForCreditCheck && (
          <div>
            <label
              className="NewCardsPage__CheckBox"
              htmlFor="acceptCreditInfo"
            >
              <input
                checked={acceptCreditInfo}
                id="acceptCreditInfo"
                onChange={() => setAcceptCreditInfo(!acceptCreditInfo)}
                // labelClassName="NewCardsPage__Label"
                title="Kreditupplysning"
                type="checkbox"
              />
              {'Jag godkänner ny kreditupplysning'}
            </label>
            <p className="NewCardsPage__Disclaimer">
              {'En ny kreditupplysning måste'} <br />
              {' tas efter inaktivitet på 6 mån.'}
            </p>
          </div>
        )}
        {isModalSection ? (
          <div className="ProfileSettingsModal__Actions flex-column">
            <div className="d-flex justify-content-end">
              <Button
                color="red200"
                disabled={
                  !isFormValid || (shouldAskForCreditCheck && !acceptCreditInfo)
                }
                isLoading={isLoading}
                loadingText="Beställer kort"
                style={{ marginBottom: 0 }}
                type="submit"
              >
                {'Beställ kort'}
              </Button>
              <Button
                onClick={onCancel}
                style={{ marginBottom: 0 }}
                type="button"
              >
                {'Avbryt'}
              </Button>
            </div>
          </div>
        ) : (
          <div className="Card__ActionButtons">
            <Button
              className="mb-2"
              disabled={
                !isFormValid || (shouldAskForCreditCheck && !acceptCreditInfo)
              }
              isLoading={isLoading}
              loadingText="Beställer kort"
              type="submit"
            >
              {'Beställ kort'}
            </Button>
            <Button color="red200" onClick={onCancel} type="button">
              {'Avbryt'}
            </Button>
          </div>
        )}
      </form>
      {isError && (
        <div className="Card__SaveError">
          {
            'Det gick inte att beställa ett ersättningskort. Vänligen försök igen'
          }
        </div>
      )}
    </>
  )
}

export default CardReplacementForm
