export class PagedResult<T> {
  payload: T[]
  nextPage: number
  hasMore: boolean
  maxPages: number

  constructor(
    payload: T[],
    nextPage: number,
    hasMore: boolean,
    maxPages: number = 1
  ) {
    this.payload = payload
    this.nextPage = nextPage
    this.hasMore = hasMore
    this.maxPages = maxPages
  }
}
