/* eslint-disable no-confusing-arrow */
import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { Fragment, useState } from 'react'
import { useDeleteUserProfile } from '../../hooks/queryHooks/useDeleteUserProfile'
import { useGetCurrentUser } from '../../hooks/queryHooks/useGetCurrentUser'
import { useUserRights } from '../../hooks/useUserRights'
import { AuthenticationRightsList } from '../../integrations/allkort/v1/proxies/UserProfileProxy'
import { UserLogin } from '../../integrations/allkort/v1/schemas/UserLogin'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import Icon from '../elements/Icon'
import { ConfirmationModalData } from './ConfirmationModal'
import UserEditButtons from './UserEditButtons'

export const UserProfileTableBody: React.FC<{
  userProfiles: UserProfile[]
  authenticationRights: AuthenticationRightsList
  setSelectedUserId: (profileIndex: number) => void
  setConfirmationModal: (modalState: ConfirmationModalData | null) => void
}> = ({
  userProfiles,
  authenticationRights,
  setSelectedUserId,
  setConfirmationModal
}) => {
  const deleteUserMutation = useDeleteUserProfile()
  const { userHasRight } = useUserRights()
  const { data: currentUser } = useGetCurrentUser()
  const allWriteRights = authenticationRights.filter((right) =>
    right.claimName.includes('Write')
  )
  const [expandedUserId, setExpandedUserId] = useState<string | null>(null)

  const triggerConfirmDeleteModal = (userName: string, userId: string) => {
    setConfirmationModal({
      isOpen: true,
      title: `Är du säker på att du vill radera ${userName}?`,
      confirmText: `Radera ${userName}`,
      rejectText: 'Avbryt',
      rejectCallback: () => setConfirmationModal(null),
      confirmCallback: () => {
        deleteUserMutation.mutate({ userId, userName })
        setConfirmationModal(null)
      },
      destructive: true
    })
  }
  return (
    <tbody className="user-profile-table__body">
      {userProfiles.map((userProfile, profileIndex) => {
        const { userProfileId, firstName, lastName, email, phoneNo, logins } =
          userProfile

        const isRowExpanded = expandedUserId === userProfileId
        const isCurrentUser = userProfileId === currentUser!.userProfileId

        const userLogin = logins.find(
          (login) => login.loginType === 'BankID'
        ) as UserLogin

        if (!userLogin?.loginAuthenticationRoles.length) {
          return <Fragment key={userProfileId} />
        }

        const userRights = userLogin.loginAuthenticationRoles.map(
          (right) => right.authenticationRole
        )

        const personalNumber = userLogin?.userIdentifier ?? ''

        const isSuperUser = userRights.includes('Superuser')

        const superUserText = isSuperUser ? (
          <p style={{ margin: '0', fontWeight: 'bold' }}>{'Administratör'}</p>
        ) : (
          ''
        )

        const columns = userHasRight('UserProfilesWrite')
          ? [personalNumber, firstName, lastName, email, phoneNo, superUserText]
          : [firstName, lastName, email, phoneNo, superUserText]

        return (
          <Fragment key={userProfileId}>
            <motion.tr
              animate={{ opacity: 1 }}
              className={classNames('user-profile-table__body-row', {
                'user-profile-table__body-row--expanded': isRowExpanded
              })}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              onClick={() =>
                setExpandedUserId(isRowExpanded ? null : userProfileId)
              }
            >
              {columns.map((colValue, i) => (
                <th className="user-profile-table__body-cell" key={i}>
                  {colValue}
                </th>
              ))}
              {isSuperUser || isCurrentUser ? (
                <>
                  <th className="user-profile-table__body-cell--empty" />
                  <th className="user-profile-table__body-cell--empty" />
                </>
              ) : (
                <UserEditButtons
                  setSelectedUserId={() => setSelectedUserId(profileIndex)}
                  triggerConfirmDeleteModal={triggerConfirmDeleteModal}
                  userProfile={userProfile}
                />
              )}
            </motion.tr>
            {isRowExpanded && (
              <motion.tr
                animate={{ opacity: 1 }}
                className="user-profile-table__body-row--rights"
                exit={{ opacity: 0.4 }}
                initial={{ opacity: 0.4 }}
                key={'expanded' + profileIndex}
                transition={{ duration: 0.2, type: 'tween' }}
              >
                {/* +3 for the empty columns */}
                <td colSpan={columns.length + 3}>
                  <div>
                    <h6>{`${firstName} ${lastName} kan redigera:`}</h6>
                    <div className="user-profile-table__rights-list">
                      {allWriteRights
                        .sort((a, b) =>
                          a.groupingName > b.groupingName ? 1 : -1
                        )
                        .map((right) => {
                          const hasRight =
                            userRights.includes(right.claimName) || isSuperUser

                          const iconColor = hasRight ? 'green300' : 'red300'

                          return (
                            <p
                              className="user-profile-table__rights-list-item"
                              key={right.claimName}
                            >
                              {right.groupingName}
                              <Icon
                                name={hasRight ? 'check' : 'times'}
                                primaryColor={iconColor}
                                secondaryColor={iconColor}
                              />
                            </p>
                          )
                        })}
                    </div>
                  </div>
                </td>
              </motion.tr>
            )}
          </Fragment>
        )
      })}
    </tbody>
  )
}
