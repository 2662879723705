import React from 'react'
import Button from '../../elements/Button'
type AccountDeletedPageProps = {}
const AccountDeletedPage: React.FC<AccountDeletedPageProps> = () => {
  return (
    <div className="Section BgColor--Red400">
      <div className="Row">
        <div className="Row__Inner">
          <div className="Column Column--AlignStart Column--Lg7">
            <h3>{'Ärendet har skickats till kundservice'}</h3>
            <p style={{ maxWidth: '640px' }}>
              {
                'Ditt konto kommer att raderas efter att kundservice har behandlat ärendet. Kundservice kan komma att kontakta dig ifall du har kvarvarande saldo.'
              }
            </p>
            <div>
              <Button text="Stäng" url="/loggain" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountDeletedPage
