/* eslint-disable no-confusing-arrow */
import React from 'react'
import { BankCardTransactions } from '../views/BankCardTransactions'

type Props = {}

const BankCardPage: React.FC<Props> = () => {
  return (
    <>
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          <div className="Column Column--JustifyStart Column--Lg6">
            <div className="ContentBox">
              <div className="ContentBox__Content">
                <>
                  <h1>{'Bankkort'}</h1>
                  <p>
                    {`Här hittar du dina digitala kvitton och kan se dina transaktioner.
Denna sida kommer att utvecklas och uppdateras löpande så välkommen tillbaka och se vad som händer vidare under hösten.`}
                  </p>
                </>
              </div>
            </div>
          </div>
          <div className="Column Column--JustifyStart Column--Lg0 Column--Md0 Column--Sm12" />
        </div>
      </div>
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          <div className="Column Column--Lg12 justify-content-start BalanceAmountAndHistory__Column">
            <BankCardTransactions />
          </div>
        </div>
      </div>
    </>
  )
}

export default BankCardPage
