/* eslint-disable no-await-in-loop, require-atomic-updates */

import { PagedResult } from '../../../../domain/models/PagedResult'
import { BankCard } from '../schemas/Card'
import { ProxyBase } from './ProxyBase'

export class BankCardProxy extends ProxyBase<BankCard> {
  async getBankCards(): Promise<BankCard[]> {
    let bankCards: BankCard[] = []

    let nextPage = 1
    let pagedResult = new PagedResult<BankCard>([], nextPage, true)

    while (pagedResult.hasMore) {
      pagedResult = await this.getArr('/api/v2/Cards/BankCards', {
        PageIndex: nextPage
      })

      bankCards = [...bankCards, ...pagedResult.payload]

      //   Safety against infinite loop:
      if (pagedResult.nextPage === nextPage) {
        break
      }

      nextPage = pagedResult.nextPage
    }

    return bankCards
  }
}
