import { AccountInformationModel } from '../../../../domain/models/AccountInformationModel'
import { AccountInformationModelMapper } from '../mappers/AccountInformationModelMapper'
import { CustomerProxy } from '../proxies/CustomerProxy'
import { OcrProxy } from '../proxies/OcrProxy'

export class AccountInformationRepository {
  private apiToken: string

  constructor(apiToken: string) {
    this.apiToken = apiToken
  }

  async getAccountInformation(): Promise<AccountInformationModel> {
    const customerProxy = new CustomerProxy(this.apiToken)
    const ocrProxy = new OcrProxy(this.apiToken)

    const customerDTO = await customerProxy.getCustomer()
    const ocrDTO = await ocrProxy.getPrepaidCardOCR()

    const customerMapper = new AccountInformationModelMapper()
    const result = customerMapper.getAccountInformationModel(
      customerDTO,
      ocrDTO
    )

    return result
  }
}
