import { UseInfiniteQueryResult } from '@tanstack/react-query'
import classNames from 'classnames'
import React, { Fragment, useEffect, useRef } from 'react'
import { InvoicesModel } from '../../domain/models/InvoicesModel'
import { useScrollToPosition } from '../../hooks/useScrollToPosition'
import { getSevenYearsAgoDate } from '../../utils/getSevenYearsAgoDate'
import Icon from '../elements/Icon'
import Invoice from './Invoice'

type Props = {
  invoicesQuery: UseInfiniteQueryResult<InvoicesModel, unknown>
}

const Invoices: React.FC<Props> = ({ invoicesQuery }) => {
  const initialRender = useRef(true)
  const { ref: tableRef, scrollTo } = useScrollToPosition()
  const {
    hasNextPage,
    fetchNextPage,
    isFetching,
    data: invoices
  } = invoicesQuery

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      scrollTo('bottom')
    }
  }, [invoices!.pageParams])

  if (invoices!.pages[0].payload.length === 0) {
    return <p>{'Du har inga tidigare fakturor.'}</p>
  }

  const invoicesToShow =
    hasNextPage && invoices!.pages.length > 1
      ? invoices!.pages.slice(0, -1)
      : invoices!.pages

  return (
    <div className="Invoices">
      <table
        className={classNames('Invoices__Table', {
          'Invoices__Table--AllLoaded': !hasNextPage
        })}
        ref={tableRef}
      >
        <tbody>
          {invoicesToShow.map((page, i) => (
            <Fragment key={i}>
              {page.payload.map((invoice, index) => {
                const previousInvoices = invoices!.pages
                  .slice(i === 0 ? 0 : i - 1)
                  .map((inv) => inv.payload)
                  .flat()
                const currentInvoiceIndex = previousInvoices.indexOf(invoice)
                const previousInvoiceDate =
                  currentInvoiceIndex >= 0 &&
                  previousInvoices?.at(currentInvoiceIndex + 1)?.invoiceDate

                return (
                  <Invoice
                    invoice={invoice}
                    isOdd={index % 2 === 1}
                    key={invoice.invoiceID}
                    previousInvoiceDate={
                      previousInvoiceDate || getSevenYearsAgoDate()
                    }
                  />
                )
              })}
            </Fragment>
          ))}
        </tbody>
      </table>
      <div className="Invoices__ActionContainer">
        {hasNextPage && (
          <button
            className="Uninvoiced__LoadMoreButton"
            onClick={() => fetchNextPage()}
            type="button"
          >
            <Icon
              className="Invoice__ButtonIcon"
              fixedWidth
              name="plus"
              type="line"
            />
            {isFetching ? 'Laddar fler ' : 'Visa fler'}
            {isFetching && (
              <Icon
                className="Invoice__LoadingSpinner"
                fixedWidth
                name="spinner"
                spin
                type="line"
              />
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default Invoices
