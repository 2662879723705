import { MailingDeliveryType } from '../integrations/allkort/v1/schemas/MailingDeliveryType'

export const getPaymentMethodString = (
  paymentMethod: MailingDeliveryType,
  isAutogiroEnabled: boolean
): {
  long: string
  short: string
} => {
  if (isAutogiroEnabled) {
    const short = 'Autogiro'
    switch (paymentMethod) {
      case 'Email':
        return {
          long: 'autogiro med mailfaktura',
          short
        }
      case 'PaperMailing':
        return {
          long: 'autogiro med pappersfaktura',
          short
        }
      case 'EInvoice':
        return {
          long: 'autogiro med e-faktura',
          short
        }
      case 'EInvoiceCompany':
        return {
          long: 'e-faktura företag',
          short: 'E-faktura - Företag'
        }
    }
  } else {
    switch (paymentMethod) {
      case 'Email':
        return {
          long: 'mailfaktura',
          short: 'Mailfaktura'
        }
      case 'PaperMailing':
        return {
          long: 'pappersfaktura',
          short: 'Pappersfaktura'
        }
      case 'EInvoice':
        return {
          long: 'e-faktura',
          short: 'E-faktura'
        }
      case 'EInvoiceCompany':
        return {
          long: 'e-faktura företag',
          short: 'E-faktura - Företag'
        }
    }
  }
  return {
    long: '(Välj betalsätt)',
    short: '(Välj betalsätt)'
  }
}
