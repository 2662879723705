/* eslint-disable @tanstack/query/exhaustive-deps */
/* eslint-disable no-confusing-arrow */
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions
} from '@tanstack/react-query'
import { PagedResult } from '../../domain/models/PagedResult'
import { DepositHistoryTransactionQuery } from '../../integrations/allkort/v1/proxies/TransactionProxy'
import { DepositHistoryTransaction } from '../../integrations/allkort/v1/schemas/Transaction'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetSmartCardBalanceHistory

export type GetSmartCardBalaceHistoryParams = {
  queryOptions?: UseInfiniteQueryOptions<
    PagedResult<DepositHistoryTransaction>,
    unknown,
    PagedResult<DepositHistoryTransaction>
  >
  fetchQuery?: DepositHistoryTransactionQuery
  additionalQueryKeys?: unknown[]
}

export const useGetSmartCardBalanceHistory = (
  options: GetSmartCardBalaceHistoryParams = {
    queryOptions: {},
    fetchQuery: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, fetchQuery, additionalQueryKeys = [] } = options
  const { balanceHistoryRepo } = useRepos()
  const query = useInfiniteQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: ({ pageParam = 1 }) =>
      balanceHistoryRepo.getBalanceHistory({
        PageIndex: pageParam,
        ...fetchQuery
      }),
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.nextPage : undefined,
    keepPreviousData: true,
    ...queryOptions
  })

  return query
}
