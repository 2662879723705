import React, { useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import useRerenderOnQueryChange from '../../hooks/useRerenderOnQueryChange'

type ScrollToAnchorProps = {}

const ScrollToAnchor: React.FC<ScrollToAnchorProps> = (props) => {
  useRerenderOnQueryChange()
  const location = useLocation()
  const lastHash = useRef('')

  useLayoutEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1)
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        const scrollElement = document.getElementById(lastHash.current)
        const headerElement = document.querySelector<HTMLDivElement>('.Header')

        if (!scrollElement || !headerElement) return

        /**
         * Compensate for header height and leave a
         * 16 px gap above the scroll element
         */
        const yOffset = -headerElement.offsetHeight - 16
        const y =
          scrollElement?.getBoundingClientRect().top + window.scrollY + yOffset

        window.scrollTo({ top: y, behavior: 'smooth' })

        lastHash.current = ''
      }, 100)
    }
  }, [location])

  return null
}

export default ScrollToAnchor
