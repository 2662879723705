import React, { useEffect, useState } from 'react'
import { useBanners } from '../../hooks/useBanners'
import { AuthService } from '../../integrations/backend/v1/services/AuthService'
import { getUrlParam } from '../../utils/getUrlParam'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import InlineAlert from '../elements/InlineAlert'
import Input from '../elements/Input'

type Props = {}

type CreatePasswordForm = {
  confirmPassword: string
  newPassword: string
}

const CreatePasswordPage: React.FC<Props> = () => {
  const { addBanner } = useBanners()
  const [form, setForm] = useState<CreatePasswordForm>({
    confirmPassword: '',
    newPassword: ''
  })
  const [error, setError] = useState<string | null>(null)
  const [passwordRegex, setPasswordRegex] = useState<RegExp | null>(null)
  const [togglePassword, setTogglePassword] = useState(true)

  useEffect(() => {
    const fetchRegex = async () => {
      const response = await AuthService.getPasswordRegex()
      if (response.ok) {
        const data = await response.text()
        const newPasswordRegex = new RegExp(data, 'i')
        setPasswordRegex(newPasswordRegex)
      }
    }
    fetchRegex()
  }, [])

  const handleClick = () => {
    setTogglePassword((oldValue) => !oldValue)
  }

  const handleInputChange = (event: React.ChangeEvent) => {
    const element = event.target as HTMLInputElement
    const name = element.name as keyof CreatePasswordForm
    setForm((oldForm) => ({
      ...oldForm,
      [name]: element.value
    }))
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!form.newPassword) {
      setError('Du måste ange ett lösenord.')
    } else if (
      !passwordRegex?.test(form.newPassword) ||
      !passwordRegex?.test(form.confirmPassword)
    ) {
      setError(
        'Lösenordet måste innehålla bokstäver och siffror och vara minst 8 tecken långt.'
      )
    } else if (form.newPassword && form.newPassword === form.confirmPassword) {
      setError(null)

      const token = getUrlParam('token')

      if (token) {
        try {
          const response = await AuthService.resetPassword(
            token,
            form.newPassword
          )

          if (response.ok) {
            const customerNo = getUrlParam('customerNo')

            if (customerNo) {
              // Log in instantly if possible:
              try {
                await AuthService.loginCustomerNumber(
                  customerNo,
                  form.newPassword
                )
              } catch {}
            }

            window.location.href = '/'
          } else {
            addBanner({
              id: 'recover-error-2',
              content:
                'Lyckades inte återställa lösenord. Länken har slutat att gälla.',
              type: 'warning'
            })
          }
        } catch (ex) {
          addBanner({
            id: 'recover-error-3',
            content:
              'Lyckades inte återställa lösenord. Länken har slutat att gälla.',
            type: 'warning'
          })
        }
      } else {
        addBanner({
          id: 'recover-error-4',
          content: 'Lyckades inte återställa lösenord. Länken är ogiltig.',
          type: 'warning'
        })
      }
    } else {
      setError('Lösenorden matchar inte.')
    }
  }

  const email = getUrlParam('email')

  return (
    <div className="Section ResetPasswordPage BgColor--Red400">
      <div className="Row Row--JustifyLeft">
        <div className="Row__Inner">
          <form
            className="Column Column--JustifyStart Column--Md6 Column--Lg6"
            onSubmit={handleSubmit}
          >
            <h1 className="CreatePasswordPage__Heading">
              {'Skapa lösenord\r'}
            </h1>
            {/**
             * @see https://www.chromium.org/developers/design-documents/create-amazing-password-forms
             */}
            {email && (
              <input
                autoComplete="username"
                hidden
                name="username"
                readOnly
                value={email}
              />
            )}
            <div className="Input__Action">
              <Input
                autoComplete="new-password"
                className="CreatePasswordPage__Input"
                label="Nytt lösenord"
                name="newPassword"
                onChange={handleInputChange}
                required
                type={togglePassword ? 'password' : 'text'}
              />
              <Button className="EyeButton" onClick={handleClick} type="button">
                {togglePassword ? (
                  <Icon className="EyeIcon" name="eye" type="line" />
                ) : (
                  <Icon className="EyeIcon" name="eye-slash" type="line" />
                )}
              </Button>
            </div>
            <div className="Input__Action">
              <Input
                className="CreatePasswordPage__Input"
                label="Bekräfta lösenord"
                name="confirmPassword"
                onChange={handleInputChange}
                required
                type={togglePassword ? 'password' : 'text'}
              />
              <Button className="EyeButton" onClick={handleClick} type="button">
                {togglePassword ? (
                  <Icon className="EyeIcon" name="eye" type="line" />
                ) : (
                  <Icon className="EyeIcon" name="eye-slash" type="line" />
                )}
              </Button>
            </div>
            {error && (
              <InlineAlert
                content={error}
                icon={{
                  name: 'exclamation-triangle',
                  primaryColor: 'black',
                  secondaryColor: 'yellow500'
                }}
              />
            )}
            <Button className="CreatePasswordPage__Button" type="submit">
              {'Uppdatera lösenord\r'}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CreatePasswordPage
