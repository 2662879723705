import * as React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useGetCards } from '../../../hooks/queryHooks/useGetCards'
import { useGetStatements } from '../../../hooks/queryHooks/useGetStatements'
import { getDateNextDayAtMidnight } from '../../../utils/getDateNextDayAtMidnight'
import { AllCardsHistory } from './AllCardsHistory'
import { SmartCardSelectable, SpecificCardHistory } from './SpecificCardHistory'

type HistoryTabsProps = {}

type SmartCardTabs = 'new' | 'all' | 'singleCard'

export const HistoryTabs: React.FC<HistoryTabsProps> = () => {
  const { data: cards } = useGetCards()
  const { data: statements } = useGetStatements()
  const latestStatementDate = statements!.pages
    .at(0)
    ?.payload.at(0)?.statementDate

  const [selectedKey, setSelectedKey] = React.useState<SmartCardTabs>('new')
  const [selectedCard, setSelectedCard] =
    React.useState<SmartCardSelectable | null>(null)

  return (
    <div className="Column Column--Lg12 p-0 History_Tab">
      <h5 className="mt-4">{'Kontohändelser'}</h5>
      <Tabs
        activeKey={selectedKey}
        className="mb-2"
        id="historyTabsId"
        onSelect={(key) => {
          if (key) {
            setSelectedKey(key as SmartCardTabs)
          }
        }}
      >
        <Tab eventKey="new" title={<h6 className="m-0">{'Nya'}</h6>}>
          <p className="HistoryTab__SupportText">
            {'Visar transaktioner gjorda efter senaste kontobesked'}
          </p>
          {latestStatementDate ? (
            <AllCardsHistory
              startDate={getDateNextDayAtMidnight(latestStatementDate)}
            />
          ) : (
            <p>
              {'Du har inga nya transaktioner sedan ditt senaste kontobesked'}
            </p>
          )}
        </Tab>

        <Tab eventKey="all" title={<h6 className="m-0">{'Alla'}</h6>}>
          <p className="HistoryTab__SupportText">
            {'Visar alla transaktioner'}
          </p>
          <AllCardsHistory />
        </Tab>

        {cards && cards.smartCards.length > 0 && (
          <Tab
            eventKey="singleCard"
            title={<h6 className="m-0">{'Per kort'}</h6>}
          >
            <p className="HistoryTab__SupportText">
              {'Visar transaktioner för valt smartkort'}
            </p>
            <div className="SmartCardView__Container">
              <SpecificCardHistory
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
              />
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  )
}
