import React, { FormEventHandler, useState } from 'react'
import { CardModel, validateCardReference } from '../../domain/models/CardModel'
import { usePutCard } from '../../hooks/queryHooks/usePutCard'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import Label from '../elements/Label'

type Props = {
  card: CardModel
  onCancel: () => void
}

const CardChangeName: React.FC<Props> = ({ card, onCancel }) => {
  const {
    cardNo,
    cardReference1: currentReference1,
    cardReference2: currentReference2,
    isCompany: isCompanyCard
  } = card

  const updateCardMutation = usePutCard({
    extendedOnSuccessFunction: onCancel
  })

  const [newCardReferenceInputs, setPinInputs] = useState({
    reference2: {
      value: currentReference2,
      error: ''
    }
  })

  const handleSave: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const { reference2 } = newCardReferenceInputs
    updateCardMutation.mutate({
      cardNo,
      cardReference1: currentReference1,
      cardReference2: reference2.value
    })
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const name = event.currentTarget.name as keyof typeof newCardReferenceInputs
    const { value } = event.currentTarget

    const newInputs = {
      ...newCardReferenceInputs,
      [name]: { ...newCardReferenceInputs[name], value }
    }
    const { reference2 } = newInputs

    newInputs.reference2.error = validateCardReference(reference2.value, true)

    setPinInputs(newInputs)
  }

  const isSubmitDisabled = () => {
    const { reference2 } = newCardReferenceInputs

    if (reference2.error) return true

    if (reference2.value === currentReference2) {
      return true
    }

    return false
  }
  return (
    <div className="Card Card--Editor">
      <h6 className="Card__Heading">
        <Icon
          name="credit-card"
          primaryColor="green500"
          secondaryColor="blue400"
        />
        {'Redigera kort'}
      </h6>
      <p className="Card__Subheading">{card.cardReference1}</p>
      <form onSubmit={handleSave}>
        <Label
          className="Card__InputRow"
          text="Kortreferens (syns på fakturan/Kontobesked):"
        >
          <input
            aria-required
            className="Card__Input"
            disabled={updateCardMutation.isLoading}
            name="reference2"
            onChange={handleInputChange}
            value={newCardReferenceInputs.reference2.value}
          />
          {newCardReferenceInputs.reference2.error && (
            <div aria-live="polite" className="Card__Error">
              {newCardReferenceInputs.reference2.error}
            </div>
          )}
          {isCompanyCard && (
            <div>
              <br />
              {'Notera att detta även ändrar referensen i korthistoriken.'}
            </div>
          )}
        </Label>

        <div className="Card__ActionButtons">
          <Button
            className="Button Card__Button Card__Button--Change mb-2"
            disabled={isSubmitDisabled()}
            type="submit"
          >
            {'Spara'}
          </Button>
          <button
            className="Button Card__Button Card__Button--Cancel"
            onClick={onCancel}
            type="button"
          >
            {'Avbryt'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default CardChangeName
