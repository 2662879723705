import classNames from 'classnames'
import React, { Suspense, useState } from 'react'
import { Statement as StatementType } from '../../hooks/queryHooks/useGetStatements'
import stringDate from '../../utils/stringDate'
import Icon from '../elements/Icon'
import { InformationRow } from '../InformationRow'
import StatementLink from './StatementLink'
import StatementRelatedTransactions from './StatementRelatedTransactions'

type Props = {
  statement: StatementType
  previousStatementDate: Date
  isOdd: boolean
}

const Statement: React.FC<Props> = ({
  statement,
  previousStatementDate,
  isOdd
}) => {
  const [expanded, setExpanded] = useState(false)

  const handleInvoiceClick = (): void => {
    setExpanded((oldState) => !oldState)
  }

  return (
    <>
      <tr
        className={classNames({
          Invoice: true,
          Invoice__Row: true,
          'Invoice__Row--Expanded': expanded,
          'Invoice__Row--IsOdd': isOdd,
          'Invoice__Row--Main': true
        })}
        onClick={handleInvoiceClick}
      >
        <td className="Invoice__Cell Invoice__Cell--Date">
          <div>
            <Icon
              className={classNames(
                'Invoice__ExpandIcon',
                expanded && 'Invoice__ExpandIcon--Expanded'
              )}
              name={expanded === true ? 'chevron-down' : 'chevron-right'}
              primaryColor="blue400"
              secondaryColor="blue400"
            />
            {stringDate(statement.statementDate)}
          </div>
        </td>
        <td className="Invoice__Cell Invoice__Cell--Status">
          {statement.deliveryType}
        </td>
      </tr>
      {expanded && (
        <>
          <StatementLink isOdd={isOdd} statement={statement} />
          <Suspense
            fallback={
              <InformationRow text="Laddar relaterade transaktioner..." />
            }
          >
            <StatementRelatedTransactions
              isOdd={isOdd}
              previousStatementDate={previousStatementDate}
              statement={statement}
            />
          </Suspense>
        </>
      )}
    </>
  )
}

export default Statement
