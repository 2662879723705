import classNames from 'classnames'
import React, { Fragment, useEffect, useRef } from 'react'
import { useGetStatements } from '../../hooks/queryHooks/useGetStatements'
import { useScrollToPosition } from '../../hooks/useScrollToPosition'
import { getSevenYearsAgoDate } from '../../utils/getSevenYearsAgoDate'
import Icon from '../elements/Icon'
import Statement from './Statement'

type Props = {}

const Statements: React.FC<Props> = () => {
  const {
    hasNextPage,
    fetchNextPage,
    isFetching,
    data: statements
  } = useGetStatements()
  const initialRender = useRef(true)
  const { ref: tableRef, scrollTo } = useScrollToPosition()

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      scrollTo('bottom')
    }
  }, [statements!.pageParams])

  if (statements!.pages[0].payload.length === 0) {
    return <p>{'Du har inga tidigare kontobesked.'}</p>
  }

  const invoicesToShow =
    hasNextPage && statements!.pages.length > 1
      ? statements!.pages.slice(0, -1)
      : statements!.pages

  return (
    <div className="Invoices Invoices--Narrow Invoices--BottomSpacing">
      <table
        className={classNames('Invoices__Table', {
          'Invoices__Table--AllLoaded': !hasNextPage
        })}
        ref={tableRef}
      >
        <tbody>
          {invoicesToShow.map((page, i) => (
            <Fragment key={i}>
              {page.payload.map((statement, index) => {
                const previousInvoices = statements!.pages
                  .slice(i === 0 ? 0 : i - 1)
                  .map((inv) => inv.payload)
                  .flat()
                const currentInvoiceIndex = previousInvoices.indexOf(statement)
                const previousInvoiceDate =
                  currentInvoiceIndex >= 0 &&
                  previousInvoices?.at(currentInvoiceIndex + 1)?.statementDate

                return (
                  <Statement
                    isOdd={index % 2 === 1}
                    key={statement.statementNo}
                    previousStatementDate={
                      previousInvoiceDate || getSevenYearsAgoDate()
                    }
                    statement={statement}
                  />
                )
              })}
            </Fragment>
          ))}
        </tbody>
      </table>
      <div className="Invoices__ActionContainer">
        {hasNextPage && (
          <button
            className="Uninvoiced__LoadMoreButton"
            onClick={() => fetchNextPage()}
            type="button"
          >
            <Icon
              className="Invoice__ButtonIcon"
              fixedWidth
              name="plus"
              type="line"
            />
            {isFetching ? 'Laddar fler ' : 'Visa fler'}
            {isFetching && (
              <Icon
                className="Invoice__LoadingSpinner"
                fixedWidth
                name="spinner"
                spin
                type="line"
              />
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default Statements
