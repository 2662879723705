import { CardModel } from '../domain/models/CardModel'
import { BankCard } from '../integrations/allkort/v1/schemas/Card'

export const isBankCard = (card: CardModel | BankCard) => {
  if ('isSmartCard' in card) {
    return false
  }

  if ('bankCardId' in card) {
    return true
  }
}
