import copy from 'copy-to-clipboard'
import React from 'react'
import { useGetAccountInformation } from '../../hooks/queryHooks/useGetAccountInformation'
import { useToasts } from '../../hooks/useToasts'
import Icon from '../elements/Icon'

type Props = {}

const AccountInformation: React.FC<Props> = () => {
  const { data: accountInformation } = useGetAccountInformation()
  const { addToast } = useToasts()

  const copyToClipboard = (value: string, type: string) => {
    const copyText = value
    const isCopy = copy(copyText, { format: 'text/plain' })
    if (isCopy) {
      addToast({
        message: `${type} kopierat!`,
        isError: false
      })
    }
  }

  return (
    <div className="AccountInformation">
      <table className="AccountInformation__Details Table--Unstyled">
        <tbody>
          <tr className="AccountInformation__Row">
            <td className="AccountInformation__Label">{'Plusgiro'}</td>
            <td>{accountInformation?.giro}</td>
            <button
              className="copyButton"
              onClick={() =>
                copyToClipboard(accountInformation?.giro!, 'Plusgiro')
              }
              type="button"
            >
              <Icon
                name="copy"
                primaryColor="red400"
                secondaryColor="pink500"
              />
            </button>
          </tr>
          <tr className="AccountInformation__Row">
            <td className="AccountInformation__Label">{'OCR'}</td>
            <td>{accountInformation?.ocr}</td>
            <button
              className="copyButton"
              onClick={() => copyToClipboard(accountInformation?.ocr!, 'OCR')}
              type="button"
            >
              <Icon
                name="copy"
                primaryColor="red400"
                secondaryColor="pink500"
              />
            </button>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default AccountInformation
