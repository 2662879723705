export class AccountInformationModel {
  customerNo: number
  giro: string
  ocr: string
  prepaidBalance: number

  constructor(
    customerNo: number,
    giro: string,
    ocr: string,
    prepaidBalance: number
  ) {
    this.customerNo = customerNo
    this.giro = giro
    this.ocr = ocr
    this.prepaidBalance = prepaidBalance
  }
}
