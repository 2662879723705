/* eslint-disable no-confusing-arrow */
/* eslint-disable func-style */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CardModel } from '../../domain/models/CardModel'
import { currentUserHasRight } from '../../utils/currentUserHasRight'
import Link from '../elements/Link'
import Modal from '../elements/Modal'
import CardReplacementForm from './CardReplacementForm'
import ProfileSettingsModal from './Profile/ProfileSettingsModal'

interface DisableLastCardModalProps {
  onClose: () => void
  card: CardModel
}

export const DisableLastCardModal: React.FC<DisableLastCardModalProps> = (
  props
) => {
  const { card, onClose } = props
  const [wantsNewCard, setWantsNewCard] = useState<boolean>(false)
  const navigate = useNavigate()

  return (
    <>
      {wantsNewCard ? (
        <Modal
          onClose={() => {
            setWantsNewCard(false)
            onClose()
          }}
        >
          <div className="Modal__Section">
            <h5 className="m-0">
              {'Ersättningskort: '}
              {card.cardReference1}
            </h5>
          </div>
          <CardReplacementForm
            card={card}
            disableCardAsDefault
            hideDisableCardCheckbox
            isModalSection
            onCancel={() => {
              setWantsNewCard(false)
              onClose()
            }}
            postponeReplacementCard
          />
        </Modal>
      ) : (
        <ProfileSettingsModal
          closeButtonText={
            currentUserHasRight('Superuser') ? 'Avsluta konto' : 'Avbryt'
          }
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onBack={() => {}}
          onCancel={() =>
            currentUserHasRight('Superuser')
              ? navigate('/avsluta-konto')
              : onClose()
          }
          onClose={onClose}
          onSave={() => setWantsNewCard(true)}
          saveButtonText="Beställ ersättningskort"
        >
          <div className="Modal__Section">
            <h5>{'Du försöker nu spärra ditt sista kort'}</h5>
            <p style={{ maxWidth: '35rem' }}>
              {
                'Det är inte möjligt att ha ett konto utan några kort. Du behöver därför beställa ersättningskort.'
              }
            </p>
            <p>
              {'För avslut av ditt konto hänvisar vi till '}
              <Link href="/profil#remove-account" styled>
                {'Profil-sidan'}
              </Link>
              {'.'}
            </p>
          </div>
        </ProfileSettingsModal>
      )}
    </>
  )
}
