import isLength from 'validator/es/lib/isLength'
import trim from 'validator/es/lib/trim'
import { castUtcDate } from '../../utils/castUtcDate'
import { CardNewPinModel } from './CardNewPinModel'
import { EditableModel } from './EditableModel'

const CARD_NAME_MAX_LENGTH = 35

export class CardModel extends EditableModel {
  cardNo: string
  magstripe: string
  cardReference1: string
  /** @alias Name @description User customizable card name */
  cardReference2: string
  isSmartCard: boolean
  disabled: boolean
  disabledDate: Date | null
  isCompany: boolean
  requiresVehicle: boolean
  requiresMilage: boolean

  constructor(
    cardNo: string,
    magstripe: string,
    cardReference1: string,
    /** @alias Name @description User customizable card name */
    cardReference2: string,
    isSmartCard: boolean,
    disabled: boolean,
    disabledDate: string | null,
    isCompany: boolean,
    requiresVehicle: boolean,
    requiresMilage: boolean
  ) {
    super()

    this.cardNo = cardNo
    this.magstripe = magstripe
    this.cardReference1 = cardReference1
    this.cardReference2 = cardReference2
    this.isSmartCard = isSmartCard
    this.disabled = disabled
    this.disabledDate =
      typeof disabledDate === 'string' ? castUtcDate(disabledDate) : null
    this.isCompany = isCompany
    this.requiresVehicle = requiresVehicle
    this.requiresMilage = requiresMilage
  }

  getNewPinModel(): CardNewPinModel {
    const result = new CardNewPinModel(this.cardNo)
    return result
  }

  validateChangeName(): boolean {
    this.resetErrors('cardReference1')

    this.cardReference1 = trim(this.cardReference1)

    const error = cardNameInputError(this.cardReference1)
    if (error) {
      this.addError('cardReference1', error)
      return false
    }

    return true
  }

  validateChangeReference(): boolean {
    this.resetErrors('cardReference2')

    this.cardReference2 = trim(this.cardReference2)

    const error = cardNameInputError(this.cardReference2)
    if (error) {
      this.addError('cardReference2', error)
      return false
    }

    return true
  }
}

export const validateCardReference = (
  newCardName: string,
  optional?: boolean
) => {
  const trimmedName = trim(newCardName)
  return cardNameInputError(trimmedName, optional)
}

export const cardNameInputError = (
  name: string,
  optional?: boolean
): string => {
  const min = optional ? 0 : 1
  const isValidLength = isLength(name, {
    max: CARD_NAME_MAX_LENGTH,
    min
  })

  if (!isValidLength) {
    // @todo Add check and different returns for cardRef1 and cardRef2
    return `Namnet måste vara mellan ${min} och ${CARD_NAME_MAX_LENGTH} tecken.`
  }

  return ''
}
