import { InfiniteData } from '@tanstack/react-query'
import classNames from 'classnames'
import { subYears } from 'date-fns'
import React, { Fragment, useEffect, useRef } from 'react'
import { InvoiceModel } from '../../domain/models/InvoiceModel'
import { PagedResult } from '../../domain/models/PagedResult'
import { TRANSACTION_MAX_AGE_YEARS } from '../../domain/models/TransactionModel'
import { TransactionsModel } from '../../domain/models/transactionsModel'
import { useScrollToPosition } from '../../hooks/useScrollToPosition'
import {
  DepositHistoryTransaction,
  Transaction
} from '../../integrations/allkort/v1/schemas/Transaction'
import { formatSEK } from '../../utils/formatSEK'
import { isBonusTransaction } from '../../utils/isBonusTransaction'
import { isDepositHistoryTransaction } from '../../utils/isDepositHistoryTransaction'
import { isManualTransaction } from '../../utils/isManualTransaction'
import stringDate from '../../utils/stringDate'
import Icon from '../elements/Icon'
import Link from '../elements/Link'

type Props = {
  invoice?: InvoiceModel
  isOdd: boolean
  transactionsQuery:
    | InfiniteData<TransactionsModel>
    | InfiniteData<PagedResult<Transaction>>
    | InfiniteData<PagedResult<DepositHistoryTransaction>>
  hasMore?: boolean
  hideRecieptForBonusTransactions?: boolean
  scrollToBottom?: boolean
  isBankCard?: boolean
  magstripe?: string
}

const TransactionTable: React.FC<Props> = ({
  invoice,
  isOdd,
  hasMore,
  transactionsQuery,
  hideRecieptForBonusTransactions,
  scrollToBottom,
  isBankCard,
  magstripe
}) => {
  const initialRender = useRef(true)
  const { ref: tableRef, scrollTo } = useScrollToPosition()

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      scrollTo('bottom')
    }
  }, [transactionsQuery!.pageParams])

  const checkInvoiceAge = (invoiceDate: Date): boolean => {
    const limitDate = subYears(new Date(), TRANSACTION_MAX_AGE_YEARS)
    return invoiceDate <= limitDate
  }

  if (transactionsQuery?.pages[0].payload.length === 0) {
    return (
      <tr
        className={classNames({
          TransactionTable__Row: true,
          'TransactionTable__Row--IsOdd': isOdd,
          'TransactionTable__Row--Transaction': true
        })}
      >
        <td
          className="TransactionTable__Cell TransactionTable__Cell--Date"
          colSpan={3}
        >
          <div className="TransactionTable__Information">
            {invoice && checkInvoiceAge(invoice.invoiceDate)
              ? `Transaktioner äldre än ${TRANSACTION_MAX_AGE_YEARS} år kan inte laddas.`
              : 'Inga transaktioner hittades.'}
          </div>
        </td>
      </tr>
    )
  }

  return (
    <table
      className={classNames('Uninvoiced__Table', {
        'Uninvoiced__Table--AllLoaded': !hasMore
      })}
      ref={tableRef}
    >
      <tbody className="Uninvoiced__TableBody">
        {transactionsQuery.pages.map((transactionsPage, i) => {
          const isLastPage = i === transactionsQuery.pages.length - 1
          return (
            <Fragment key={i}>
              {transactionsPage.payload.map((transaction, index) => {
                const transactionDate = isDepositHistoryTransaction(transaction)
                  ? transaction.processedDate
                  : transaction.dateTime
                const transactionDateString = stringDate(
                  new Date(transactionDate)
                )

                const isDeposit = isDepositHistoryTransaction(transaction)
                  ? transaction.isDeposit
                  : false

                let href = ''

                if (isBankCard) {
                  href =
                    `${import.meta.env.VITE_BACKEND_URL}/api/BankcardReceipts` +
                    `/Kvitto ${transaction.transactionId}.pdf`
                } else {
                  href =
                    `${import.meta.env.VITE_BACKEND_URL}/api/Receipts` +
                    `/Kvitto ${transaction.transactionId}.pdf`
                }

                const isLastPageItem =
                  index === transactionsPage.payload.length - 1

                if (isManualTransaction(transaction)) {
                  return <Fragment key={transaction.transactionId} />
                }

                return (
                  <tr
                    className={classNames({
                      TransactionTable__Row: true,
                      'TransactionTable__Row--Transaction': true,
                      'TransactionTable__Row--Deposit': isDeposit,
                      'TransactionTable__Row--TransactionLast':
                        isLastPageItem && isLastPage
                    })}
                    key={index}
                  >
                    <td className="TransactionTable__Cell TransactionTable__Cell--Date">
                      {(hideRecieptForBonusTransactions &&
                        isBonusTransaction(transaction)) ||
                      isDeposit ? (
                        <span>{transactionDateString}</span>
                      ) : (
                        <>
                          <Link
                            className="TransactionTable__DownloadLink"
                            href={href}
                            openNewTab
                          >
                            <Icon
                              className="TransactionTable__ReceiptIcon"
                              name="receipt"
                              primaryColor="blue400"
                              secondaryColor="yellow500"
                            />
                          </Link>
                          <Link href={href} openNewTab styled>
                            {transactionDateString}
                          </Link>
                        </>
                      )}
                    </td>
                    <td className="TransactionTable__Cell TransactionTable__Cell--TotalAmount">
                      {formatSEK(transaction.amount)}
                    </td>
                    {transaction.transactionType === 'B' && (
                      <td className="TransactionTable__Cell TransactionTable__Cell--Magstripe">{`Kortnr: ${transaction.magstripe!.replaceAll(
                        'X',
                        '*'
                      )}`}</td>
                    )}
                    <td
                      className="TransactionTable__Cell TransactionTable__Cell--ProductAndSiteName"
                      colSpan={2}
                    >
                      {isDeposit ? (
                        'Insättning'
                      ) : (
                        <>
                          <span className="TransactionTable__CellRow TransactionTable__CellRow--Product">
                            {transaction.productName}
                          </span>
                          {' / '}
                          <span className="TransactionTable__CellRow">
                            {transaction.siteName}
                          </span>
                        </>
                      )}
                    </td>
                  </tr>
                )
              })}
            </Fragment>
          )
        })}
      </tbody>
    </table>
  )
}

export default TransactionTable
