/* eslint-disable func-style */
/* eslint-disable no-restricted-syntax */
import { DepositHistoryTransaction } from '../integrations/allkort/v1/schemas/Transaction'

export function isDepositHistoryTransaction(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any
): obj is DepositHistoryTransaction {
  if (typeof obj.isDeposit === 'boolean') {
    return true
  }

  return false
}
