import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import normalizeUrl from '../../utils/normalizeUrl'
import Wrapper from './Wrapper'

type Props = {
  ariaLabel?: string
  children: React.ReactNode
  className?: string
  href?: string | null
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
  openNewTab?: boolean
  styled?: boolean
}

const Link: React.FunctionComponent<Props> = ({
  ariaLabel,
  children,
  className,
  href,
  onClick,
  openNewTab,
  styled
}) => {
  if (href == null) {
    return <>{children}</>
  }

  const { isInternal, url } = normalizeUrl(href)
  const newClassName = classNames(
    'Link',
    !styled && 'Link--Unstyled',
    className
  )

  let link
  if (isInternal) {
    link = (
      <RouterLink
        aria-label={ariaLabel}
        className={newClassName}
        onClick={onClick}
        to={url}
      >
        {children}
      </RouterLink>
    )
  } else {
    link = (
      /* eslint-disable-next-line react/jsx-no-target-blank */
      <a
        aria-label={ariaLabel}
        className={newClassName}
        href={url}
        onClick={onClick}
        rel={openNewTab ? 'noreferrer noopener' : undefined}
        target={openNewTab ? '_blank' : undefined}
      >
        {children}
      </a>
    )
  }

  return (
    <Wrapper if={isInternal} wrap={(wrappedChildren) => <>{wrappedChildren}</>}>
      {link}
    </Wrapper>
  )
}

Link.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  openNewTab: PropTypes.bool
}

export default Link
