/* eslint-disable require-atomic-updates */

import QueryString from 'qs'
import React, { useEffect } from 'react'
import { AuthService } from '../../integrations/backend/v1/services/AuthService'
import Loader from '../elements/Loader'

const loginUserCallback = async () => {
  const { code } = QueryString.parse(
    window.location.search.slice(1)
  ) as CallbackQuery

  try {
    await AuthService.loginBankIDToken(code)

    window.location.href = '/'
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  } catch (ex: any) {
    window.location.href = `/?error=${encodeURIComponent(ex.message)}`
  }
}

type CallbackQuery = {
  code: string
  scope: string
}

type CallbackPageProps = {}

const CallbackPage: React.FC<CallbackPageProps> = () => {
  useEffect(() => {
    loginUserCallback()
  }, [])

  return <Loader text="Du loggas in..." />
}

export default CallbackPage
