import classNames from 'classnames'
import React, { CSSProperties } from 'react'

type ProfileBoxProps = { style?: CSSProperties; className?: string }

const ProfileBox: React.FC<ProfileBoxProps> = ({
  children,
  style,
  className
}) => {
  return (
    <div
      className={classNames('ProfileBox__Main ProfileBox__Area', className)}
      style={style}
    >
      {children}
    </div>
  )
}

export default ProfileBox
