import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import Icon from '../elements/Icon'
import ToolTipWrapper from '../elements/ToolTipWrapper'

const EmailTypeStringSwedish = [
  {
    heading: 'Information',
    description: 'Informationsutskick'
  },
  {
    heading: 'Mailfaktura/Kontobesked',
    description: 'Till dessa adresser skickas både kontobesked och mailfakturor'
  },
  {
    heading: 'Kvittokopia',
    description: 'Till dessa mailadresser skickas kvittokopior'
  }
] as const

type EmailTableHeadProps = {}

const CustomerProfileEmailsHead: React.FC<EmailTableHeadProps> = () => {
  const { data: customer } = useGetCustomer()
  return (
    <thead>
      <tr>
        <th className="ProfileEmails__Column">
          <strong>{'Mailadress'}</strong>
        </th>
        {EmailTypeStringSwedish.map((item) => {
          if (
            customer!.invoiceMailingDeliveryType === 'PaperMailing' &&
            customer!.statementMailingDeliveryType === 'PaperMailing' &&
            item.heading === 'Mailfaktura/Kontobesked'
          ) {
            return null
          }
          let columnHeading: string = item.heading
          if (
            columnHeading === 'Mailfaktura/Kontobesked' &&
            (customer!.invoiceMailingDeliveryType === 'PaperMailing' ||
              customer!.statementMailingDeliveryType === 'PaperMailing')
          ) {
            if (customer!.invoiceMailingDeliveryType === 'PaperMailing') {
              const statementTitlePart = item.heading.split('/')[1]
              if (statementTitlePart) {
                columnHeading = statementTitlePart
              }
            }
            if (customer!.statementMailingDeliveryType === 'PaperMailing') {
              const mailInvoiceTitlePart = item.heading.split('/')[0]
              if (mailInvoiceTitlePart) {
                columnHeading = mailInvoiceTitlePart
              }
            }
          }
          return (
            <th
              className="ProfileEmailsCheckBox__Column"
              key={item.heading + item.description}
            >
              <span>
                <strong>{columnHeading}</strong>
				<ToolTipWrapper
					enabled={true} // Only show tooltips on desktop
					noHoverState={false}
					text={item.description}
					tooltipPlacement="top"
				>
					<Icon
					className="Info__Icon"
					name="question-circle"
					primaryColor="white"
					secondaryColor="yellow500"
					/>
				</ToolTipWrapper>
              </span>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default CustomerProfileEmailsHead
