import React from 'react'
import { useUserRights } from '../../hooks/useUserRights'
import { WriteAuthenticationRight } from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'

type ShowIfProps =
  | {
      userHasRight: WriteAuthenticationRight | WriteAuthenticationRight[]
      isTrue?: never
    }
  | {
      userHasRight?: never
      isTrue: boolean
    }

export const ShowIf: React.FC<ShowIfProps> = ({
  userHasRight,
  isTrue,
  children
}) => {
  const { userHasRight: currentUserHasRight } = useUserRights()

  if ((userHasRight && currentUserHasRight(userHasRight)) || isTrue) {
    return <>{children}</>
  }

  return null
}
