import {
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query'
import { atom, useAtom } from 'jotai'
import { Customer } from '../../integrations/allkort/v1/schemas/Customer'
import { KeysMatching } from '../../utils/KeysMatching'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetCustomer
export type GetCustomerParams = {
  queryOptions?: Omit<
    UseQueryOptions<UseCustomerData, unknown, UseCustomerData, unknown[]>,
    'initialData'
  >
  additionalQueryKeys?: unknown[]
}

export type UseCustomerData = Customer

const devCustomerAtom = atom<Partial<Customer>>({} as Partial<Customer>)
devCustomerAtom.debugLabel = 'devCustomerAtom'

export const useDevCustomerValues = (additionalQueryKeys: unknown[] = []) => {
  const queryClient = useQueryClient()
  const [devCustomerValues, setDevCustomerValues] = useAtom(devCustomerAtom)

  const toggleBooleanValue = (value: KeysMatching<Customer, boolean>) => {
    const currentQueryData: Customer | undefined = queryClient?.getQueryData([
      queryKey,
      devCustomerValues,
      ...additionalQueryKeys
    ])

    setDevCustomerValues({
      ...devCustomerValues,
      [value]: devCustomerValues?.[value]
        ? !devCustomerValues?.[value]
        : !currentQueryData![value]
    })
  }

  return { values: devCustomerValues, toggleBooleanValue }
}

export const useGetCustomer = (
  options: GetCustomerParams = {
    queryOptions: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, additionalQueryKeys = [] } = options
  const { customerRepo } = useRepos()
  const { values: devCustomerValues } =
    useDevCustomerValues(additionalQueryKeys)

  const query = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [queryKey, devCustomerValues, ...additionalQueryKeys],
    queryFn: async () => {
      const fetchedCustomer = await customerRepo.getCustomer()

      return {
        ...fetchedCustomer,
        name: `${fetchedCustomer.firstName ?? ''} ${
          fetchedCustomer.lastName ?? ''
        }`,
        ...devCustomerValues
      } as UseCustomerData
    },
    keepPreviousData: true,
    ...queryOptions
  })

  return query
}
