import React, { Fragment } from 'react'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import { getPaymentMethodString } from '../../utils/getPaymentMethodString'
import { isAutogiroEnabled } from '../../utils/isAutogiroEnabled'
import Link from '../elements/Link'

type Props = {}

const PaymentOptions: React.FC<Props> = () => {
  const { data: customer } = useGetCustomer()

  const currentPaymentMethod = customer!.invoiceMailingDeliveryType
  const invoiceEmails = customer!.emails.filter(
    (email) => email.emailTypeId === 'InvoiceStatement'
  )

  let address
  if (currentPaymentMethod === 'PaperMailing') {
    address =
      customer!.address1 + ', ' + customer!.postalCode + '  ' + customer!.city
  } else if (currentPaymentMethod === 'Email') {
    address = invoiceEmails
      ? invoiceEmails.map((email) => (
          <Fragment key={email.emailAddress}>
            <br />
            <span>{email.emailAddress}</span>
          </Fragment>
        ))
      : 'Du har ingen mailadress inställd för mailfakturor'
  }
  return (
    <div className="PaymentOptions">
      <div className="PaymentOptions__PaymentInfo">
        <div className="PaymentOptions__Main PaymentOptions__Area">
          <div className="PaymentOptions__Header">
            <p>
              {'Du har valt '}
              <strong>
                {
                  getPaymentMethodString(
                    customer!.invoiceMailingDeliveryType,
                    isAutogiroEnabled(customer!)
                  ).long
                }
              </strong>
              {'.'}
            </p>
          </div>
          {customer!.invoiceMailingDeliveryType !== 'EInvoice' && (
            <div className="PaymentOptions__Header">
              <p>
                {'Din faktura skickas till: '}
                <b className="BreakWord">{address}</b>
              </p>
            </div>
          )}
          {!customer!.isFrozen && (
            <div className="PaymentOptions__Header">
              <span>
                {'För att ändra fakturaalternativ, gå till '}
                <Link href="/profil#invoice-settings" styled>
                  {'Profil'}
                </Link>
                {'.'}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentOptions
