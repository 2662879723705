import { castUtcDate } from '../../utils/castUtcDate'
import { getDateNextDayAtMidnight } from '../../utils/getDateNextDayAtMidnight'
import { PaymentStatus } from '../enums/PaymentStatus'
import { TransactionModel } from './TransactionModel'

export class InvoiceModel {
  deliveryType: string
  invoiceID: number
  invoiceDate: Date
  totalAmount: number
  expirationDate: Date
  paidDate: Date | null
  remainingAmount: number
  transactions: TransactionModel[]
  ocr: string

  constructor(
    deliveryType: string,
    invoiceID: number,
    invoiceDate: Date,
    totalAmount: number,
    expirationDate: Date,
    paidDate: Date | null,
    remaniningAmount: number,
    transactions: TransactionModel[],
    ocr: string | null
  ) {
    this.deliveryType = deliveryType
    this.invoiceID = invoiceID
    this.invoiceDate = invoiceDate
    this.totalAmount = totalAmount
    this.expirationDate = expirationDate
    this.paidDate = paidDate
    this.remainingAmount = remaniningAmount
    this.transactions = transactions
    this.ocr = ocr ?? ''
  }

  getPaymentStatus() {
    if (this.remainingAmount === 0) {
      return PaymentStatus.Paid
    } else if (castUtcDate() >= getDateNextDayAtMidnight(this.expirationDate)) {
      return PaymentStatus.Overdue
    }

    return PaymentStatus.Due
  }
}
