/* eslint-disable no-confusing-arrow */
import React from 'react'
import { useGetSmartCardBalanceHistory } from '../../../hooks/queryHooks/useGetSmartCardBalanceHistory'
import Icon from '../../elements/Icon'
import TransactionTable from '../TransactionTable'

type AllCardsHistoryProps = { startDate?: Date; endDate?: Date }

export const AllCardsHistory: React.FC<AllCardsHistoryProps> = ({
  startDate,
  endDate
}) => {
  const balanceHistoryQuery = useGetSmartCardBalanceHistory({
    additionalQueryKeys: [endDate, startDate].filter(Boolean),
    fetchQuery: {
      startDate: startDate && startDate.toISOString(),
      endDate: endDate && endDate.toISOString()
    }
  })

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
    balanceHistoryQuery

  if (!isLoading && !data) {
    return <p>{'Något gick fel'}</p>
  }

  if (!data || (data!.pages.at(0)?.payload?.length === 0 && !hasNextPage)) {
    return <p>{'Du har inget ofakturerat belopp.'}</p>
  }

  return (
    <>
      <TransactionTable
        hasMore={hasNextPage}
        isOdd
        transactionsQuery={data}
        isBankCard={false}
		magstripe=''
      />
      <div className="Uninvoiced__ActionContainer">
        {hasNextPage && (
          <button
            className="Uninvoiced__LoadMoreButton"
            onClick={() => fetchNextPage()}
            type="button"
          >
            <Icon
              className="Uninvoiced__ButtonIcon"
              fixedWidth
              name="plus"
              type="line"
            />
            {isFetchingNextPage ? 'Laddar fler' : 'Visa fler'}
            {isFetchingNextPage && (
              <Icon
                className="Uninvoiced__LoadingSpinner"
                fixedWidth
                name="spinner"
                spin
                type="line"
              />
            )}
          </button>
        )}
      </div>
    </>
  )
}
