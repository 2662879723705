/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react'
import { BannerData } from '../components/elements/Banner'
type Banner = Record<string, BannerData>
type BannerContextData = {
  banners: Banner
  addBanner: (newBanner: BannerData) => void
  removeBanner: (bannerId: string) => void
}

const initialData: BannerContextData = {
  banners: {},
  addBanner: (newBanner: BannerData) => {},
  removeBanner: (bannerId: string) => {}
}

export const BannerContext = createContext(initialData)

export const BannerProvider: React.FC<{}> = ({ children }) => {
  const [banners, setBanners] = useState<Banner>({})

  const addBanner = (newBanner: BannerData) =>
    setBanners((oldBanners) => ({ ...oldBanners, [newBanner.id]: newBanner }))

  const removeBanner = (bannerId: string) => {
    setBanners((oldBanners) => {
      const newBanners = { ...oldBanners }
      delete newBanners[bannerId]

      return newBanners
    })
  }

  return (
    <BannerContext.Provider value={{ banners, addBanner, removeBanner }}>
      {children}
    </BannerContext.Provider>
  )
}
