import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: process.env.NODE_ENV === 'production',
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
      /**
       * Consider data "fresh" for 5 seconds to prevent multiple
       * fetches for the same query, in quick succession
       */
      staleTime: 1000 * 5,
      suspense: true,
      useErrorBoundary: false
    }
  }
})

export const getAllCachedData = () => {
  const cache = queryClient.getQueryCache()

  const allData = Object.fromEntries(
    cache.getAll().map((query) => [query.queryKey[0], query.state.data])
  )

  return allData
}
