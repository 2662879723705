import React from 'react'
import { MailingDeliveryType } from '../../domain/enums/MailingDeliveryType'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import PaymentMethodBadge from './PaymentMethodBadge'

type Props = {
  onPaymentMethodChange: (newValue: MailingDeliveryType) => void
  selectedPaymentMethod: MailingDeliveryType
}

const PaymentRadioGroup: React.FC<Props> = ({
  selectedPaymentMethod,
  onPaymentMethodChange
}) => {
  const { data: customer } = useGetCustomer()
  const currentPaymentMethod = customer!.invoiceMailingDeliveryType

  const handleRadioButtonChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const newValue = e.currentTarget.value as MailingDeliveryType
    onPaymentMethodChange(newValue)
  }

  return (
    <div className="ProfileSettings__RadioGroup">
      <div className="PaymentRadioGroup__Row">
        <label className="PaymentRadioGroup__Label">
          <input
            checked={selectedPaymentMethod === 'Email'}
            className="PaymentRadioGroup__Checkbox"
            name="togglePayment"
            onChange={handleRadioButtonChange}
            type="radio"
            value="Email"
          />
          {'Mailfaktura'}
        </label>
        {currentPaymentMethod === 'Email' && <PaymentMethodBadge />}
      </div>
      <div className="PaymentRadioGroup__Row">
        <label className="PaymentRadioGroup__Label">
          <input
            checked={selectedPaymentMethod === 'PaperMailing'}
            className="PaymentRadioGroup__Checkbox"
            name="togglePayment"
            onChange={handleRadioButtonChange}
            type="radio"
            value="PaperMailing"
          />
          {'Pappersfaktura'}
        </label>
        {currentPaymentMethod === 'PaperMailing' && <PaymentMethodBadge />}
      </div>
      <div className="PaymentRadioGroup__Row">
        <label className="PaymentRadioGroup__Label">
          <input
            checked={
              selectedPaymentMethod === 'EInvoice' ||
              selectedPaymentMethod === 'EInvoiceCompany'
            }
            className="PaymentRadioGroup__Checkbox"
            name="togglePayment"
            onChange={handleRadioButtonChange}
            type="radio"
            value={customer!.isCompany ? 'EInvoiceCompany' : 'EInvoice'}
          />
          {customer?.isCompany ? 'E-faktura - Företag' : 'E-faktura'}
        </label>
        {(currentPaymentMethod === 'EInvoice' ||
          currentPaymentMethod === 'EInvoiceCompany') && <PaymentMethodBadge />}
      </div>
    </div>
  )
}
export default PaymentRadioGroup
