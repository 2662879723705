import React, { VFC } from 'react'
import Icon from './Icon'

const ExternalLinkIcon: VFC = () => {
  return (
    <Icon className="ExternalLinkIcon" name="external-link-alt" type="line" />
  )
}

export default ExternalLinkIcon
