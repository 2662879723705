/* eslint-disable func-style */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-redeclare */

import { atom, useAtom } from 'jotai'
import { AuthService } from '../integrations/backend/v1/services/AuthService'

const isBankIdUserAtom = atom(
  AuthService.getAuthenticationMethod() === 'bankid'
)

/**
 * FUNCTION OVERLOADS TO CORRECTLY TYPE RETURN DEPENDING ON INPUT
 */
function useIsBankIdUser(includeDevFunction: true): {
  isBankIdUser: boolean
  toggleBankIdUser: () => void
}
function useIsBankIdUser(includeDevFunction?: never): boolean
function useIsBankIdUser(includeDevFunction?: unknown) {
  const [isBankIdUser, setIsBankIdUser] = useAtom(isBankIdUserAtom)

  const toggleBankIdUser = () => {
    setIsBankIdUser((prev) => !prev)
  }

  if (includeDevFunction) {
    return { isBankIdUser, toggleBankIdUser }
  }

  return isBankIdUser
}

export default useIsBankIdUser
