/* eslint-disable no-await-in-loop, require-atomic-updates */

import { BankCardReceips } from '../schemas/Card'
import { ProxyBase } from './ProxyBase'

export class BankCardReceipsProxy extends ProxyBase<BankCardReceips> {
  getReceips(transactionId: string): Promise<string> {
    return this.get(`/api/v2/Transactions/Bankcard/${transactionId}`, {})
  }
}
