import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { DevTools } from 'jotai-devtools'
import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import App from './App'
import UserDevTools from './components/devtools/UserDevTools'
import SuspenseLoaderWrapper from './components/elements/SuspenseLoaderWrapper'
import LoginFlow from './components/pages/LoginFlow'
import MaintenanceModePage from './components/views/MaintenanceModePage'
import { BannerProvider } from './utils/BannerContext'
import { getAllCachedData, queryClient } from './utils/QueryClient'
import { ToastProvider } from './utils/ToastContext'
import { getApiToken } from './utils/getApiToken'

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      }),
      new Sentry.Replay()
    ],
    beforeSend: (event, hint) => {
      if (hint.originalException) {
        const allCachedData = getAllCachedData()
        event.extra = {
          ...event.extra,
          cachedData: allCachedData
        }
      }
      return event
    },
    // Performance Monitoring
    tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

if (import.meta.env.VITE_MAINTANENCE_MODE === 'true') {
  ReactDOM.render(<MaintenanceModePage />, document.getElementById('App'))
} else {
  const apiToken = getApiToken()
  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <BannerProvider>
            <ToastProvider>
              <SuspenseLoaderWrapper text="Laddar dina sidor...">
                {apiToken ? <App /> : <LoginFlow />}
                {import.meta.env.VITE_ENABLE_DEVTOOLS === 'true' && (
                  <>
                    <UserDevTools />
                    <ReactQueryDevtools
                      initialIsOpen={false}
                      position="bottom-right"
                    />
                    <DevTools />
                  </>
                )}
              </SuspenseLoaderWrapper>
            </ToastProvider>
          </BannerProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('App')
  )
}
