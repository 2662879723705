import React from 'react'
import { useGetBankCards } from '../../hooks/queryHooks/useGetBankCards'
import { useGetCards } from '../../hooks/queryHooks/useGetCards'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import { useUserRights } from '../../hooks/useUserRights'
import ExternalLinkIcon from '../elements/ExternalLinkIcon'
import Info from '../elements/Info'
import Link from '../elements/Link'
import RightsButton from '../elements/RightsButton'
import ScrollIndicator from '../elements/ScrollIndicator'
import { useCustomerStatus } from '../hocs/GetCustomerStatus'
import { CustomerProfileBankCardEmail } from '../views/CustomerProfileBankCardEmail'
import { CustomerProfileBalance } from '../views/Profile/CustomerProfileBalance'
import { CustomerProfileEmails } from '../views/Profile/CustomerProfileEmails'
import { CustomerProfileInformation } from '../views/Profile/CustomerProfileInformation'
import { CustomerProfilePayment } from '../views/Profile/CustomerProfilePayment'
import { CustomerProfilePhone } from '../views/Profile/CustomerProfilePhone'
import { CustomerProfileVechicleSpecification } from '../views/Profile/CustomerProfileVechicleSpecification'


type Props = {}

const ProfileSettings: React.FC<Props> = () => {
	const { data: customer } = useGetCustomer()
	const { data: cards } = useGetCards()
	const { data: bankcards } = useGetBankCards()
	const { customerAllowedToWriteBool } = useCustomerStatus( customer );

	const hasSmartCards = cards!.smartCards.length > 0
	const hasCreditCards = cards!.tankCards.length > 0
	const hasBankCards = customer!.bankCardCustomer === true
	const isCardCustomer = ( hasBankCards || hasCreditCards || hasSmartCards )

	const { isSuperUser } = useUserRights()
	const isBankIdUser = useIsBankIdUser()
	//const customerAllowedToEdit = helpers.customerAllowedToWrite()

	return (
	<div className="Row__Inner">
		<div className="Column Column--JustifyStart Column--Lg7">
			<h2>{customer!.name}</h2>
		</div>

		{/* First column */}
		<div className="Column Column--JustifyStart Column--LgPlus6">
			{/* Render ´Kunduppgifter´ */}
			<CustomerProfileInformation />

			{/* Render `Fordonspecifikation` for company customer */}
			{customer!.isCompany && <CustomerProfileVechicleSpecification />}

			{/* Render `Betalsätt - Tankkort` for private customer */}
			{cards!.tankCards.length !== 0 && !customer!.isCompany && (
				<CustomerProfilePayment />
			)}
			{bankcards!.length > 0 && <CustomerProfileBankCardEmail />}
		</div>

		{/* Second column */}
		<div className="Column Column--JustifyStart Column--LgPlus6">
			{/* Render `Telefonnummer` */}
			<CustomerProfilePhone />

			{/* Render `Kontobesked - Smartkort` for private customer */}
			{cards!.smartCards.length !== 0 && !customer!.isCompany && (
				<CustomerProfileBalance />
			)}
		</div>

		{/* Render `Mailadresser - Qstarkort` */}
		{(hasSmartCards || hasCreditCards) && (
			<div className="Column Column--JustifyStart Column--Lg12">
				<CustomerProfileEmails />
				<ScrollIndicator
					mobileOnly={true}
					topMargin="-30px"
					bottomPadding="30px"
				/>
			</div>
		)}

		{/* Render `Betalsätt - Tankkort` for company customer */}
		{cards!.tankCards.length !== 0 && customer!.isCompany && (
			<div className="Column Column--JustifyStart Column--LgPlus6">
				<CustomerProfilePayment />
			</div>
		)}

		{/* Render `Kontobesked - Smartkort` for company customer */}
		{cards!.smartCards.length !== 0 && customer!.isCompany && (
			<div className="Column Column--JustifyStart Column--LgPlus6">
				<CustomerProfileBalance />
			</div>
		)}

		{/* Render `Ta bort konto` */}
		<div className="Column Column--JustifyStart Column--Lg7">
		<div className="ProfilePage__Section">
			<h4 id="remove-account">{'Ta bort konto'}</h4>
			<div>
				{ (isBankIdUser) &&
					<p>
						{'Här kan du välja att avsluta ditt medlemskonto hos Qstar.'}
						<br />
						{'Ditt konto kommer att raderas efter att kundservice har behandlat ärendet.'}
						<br />
						<i>{'Kundservice kan komma att kontakta dig ifall du har kvarvarande saldo.'}</i>
					</p>
				}
				{(isCardCustomer && isBankIdUser) &&     
					<>
						<p className="mb-0">{'Följande kort och tjänster kommer också att avslutas:'}</p>
						<ul>            
							{ hasBankCards &&
								<li>Bankkort - digitala kvitton</li>
							}

							{ hasCreditCards &&
								<li>Tankkort</li>
							}

							{ hasCreditCards &&
								<li>Smartkort</li>
							}
						</ul>
					</>
			}
			</div>  

			{isBankIdUser ? (
			<RightsButton
				className="Profile__DeleteButton"
				disabled={!isSuperUser}
				style={{ margin: 0 }}
				text="Avsluta konto"
				url="/avsluta-konto"
				userRights="Superuser"
				customerAllowedToWrite={customerAllowedToWriteBool}
			/>
			) : (
			<Info small type="info">
				<span>{'För att avsluta ditt konto: '}</span>
				{!customer!.isFrozen && (
					<Link href="/login-bankid">
						{'Logga in med BankID '}
						<ExternalLinkIcon />
					</Link>
				)}
				<span>
					{!customer!.isFrozen && 'eller '}
					{'kontakta kundservice.'}
				</span>
			</Info>
			)}
		</div>
		</div>
	</div>
	)
}
export default ProfileSettings
