import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { deleteUserProfileResponse } from '../../integrations/allkort/v1/proxies/UserProfileProxy'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UseDeleteUserProfileArgs = {
  queryOptions?: UseMutationOptions<
    deleteUserProfileResponse,
    unknown,
    DeleteUserProfileMutationArgs,
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}
export type DeleteUserProfileMutationArgs = { userId: string; userName: string }

export const useDeleteUserProfile = (args: UseDeleteUserProfileArgs = {}) => {
  const {
    queryOptions = {},
    extendedOnSuccessFunction,
    extendedOnErrorFunction
  } = args
  const invalidateQuery = useInvalidateQuery()
  const { addToast } = useToasts()
  const { userProfileRepo } = useRepos()

  const mutation = useMutation(
    (userData: DeleteUserProfileMutationArgs) =>
      userProfileRepo.deleteUser(userData.userId),
    {
      onError: (data, userData) => {
        addToast({
          message:
            `Lyckades inte radera ${userData.userName}. ` +
            'Försök igen senare eller kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: (data, userData) => {
        addToast(`${userData.userName} är nu raderad`)
        invalidateQuery('user-profiles')
        extendedOnSuccessFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}
