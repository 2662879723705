import React from 'react'
import Anchor from './Anchor'
import Button, { ButtonProps } from './Button'

type Props = {
  anchor?: string
  buttons?: ButtonProps[]
  content: string
}

const ContentBox: React.FunctionComponent<Props> = ({
  anchor,
  buttons = [],
  content
}) => {
  return (
    <div className="ContentBox">
      <Anchor hash={anchor} />
      <div
        className="ContentBox__Content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      {buttons.map((button) => (
        <Button key={JSON.stringify(button)} {...button} />
      ))}
    </div>
  )
}

export default ContentBox
