import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { PutCardPinArgs } from '../../integrations/allkort/v1/proxies/CardProxy'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UsePutCardPinArgs = {
  queryOptions?: UseMutationOptions<
    void,
    PutCardPinMutationArgs,
    unknown,
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}
export type PutCardPinMutationArgs = PutCardPinArgs & { cardName: string }

export const usePutCardPin = (args: UsePutCardPinArgs = {}) => {
  const {
    queryOptions = {},
    extendedOnSuccessFunction,
    extendedOnErrorFunction
  } = args
  const invalidateQuery = useInvalidateQuery()
  const { addToast } = useToasts()
  const { cardRepo } = useRepos()

  const mutation = useMutation(
    ({ cardName, ...replacementCardArgs }: PutCardPinMutationArgs) =>
      cardRepo.putCardPin(replacementCardArgs),
    {
      onError: () => {
        addToast({
          message:
            'Lyckades inte ändra pinkoden. ' +
            'Försök igen senare eller kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: (status, cardArgs) => {
        addToast(`Pinkoden för ${cardArgs.cardName} är nu uppdaterat`)
        invalidateQuery('cards')

        extendedOnSuccessFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}
