import copy from 'copy-to-clipboard'
import React from 'react'
import { InvoiceModel } from '../../domain/models/InvoiceModel'
import { useGetAccountInformation } from '../../hooks/queryHooks/useGetAccountInformation'
import { useToasts } from '../../hooks/useToasts'
import stringDate from '../../utils/stringDate'
import Icon from '../elements/Icon'
import Link from '../elements/Link'
import PaymentBadge from '../elements/PaymentBadge'

type Props = {
	invoices: InvoiceModel[]
}

const UpcomingPayment: React.FC<Props> = ({ invoices }) => {
	const { addToast } = useToasts()
	const { data: accountInformation } = useGetAccountInformation()

	const copyToClipboard = (value: string, type: string) => {
		const copyText = value
		const isCopy = copy(copyText, { format: 'text/plain' })
		if (isCopy) {
			addToast({
			message: `${type} kopierat!`,
			isError: false
		})
		}
	}
	return (
		<div className="UpcomingPayment">
			<div className="UpcomingPayment__PaymentInfo">
				<div className="UpcomingPayment__Header" />
				{invoices.length > 0 ? (
					<div className="UpcomingPayment__Invoices UpcomingPayment__Area">
						{invoices.map((invoice) => (
							<>
								<div
									className="UpcomingPayment__InvoiceRow"
									key={invoice.invoiceID}
								>
									<Link
										href={
											`${import.meta.env.VITE_BACKEND_URL}/api/Invoices` +
											`/Faktura ${invoice.invoiceID}.pdf`
										}
										openNewTab
										styled
									>
										<Icon
											className="UpcomingPayment__Icon"
											name="file-invoice"
											primaryColor="blue800"
											secondaryColor="blue400"
										/>
										<span className="UpcomingPayment__DueDate">
											{stringDate(invoice.expirationDate)}
										</span>
									</Link>
									<PaymentBadge
										className="UpcomingPayment__PaymentBadge"
										invoice={invoice}
									/>
								</div>
								{/*
								TODO: This is future code to implement ocs, plusgiro and be able to copy the values. Right now we need to fetch the correct plusgiro from the API. This is to be implemented by CNS 11/11/2024 // Jim.T
								<div 
									className="UpcomingPayment__InvoiceRow"
									key={'ocr-payment'}
								> 
									<table className="UpcomingPayment__Details Table--Unstyled">
										<tbody>
										<tr className="UpcomingPayment__Row">
											<td className="UpcomingPayment__Label">{'Plusgiro'}</td>
											<td className="UpcomingPayment__invoice-data">{accountInformation?.giro}</td>
											<button
											className="copyButton"
											onClick={() =>
												copyToClipboard(accountInformation?.giro!, 'Plusgiro')
											}
											type="button"
											>
											<Icon
												name="copy"
												primaryColor="red400"
												secondaryColor="pink500"
											/>
											</button>
										</tr>
										<tr className="UpcomingPayment__Row">
											<td className="UpcomingPayment__Label">{'OCR'}</td>
											<td className="UpcomingPayment__invoice-data">{invoice.ocr}</td>
											<button
											className="copyButton"
											onClick={() => copyToClipboard(invoice.ocr, 'OCR')}
											type="button"
											>
											<Icon
												name="copy"
												primaryColor="red400"
												secondaryColor="pink500"
											/>
											</button>
										</tr>
										<tr className="UpcomingPayment__Row">
											<td className="UpcomingPayment__Label">{'Summa'}</td>
											<td className="UpcomingPayment__invoice-data">{invoice.remainingAmount}kr</td>
											<button
											className="copyButton"
											onClick={() => copyToClipboard(invoice.remainingAmount.toString(), 'Summa')}
											type="button"
											>
											<Icon
												name="copy"
												primaryColor="red400"
												secondaryColor="pink500"
											/>
											</button>
										</tr>
										</tbody>
									</table>
								</div>
								*/}
						</>
						))}
					</div>
				) : (
					<div
						className="UpcomingPayment__Invoices UpcomingPayment__Area
					UpcomingPayment__PaidRow"
					>
						<Icon
							className="UpcomingPayment__Icon"
							name="check-circle"
							primaryColor="green300"
							secondaryColor="green500"
						/>
						{'Allt är betalt!'}
					</div>
				)}
			</div>
		</div>
	)
}

export default UpcomingPayment
