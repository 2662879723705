/* eslint-disable nonblock-statement-body-position */
import React from 'react'
import { useIsMobile } from '../../hooks/useIsMobile'
import { useUserRights } from '../../hooks/useUserRights'
import { WriteAuthenticationRight } from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import ToolTipWrapper, { ToolTipWrapperProps } from './ToolTipWrapper'

const rightToMessageMap: Record<WriteAuthenticationRight, string> = {
  CardsWrite: 'redigera kort',
  CustomerWrite: 'redigera kundinformation',
  EmailWrite: 'redigera mailadresser',
  PhoneWrite: 'redigera telefonnummer',
  UserProfilesWrite: 'redigera användare',
  CardsRead: '',
  BankCardsRead: '',
  ReceiptsRead: '',
  ReceiptsWrite: '',
  CustomerRead: '',
  EmailRead: '',
  InvoicesRead: '',
  PhoneRead: '',
  RemindersRead: '',
  SalesRead: '',
  StatementsRead: '',
  TransactionsRead: '',
  UserProfilesRead: '',
  VehiclesRead: '',
  BankCardsWrite: '',
  Superuser: ''
}

type UserRightTooltipProps = Omit<ToolTipWrapperProps, 'text'> & {
  userRights:
    | WriteAuthenticationRight
    | WriteAuthenticationRight[]
    | 'Superuser'
  text?: string
}

export const getStringFromRightsList = (
  rights: WriteAuthenticationRight | WriteAuthenticationRight[]
) => {
  if (typeof rights === 'string') return rightToMessageMap[rights]

  if (rights.length === 1) return rightToMessageMap[rights[0]]

  if (rights.length === 2) {
    return `${rightToMessageMap[rights[0]]} och ${rightToMessageMap[rights[1]]}`
  }

  return rights
    .map((right, index) => {
      const rightString = rightToMessageMap[right]

      if (index === rights.length - 1) return rightString
      if (index === rights.length - 2) return `${rightString} och `
      return `${rightString}, `
    })
    .join('')
}

const UserRightTooltip: React.FC<UserRightTooltipProps> = ({
  children,
  userRights,
  enabled,
  text,
  tooltipPlacement,
  ...props
}) => {
  const isMobile = useIsMobile()
  const { userHasRight } = useUserRights()
  const hasRight = userHasRight(userRights)
  const defaultPlacement = isMobile ? 'auto' : 'right'
  const missingUserRights =
    typeof userRights !== 'string' &&
    userRights.filter((right) => !userHasRight(right))
  let tooltipText = ''

  if (userRights === 'Superuser') {
    tooltipText = 'För att använda denna funktion behöver du vara administratör'
  } else {
    tooltipText = `För att använda denna funktion behöver du rättigheten att ${getStringFromRightsList(
      missingUserRights || userRights
    )}`
  }

  return (
    <ToolTipWrapper
      {...props}
      enabled={enabled || !hasRight}
      noHoverState
      text={text ?? tooltipText}
      tooltipPlacement={tooltipPlacement ?? defaultPlacement}
    >
      {children}
    </ToolTipWrapper>
  )
}

export default UserRightTooltip
