import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { DependencyList } from 'react'
import { AuthenticationRightsList } from '../../integrations/allkort/v1/proxies/UserProfileProxy'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetAuthenticationRights

type QueryOptions = Omit<
  UseQueryOptions<
    AuthenticationRightsList,
    unknown,
    AuthenticationRightsList,
    ('authentication-rights' | unknown)[]
  >,
  'initialData'
>

type UserGetInvoicesParams = {
  queryOptions?: QueryOptions
  additionalQueryKeys?: DependencyList[]
}

export const useGetAuthenticationRights = (
  options: UserGetInvoicesParams = {
    queryOptions: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, additionalQueryKeys = [] } = options
  const { userProfileRepo } = useRepos()

  return useQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: () => userProfileRepo.getAuthenticationRights(),
    staleTime: 1000 * 60 * 10, // Keep this data as "fresh" for 10 min, as it doesn't change often
    cacheTime: 1000 * 60 * 30, // Don't discard unused queries for 30 min, for the same reason as above
    ...queryOptions
  })
}
