/* eslint-disable react/display-name */
import classNames from 'classnames'
import React, { forwardRef } from 'react'

type Props = {
  transparent?: boolean
  children: React.ReactNode
}

const Header = forwardRef<HTMLDivElement, Props>(
  ({ children, transparent }, ref) => {
    return (
      <div
        className={classNames({
          Header: true,
          'Header--Transparent': transparent
        })}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)

export default Header
