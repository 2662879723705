import { AuthenticationRight } from '../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import { getCurrentUserRights } from './getCurrentUserRights'

export const currentUserHasRight = (right: AuthenticationRight) => {
  const currentUserRights = getCurrentUserRights()
  if (currentUserRights === 'Superuser') {
    return true
  }

  return currentUserRights.includes(right)
}
