/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from 'react'

export type ToastData = {
  message: string
  isError?: boolean
  id: string
}

type NewToastObject = Omit<ToastData, 'id'>
export type NewToastData = NewToastObject | string

type Toasts = ToastData[]

type ToastContextData = {
  toasts: Toasts
  addToast: (newToast: NewToastData) => void
  removeToast: (toastId: string) => void
}

const initialData: ToastContextData = {
  toasts: [],
  addToast: (newToast: NewToastData) => {},
  removeToast: (toastId: string) => {}
}

export const ToastContext = createContext(initialData)

export const ToastProvider: React.FC<{}> = ({ children }) => {
  const [toasts, setToasts] = useState<Toasts>([])

  const addToast = (newToast: NewToastData, timeBeforeClose?: number) => {
    const toastId = Math.floor(Math.random() * 1000).toString()
    const newToastObject: NewToastObject =
      typeof newToast === 'string' ? { message: newToast } : newToast

    setToasts((oldToasts) => [...oldToasts, { ...newToastObject, id: toastId }])

    setTimeout(() => {
      removeToast(toastId)
    }, timeBeforeClose || 5000)
  }

  const removeToast = (toastId: string) => {
    setToasts((oldToasts) => {
      const newToasts = [...oldToasts].filter((toast) => toast.id !== toastId)

      return newToasts
    })
  }

  return (
    <ToastContext.Provider value={{ toasts, addToast, removeToast }}>
      {children}
    </ToastContext.Provider>
  )
}
