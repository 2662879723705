import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

type AccordionProps = { className?: string; expanded: boolean }

const Accordion: React.FC<AccordionProps> = ({
  children,
  className,
  expanded
}) => {
  return (
    <AnimatePresence>
      {expanded && (
        <motion.div
          animate="open"
          className={className}
          exit="collapsed"
          initial="collapsed"
          key="content"
          style={{ overflow: 'hidden' }}
          transition={{ duration: 0.2, ease: 'easeOut' }}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 }
          }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default Accordion
