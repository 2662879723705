export const allAuthenticationRights = [
  'CardsRead',
  'CardsWrite',
  'BankCardsRead',
  'ReceiptsRead',
  'ReceiptsWrite',
  'CustomerRead',
  'CustomerWrite',
  'EmailRead',
  'EmailWrite',
  'InvoicesRead',
  'PhoneRead',
  'PhoneWrite',
  'RemindersRead',
  'SalesRead',
  'StatementsRead',
  'TransactionsRead',
  'UserProfilesRead',
  'UserProfilesWrite',
  'VehiclesRead',
  'BankCardsWrite'
] as const

// TODO: we will probably need another const to be able to put in superuser rights.
export const superUserRights: string[] = [
  'Superuser'
]

// @ts-ignore
export const allReadAuthenticationRights: AuthenticationRight[] = [
  'CardsRead',
  'BankCardsRead',
  'ReceiptsRead',
  'CustomerRead',
  'EmailRead',
  'InvoicesRead',
  'PhoneRead',
  'RemindersRead',
  'SalesRead',
  'StatementsRead',
  'TransactionsRead',
  'UserProfilesRead',
  'VehiclesRead'
] as const

// @ts-ignore
export const allWriteAuthenticationRights: AuthenticationRight[] = [
  'CardsWrite',
  'CustomerWrite',
  'EmailWrite',
  'PhoneWrite',
  'UserProfilesWrite',
  'BankCardsWrite',
  'ReceiptsWrite'
] as const

export type AuthenticationRights = typeof allAuthenticationRights | 'Superuser'
export type AuthenticationRight =
  | typeof allAuthenticationRights[number]
  | 'Superuser'

export type ReadAuthenticationRights = typeof allReadAuthenticationRights
export type ReadAuthenticationRight = ReadAuthenticationRights[number]

export type WriteAuthenticationRights = typeof allWriteAuthenticationRights
export type WriteAuthenticationRight = WriteAuthenticationRights[number]

export type UserAuthenticationRights = AuthenticationRight[] | 'Superuser'

export type LoginAuthenticationRole = {
  loginAuthenticationRoleId: number
  authenticationRole: AuthenticationRight
  isApiEditable: boolean
}
