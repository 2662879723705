import { AccountInformationModel } from '../../../../domain/models/AccountInformationModel'
import { FetchedCustomer } from '../schemas/Customer'

export class AccountInformationModelMapper {
  getAccountInformationModel = (
    model: FetchedCustomer,
    ocr: string
  ): AccountInformationModel => {
    const result = new AccountInformationModel(
      model.customerNo,
      /**
       * @todo Which Bankgiro should be used?
       * @see https://www.bankgirot.se/sok-bankgironummer/?bgnr=&orgnr=&company=qstar&city=#bgsearchform
       */
      import.meta.env.VITE_QSTAR_BANKGIRO,
      ocr,
      model.prepaidBalance
    )
    return result
  }
}
