import { AddressPhone } from '../../../../domain/models/AddressModel'
import { FetchedCustomer } from '../schemas/Customer'
import { Email } from '../schemas/Email'
import { MailingDeliveryType } from '../schemas/MailingDeliveryType'
import { Phone } from '../schemas/Phone'
import { ProxyBase } from './ProxyBase'

export type CustomerUpdate = {
  contact?: string
  invoiceMailingDeliveryType?: MailingDeliveryType
  statementMailingDeliveryType?: MailingDeliveryType
  isAutogiroChangeRequestedByCustomer?: true
  emails?: Email[]
  phones?: AddressPhone[]
}

export class CustomerProxy extends ProxyBase<FetchedCustomer> {
  deleteEmail(emailId: number): Promise<unknown> {
    return this.delete(`/api/v2/Email/${emailId}`)
  }

  deletePhone(phoneId: number): Promise<unknown> {
    return this.delete(`/api/v2/Phone/${phoneId}`)
  }

  getCustomer(): Promise<FetchedCustomer> {
    return this.get('/api/v2/Customer')
  }

  getPhones(): Promise<Phone[]> {
    return this.get('/api/v2/Phone')
  }

  putCustomer(
    customerValuesToUpdate: CustomerUpdate
  ): Promise<FetchedCustomer> {
    return this.put('/api/v2/Customer', customerValuesToUpdate)
  }

  patchCustomer(customer: Partial<FetchedCustomer>): Promise<FetchedCustomer> {
    return this.put('/api/v2/Customer', customer)
  }

  postCustomerServiceTask(message: string): Promise<unknown> {
    return this.post('/api/v2/Customer/CustomerServiceTask', {
      message: `${message}`
    })
  }
}
