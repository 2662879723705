import classNames from 'classnames'
import React, { ChangeEvent } from 'react'
import { CardModel } from '../../domain/models/CardModel'
import { BankCard } from '../../integrations/allkort/v1/schemas/Card'

type Props = {
  className: string | undefined
  model: (CardModel | BankCard)[]
  dropDownCardValue: string
  onChangeDropdownCard: (event: ChangeEvent) => void
  showOldCards: boolean
}

const CardsList: React.FC<Props> = ({
  className,
  model,
  dropDownCardValue,
  onChangeDropdownCard,
  showOldCards
}) => {
  const handleOnChangeDropdownCard = (event: ChangeEvent): void => {
    return onChangeDropdownCard(event)
  }

  const options = model.map((card) => {
    let value
    if ('cardNo' in card) {
      value = [card.cardReference1, card.cardReference2]
        .map((token) => token.trim())
        .filter((token) => token !== '')
        .join(', ')
    } else {
      value = [card.maskedPan.replaceAll('X', '*')]
        .map((token) => token.trim())
        .filter((token) => token !== '')
        .join(', ')
    }

    if (value === '') {
      if ('cardNo' in card) {
        value = `(Namnlöst kort #${card.cardNo})`
      } else {
        value = `(Namnlöst kort #${card.maskedPan})`
      }
    }

    //TODO: Does bankcard have a status type? Check With CNS how disabled bankcards are handeled
    if ('cardNo' in card) {
      if (card.disabled && !showOldCards) {
        return null
      }
    }
    if ('cardNo' in card) {
      return (
        <option key={card.cardNo} value={card.cardNo}>
          {value}
        </option>
      )
    } else {
      return (
        <option key={card.maskedPan} value={card.maskedPan}>
          {value}
        </option>
      )
    }
  })

  return (
    <select
      className={classNames(
        {
          Dropdown: true,
          'Dropdown--Placeholder': dropDownCardValue === 'none'
        },
        className
      )}
      onBlur={handleOnChangeDropdownCard}
      onChange={handleOnChangeDropdownCard}
      value={dropDownCardValue}
    >
      <option value="none">{'Välj kort\r'}</option>
      {options}
    </select>
  )
}

export default CardsList
