import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { useGetCustomer } from '../../hooks/queryHooks/useGetCustomer'
import useIsBankIdUser from '../../hooks/useIsBankIdUser'
import ExternalLinkIcon from './ExternalLinkIcon'
import Info from './Info'
import Link from './Link'

type Props = {
  actionText?: string
  className?: string
}

export const NeedsExtAuth: FunctionComponent<Props> = ({
  actionText,
  children,
  className
}) => {
  const { data: customer } = useGetCustomer()
  const isBankIdUser = useIsBankIdUser()

  if (isBankIdUser) {
    return <>{children}</>
  }

  if (!actionText) {
    return <></>
  }

  return (
    <Info className={classNames('NeedsExtAuth', className)} small type="info">
      {actionText && (
        <>
          {actionText}
          {': '}
        </>
      )}
      {!isBankIdUser || !customer!.isCompany ? (
        <Link href="/login-bankid">
          {'Logga in med BankID '}
          <ExternalLinkIcon />
        </Link>
      ) : (
        <Link href="https://qstar.se/kontakt">{'Kontakta kundservice'}</Link>
      )}
    </Info>
  )
}
