import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetUserProfiles } from '../../hooks/queryHooks/useGetUserProfiles'
import { UserProfileSortingString } from '../../integrations/allkort/v1/repositories/UserProfileRepository'
import ProfileBox from './ProfileBox'

type Props = {}

let persistentPageNumber: number | null = null
let persistentSortingString: UserProfileSortingString | null = null

export const CustomerProfileBankCardEmail: React.FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentPage = searchParams.get('page')
    ? parseInt(searchParams.get('page') as string)
    : persistentPageNumber || 1

  const currentSorting: UserProfileSortingString | null = searchParams.get(
    'sorting'
  )
    ? (searchParams.get('sorting') as UserProfileSortingString)
    : persistentSortingString || null

  const { data: userProfilesPaged, isFetching } = useGetUserProfiles({
    fetchQuery: {
      PageIndex: currentPage,
      sortingParameters: currentSorting ?? undefined
    }
  })

  return (
    <div>
      <h4 id="information">{'Mailadresser - Bankkort'}</h4>
      <ProfileBox>
        <div className="toolTipIcon">
          {/*<ToolTipWrapper
            enabled={true} // Only show tooltips on desktop
            noHoverState={false}
            text={
              'Denna mailadress är ej redigerbar, om du vill ändra mailadressen kontakta kundservice.'
            }
            tooltipPlacement="top"
          >
            <Icon
              className="Info__Icon"
              name="question-circle"
              primaryColor="white"
              secondaryColor="yellow500"
            />
		</ToolTipWrapper>*/}
        </div>
        {userProfilesPaged && (
          <>
            <b className="mobile-width">{'Information angående dina bankkort skickas till:'}</b>
            <div>
              <span className="BreakWord">
                {userProfilesPaged?.payload[0].email}
              </span>
            </div>
          </>
        )}
        <br />
        {userProfilesPaged && (
          <>
            <b>{'Kvittokopia för dina bankkort skickas till:'}</b>
            <div>
              <span className="BreakWord">
                {userProfilesPaged?.payload[0].email}
              </span>
            </div>
          </>
        )}
      </ProfileBox>
    </div>
  )
}
