import classNames from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'
import { ToastData } from '../../utils/ToastContext'
import Icon from './Icon'

type Props = {
  id: string
  closeToast: () => void
} & ToastData

const Toast: React.FC<Props> = ({ id, message, isError, closeToast }) => {
  const handleClose = () => {
    closeToast()
  }

  return (
    <motion.div
      animate={{ left: 0, opacity: 1 }}
      className={classNames('Toast', {
        'Toast--Error': isError
      })}
      exit={{ opacity: 0 }}
      initial={{ left: 25, opacity: 0 }}
      key={id}
      layout
      onClick={handleClose}
      onKeyPress={handleClose}
    >
      <div className="Toast__Box">
        {isError ? (
          <Icon
            className="Toast__Icon Toast__Icon--Error"
            name="exclamation-triangle"
            primaryColor="white"
            secondaryColor="red500"
          />
        ) : (
          <Icon
            className="Toast__Icon Toast__Icon--Check"
            name="check-circle"
            primaryColor="green300"
            secondaryColor="green500"
          />
        )}

        <span>{message}</span>
        <div
          onClick={handleClose}
          onKeyPress={handleClose}
          role="button"
          tabIndex={0}
        >
          <Icon
            className="Toast__CloseButton"
            name="times"
            primaryColor="white"
            secondaryColor="white"
          />
        </div>
      </div>
    </motion.div>
  )
}

export default Toast
