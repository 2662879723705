import * as React from 'react'

export const useWindowDimensions = () => {
  const [width, setWidth] = React.useState<number>(window.innerWidth)

  React.useEffect(() => {
    // Set width on initial render
    if (!width) {
      setWidth(window.innerWidth)
    }
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return { width }
}
