import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SaveState } from '../../domain/enums/SaveState'
import * as ModelState from '../../domain/models/ModelState'
import { useBanners } from '../../hooks/useBanners'
import { AuthService } from '../../integrations/backend/v1/services/AuthService'
import { getUrlParam } from '../../utils/getUrlParam'
import Button from '../elements/Button'
import Icon from '../elements/Icon'
import Link from '../elements/Link'

type Props = {}

type LoginForm = {
	username: string
	password: string
}

const LoginPage: React.FC<Props> = () => {
	const navigate = useNavigate()
	const { addBanner } = useBanners()

	const [loginState, setLoginState] = useState(ModelState.initialState())
	const [isLoading, setIsLoading] = useState(false)
	const [form, setForm] = useState({
		username: '',
		password: ''
	})

	useEffect(() => {
		const error = getUrlParam('error')

		if (error) {
			addBanner({
			id: error,
			content: error,
			type: 'warning'
			})
		}
	}, [])

	const handleInputChange = (event: React.ChangeEvent): void => {
		const element = event.target as HTMLInputElement
		const name = element.name as keyof LoginForm

		setForm((oldForm) => ({
			...oldForm,
			[name]: element.value
		}))
	}

	const handleSubmit = (event: React.FormEvent): void => {
		event.preventDefault()
		setIsLoading(true)
		;(async () => {
			const loginValidation = validateLogin()

			if (ModelState.hasErrors(loginValidation)) {
				setLoginState((oldLoginState) =>
					ModelState.setFailed(
					oldLoginState,
					'Kontrollera din inmatning och försök igen.'
					)
				)
				return
			}

			try {
				const success =
					import.meta.env.VITE_DEBUG_PASS_NEED_RESET === 'true'
					? false
					: await AuthService.loginCustomerNumber(
						form.username,
						form.password
						)

				if (success) {
					window.location.href = '/'
				} else {
					navigate('/glomt-losenord')
				}
			} catch (ex) {
			const error = ex as Error | { message: undefined }
				setLoginState(
					ModelState.setFailed(loginValidation, error?.message ?? `${ex}`)
				)
			}
		})()

	setIsLoading(false)
	}

	const validateLogin = (): ModelState.State => {
		const propertyErrors: { [property: string]: string } = {}

		if (!form.username) {
			propertyErrors.username = 'Kundnummer saknas.'
		}

		if (!form.password) {
			propertyErrors.password = 'Lösenord saknas.'
		}

		return { ...loginState, propertyErrors }
	}

	const forgotPasswordUrl = `/glomt-losenord${
		form.username ? `?customerNo=${form.username}` : ''
	}`

	return (
	<div className="Section Section--Login login-customer-number BgColor--Red400">
		<div className="Row">
		<div className="Row__Inner">
			<div className="Column Column--Md12 Column--Lg7 Column--JustifyStart Login__InfoText read-more">
				<h1 className="Login__Heading">
					{'Mina sidor'}
					<br />
					{'Inloggning'}
				</h1>
				<div className="Login__TextWrapper">
					<p className="Login__InfoTextSub">
						{
						'Välkommen till Mina sidor. Här kan du som privatperson och enskild firma logga in med BankID eller kundnummer. Vi fortsätter utveckla Mina sidor till Qstar Kundklubb och uppdaterar samtidigt våra '
						}
						<a href="https://qstar.se/tankkort/avtalsvillkor/" target="_blank">
						{'Allmänna villkor'}
						</a>
						{'.'}
						<br />
						<br />
						{'Alla våra befintliga kunder som redan har tillgång till Mina sidor är automatiskt med i Qstar Kundklubb. Det gäller både privat- och företagskunder.'}
						<br />
						<br />
						{'Du accepterar de uppdaterade villkoren för Kundkort, inklusive Villkoren för '}
						<a href="https://qstar.se/kundklubbsvillkor/" target="_blank">
						{'Qstar Kundklubb'}
						</a>
						{', genom att fortsätta att använda ditt Qstar kundkort. Det enda du behöver göra är att fortsätta som vanligt.'}
					</p>
					<input type="checkbox" id="view-more" className="view-more"/>
					<label htmlFor="view-more">
						Läs mer
						<svg className="Login__svg-arrow-down" width="800px" height="800px" viewBox="0 -4.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
							<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
								<g id="Dribbble-Light-Preview" transform="translate(-220.000000, -6684.000000)" fill="#fff">
									<g id="icons" transform="translate(56.000000, 160.000000)">
										<path d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583" id="arrow_down-[#338]">
										</path>
									</g>
								</g>
							</g>
						</svg>
					</label>
				</div>
				
				{process.env.NODE_ENV === 'development' &&
					import.meta.env.VITE_VERSION_TEXT && (
					<p className="Login__VersionText">
						{`Testversion: ${import.meta.env.VITE_VERSION_TEXT}`}
					</p>
				)}
			</div>
			<form
			className="Column Column--Lg4 Column--JustifyStart"
			onSubmit={handleSubmit}
			>
				<div className="Login__Switch">
					<div className="Switch">
					<Link className="Switch__Button" href="/login-bankid">
						{'BankID'}
					</Link>
					<button
						className="Switch__Button Switch__Button--Active"
						type="button"
					>
						{'Kundnummer'}
					</button>
					</div>
				</div>
				<input
					autoComplete="username"
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus
					className="Login__Textfield"
					data-val="true"
					data-val-required="The Username field is required."
					id="Username"
					name="username"
					onChange={handleInputChange}
					placeholder="Kundnummer"
					type="text"
					value={form.username}
				/>
				{loginState.propertyErrors.username && (
					<div aria-live="polite" className="Login__Error">
					{loginState.propertyErrors.username}
					</div>
				)}
				<input
					autoComplete="current-password"
					className="Login__Textfield"
					data-val="true"
					data-val-required="The Password field is required."
					id="Password"
					name="password"
					onChange={handleInputChange}
					placeholder="Lösenord"
					type="password"
					value={form.password}
				/>
				{loginState.propertyErrors.password && (
					<div aria-live="polite" className="Login__Error">
					{loginState.propertyErrors.password}
					</div>
				)}
				<div className="Login__Bottom">
					{loginState.saveState === SaveState.Failed &&
					loginState.errorMessage && (
						<div className="Login__Feedback">
						{loginState.errorMessage}
						</div>
					)}
					<div className="Login__Actions">
					{!isLoading && (
						<Button className="Login__LoginButton" type="submit">
						{'Logga in'}
						</Button>
					)}
					{isLoading && (
						<Button className="Login__LoginButton" type="submit">
							{'Loggar in'}
							<Icon
								className="Login__LoginButtonSpinner"
								fixedWidth
								name="spinner"
								spin
								type="line"
							/>
						</Button>
					)}
					<Link
						className="Login__ForgotPassword"
						href={forgotPasswordUrl}
					>
						{'Glömt lösenord?'}
					</Link>
					</div>
				</div>
			</form>
		</div>
		</div>
	</div>
	)
}

export default LoginPage
