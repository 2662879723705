/* eslint-disable no-await-in-loop, require-atomic-updates */

import { CardType } from '../../../../domain/enums/CardType'
import { PagedResult } from '../../../../domain/models/PagedResult'
import { PutCardMutationArgs } from '../../../../hooks/queryHooks/usePutCard'
import { Card, CardNew, CardReplacement } from '../schemas/Card'
import { ProxyBase } from './ProxyBase'

export type PutCardPinArgs = {
  cardNo: string
  newPin: string
}

export class CardProxy extends ProxyBase<Card> {
  async getCards(cardType?: CardType): Promise<Card[]> {
    let cards: Card[] = []
    let nextPage = 1
    let pagedResult = new PagedResult<Card>([], nextPage, true)

    while (pagedResult.hasMore) {
      pagedResult = await this.getArr('/api/v2/Cards', {
        PageIndex: nextPage
      })

      cards = [...cards, ...pagedResult.payload]

      // Safety against infinite loop:
      if (pagedResult.nextPage === nextPage) {
        break
      }

      nextPage = pagedResult.nextPage
    }

    // If a specific type is required filter for that type
    if (cardType) {
      cards = await cards.filter((card) => card.cardType === cardType)
    }

    return cards
  }

  putCard({
    cardNo,
    cardReference1,
    cardReference2
  }: PutCardMutationArgs): Promise<Card> {
    return this.put(`/api/v2/Cards/${cardNo}`, {
      cardReference1,
      cardReference2
    })
  }

  async putCardPin({ cardNo, newPin }: PutCardPinArgs): Promise<void> {
    await this.put(`/api/v2/Cards/${cardNo}/ChangePin`, {
      newPin: parseInt(newPin)
    })
  }

  async putCardDisable(cardNo: string): Promise<void> {
    await this.put(`/api/v2/Cards/${cardNo}/Disable`)
  }

  async postCardReplacement(
    NewCardReplacement: CardReplacement
  ): Promise<void> {
    await this.post(`/api/v2/Cards/CardReplacement`, NewCardReplacement)
  }

  postNewCard = (newCard: CardNew): Promise<unknown> =>
    this.post('/api/v2/Cards', {
      ...newCard,
      isVirtual: false,
      requiresVehicle: false,
      requiresMileage: false
    })
}
