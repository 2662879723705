import PropTypes from 'prop-types'
import React from 'react'

type Props = {
  hash: string | undefined
}

const Anchor: React.FunctionComponent<Props> = ({ hash }) => {
  return hash ? <div className="Anchor" id={hash} /> : null
}

Anchor.propTypes = {
  hash: PropTypes.string
}

export default Anchor
