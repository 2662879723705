import trim from 'validator/es/lib/trim'
import {
  pinInputError,
  pinInputVerifyError
} from '../../components/views/CardNewPin'
import { EditableModel } from './EditableModel'

export class CardNewPinModel extends EditableModel {
  cardNo: string
  newPin: string = ''
  newPinVerify: string = ''

  constructor(cardNo: string) {
    super()
    this.cardNo = cardNo
  }

  validate(): boolean {
    this.newPin = trim(this.newPin)
    this.newPinVerify = trim(this.newPinVerify)

    let error = pinInputError(this.newPin)
    if (error) {
      this.propertyErrors = { newPin: error }
      return false
    }

    error = pinInputVerifyError(this.newPin, this.newPinVerify)

    this.propertyErrors = error ? { newPinVerify: error } : {}

    return !error
  }
}
