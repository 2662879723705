/* eslint-disable @tanstack/query/exhaustive-deps */
/* eslint-disable no-confusing-arrow */
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions
} from '@tanstack/react-query'
import { CardType } from '../../domain/enums/CardType'
import { PagedResult } from '../../domain/models/PagedResult'
import { TransactionQuery } from '../../integrations/allkort/v1/proxies/TransactionProxy'
import { Transaction } from '../../integrations/allkort/v1/schemas/Transaction'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetSmartCardHistory

export type GetSmartCardHistoryParams = {
  queryOptions?: UseInfiniteQueryOptions<
    PagedResult<Transaction>,
    unknown,
    PagedResult<Transaction>
  >
  fetchQuery?: TransactionQuery
  additionalQueryKeys?: unknown[]
}

export const useGetSmartCardHistory = (
  options: GetSmartCardHistoryParams = {
    queryOptions: {},
    fetchQuery: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, fetchQuery, additionalQueryKeys = [] } = options
  const { balanceHistoryRepo } = useRepos()
  const query = useInfiniteQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: ({ pageParam = 1 }) =>
      balanceHistoryRepo.getTransactionHistory({
        PageIndex: pageParam,
        cardType: CardType.SmartCard,
        ...fetchQuery
      }),
    getNextPageParam: (lastPage) =>
      lastPage.hasMore ? lastPage.nextPage : undefined,
    keepPreviousData: true,
    ...queryOptions
  })

  return query
}
