import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import upperFirst from '../../utils/upperFirst'
import Anchor from './Anchor'

type Props = {
  align?: 'top'
  anchor?: string
  children: React.ReactNode
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  gutter?: 0 | 16 | 32 | 48 | 64
  innerClassName?: string
  justify?: 'center' | 'left' | 'right'
  reverse?: boolean
}

const Row: React.FunctionComponent<Props> = ({
  align,
  anchor,
  children,
  className,
  gutter,
  innerClassName,
  justify = 'left',
  reverse
}) => {
  return (
    <div
      className={classNames(
        'Row',
        align && `Row--Align${upperFirst(align)}`,
        `Row--Justify${upperFirst(justify)}`,
        { [`Row--Gutter${gutter}`]: gutter !== undefined },
        { 'Row--Reverse': reverse },
        className
      )}
    >
      <Anchor hash={anchor} />
      <div className={classNames('Row__Inner', innerClassName)}>{children}</div>
    </div>
  )
}

Row.propTypes = {
  align: PropTypes.oneOf(['top']),
  anchor: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  gutter: PropTypes.oneOf([0, 16, 32, 48, 64]),
  innerClassName: PropTypes.string,
  justify: PropTypes.oneOf(['center', 'left', 'right']),
  reverse: PropTypes.bool
}

export default Row
