import classNames from 'classnames'
import * as React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/types'

type CustomTooltipProps = {
  tooltipPlacement?: Placement
  text: string | null
  className?: string
  noHoverState?: boolean
  style?: React.CSSProperties
}
export const CustomTooltip: React.FC<
  React.PropsWithChildren<CustomTooltipProps>
> = (props) => {
  const {
    text = '',
    tooltipPlacement = 'right',
    className,
    noHoverState,
    style
  } = props

  const target = React.useRef(null)

  return (
    <div className={className} style={style}>
      <OverlayTrigger
        overlay={<Tooltip className="Tooltip">{text}</Tooltip>}
        placement={tooltipPlacement}
        trigger={['click', 'focus', 'hover']}
      >
        <div
          className={classNames('Tooltip', {
            'Tooltip--NoHoverState': noHoverState
          })}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
              e.preventDefault()
            }
          }}
          ref={target}
          role="button"
          tabIndex={0}
        >
          {props.children ? (
            props.children
          ) : (
            <i className="fa fa-question-circle Tooltip__Icon" />
          )}
        </div>
      </OverlayTrigger>
    </div>
  )
}
