import { PagedResult } from '../../../../domain/models/PagedResult'
import {
  UpdateUserProfileInfoArgs,
  UserProfileQuery,
  UserProfileSortingString
} from '../repositories/UserProfileRepository'
import { AuthenticationRight } from '../schemas/LoginAuthenticationRole'
import { LoginAuthenticationRoleCreate } from '../schemas/LoginAuthenticationRoleCreate'
import { UserLogin } from '../schemas/UserLogin'
import { UserLoginCreate } from '../schemas/UserLoginCreate'
import { UserProfile } from '../schemas/UserProfile'
import { UserProfileCreate } from '../schemas/UserProfileCreate'
import { ProxyBase } from './ProxyBase'

export type deleteUserProfileResponse = {
  statusCode: number
}

export type AuthenticationRightsGroups = [
  'Superuser',
  'Kort',
  'Kund',
  'E-post',
  'Fakturor',
  'Telefonnummer',
  'Påminnelser',
  'Kundförsäljning',
  'Kontoutdrag',
  'Transaktioner',
  'Användarprofiler',
  'Fordon'
]

export type AuthenticationRightsGroup = AuthenticationRightsGroups[number]

export interface AuthenticationRightsListItem<T = AuthenticationRight> {
  authenticationRoleId: number
  claimName: T
  displayName: string
  isApiEditable: boolean
  groupingName: AuthenticationRightsGroup
  description: string
}

export type AuthenticationRightsList<T = AuthenticationRight> =
  AuthenticationRightsListItem<T>[]

type UserProfilesPagedParams = {
  includeLogins: boolean
  PageIndex: number
  sortingParameters?: UserProfileSortingString
}

export class UserProfileProxy extends ProxyBase<UserProfile> {
  async getCurrentUser(): Promise<UserProfile> {
    const currentUser = await this.get('/api/v2/UserProfiles/Current')
    return currentUser
  }

  async getAuthenticationRights(): Promise<AuthenticationRightsList> {
    const authenticationRightsList = await this.get<AuthenticationRightsList>(
      '/api/v2/UserProfiles/Login/AuthenticationRole'
    )

    return authenticationRightsList
  }

  async getUserProfilesPaged(
    query: UserProfileQuery
  ): Promise<PagedResult<UserProfile>> {
    const userProfiles = (await this.getArr(
      '/api/v2/UserProfiles',
      query
    )) as PagedResult<UserProfile>

    return userProfiles
  }

  async getUserProfiles(): Promise<UserProfile[]> {
    const userProfiles: UserProfile[] = []
    let nextPage = 1
    let pagedResult = new PagedResult<UserProfile>([], nextPage, true)

    while (pagedResult.hasMore) {
      // eslint-disable-next-line no-await-in-loop
      pagedResult = await this.getArr('/api/v2/UserProfiles', {
        includeLogins: true,
        PageIndex: nextPage
      })
      userProfiles.push(...pagedResult.payload)

      // Safety against infinite loop:
      if (pagedResult.nextPage === nextPage) {
        break
      }

      nextPage = pagedResult.nextPage
    }
    return userProfiles
  }

  async updateUser({ updatedUserData, userId }: UpdateUserProfileInfoArgs) {
    const updatedUserObject = (await this.put(
      `/api/v2/UserProfiles/${userId}`,
      { ...updatedUserData, isActive: true }
    )) as unknown as Promise<UserProfile>
    return updatedUserObject
  }

  async deleteAuthenticationRole(
    userId: string,
    loginId: string,
    authenticationRightsToDelete: number[]
  ) {
    await this.delete(
      `/api/v2/UserProfiles/${userId}/Login/${loginId}/LoginAuthenticationRole`,
      authenticationRightsToDelete
    )
  }

  async createUser(newUserProfile: UserProfileCreate): Promise<UserProfile> {
    const response = (await this.post(
      '/api/v2/UserProfiles',
      {
        ...newUserProfile,
        isActive: true
      },
      true
    )) as Response

    const newUserObject = response.json()

    return newUserObject as unknown as UserProfile
  }

  async deleteUser(userId: string): Promise<deleteUserProfileResponse> {
    const response = (await this.delete(
      `/api/v2/UserProfiles/${userId}`
    )) as Response
    const deletedUser = response.json()
    return deletedUser
  }

  async createLogin(
    userId: string,
    newUserLogin: UserLoginCreate
  ): Promise<UserLogin> {
    const response = (await this.post(
      `/api/v2/UserProfiles/${userId}/Logins`,
      newUserLogin,
      true
    )) as Response
    const createdUserLogin = response.json()
    return createdUserLogin
  }

  async createAuthenticationRole(
    userId: string,
    loginId: string,
    newAuthenticationRights: LoginAuthenticationRoleCreate
  ): Promise<void> {
    await this.post(
      `/api/v2/UserProfiles/${userId}/Login/${loginId}/LoginAuthenticationRole`,
      newAuthenticationRights,
      true
    )
  }
}
