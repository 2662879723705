import React, { useState } from 'react'
import Modal from '../../../../elements/Modal'

type TechInfoModalProps = {
  text?: string
  title?: string
}

const TechInfoModal: React.FC<TechInfoModalProps> = ({
  children,
  title = 'Teknisk info',
  text = 'Teknisk info'
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <button
        className="Button Button--Small"
        onClick={() => setIsOpen(true)}
        style={{ margin: '0 0 .5rem .75rem' }}
        type="button"
      >
        {text}
      </button>
      {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
          <div className="Modal__Section p-4">
            <h5 className="mb-0">{title}</h5>
          </div>
          <div className="Modal__Section p-4">{children}</div>
        </Modal>
      )}
    </>
  )
}

export default TechInfoModal
