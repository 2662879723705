import React, { useEffect } from 'react'
import { AuthService } from '../../integrations/backend/v1/services/AuthService'
import Loader from '../elements/Loader'

const forwardUser = async () => {
  try {
    const redirectUrl = await AuthService.authorizeBankIDLogin()
    window.location.replace(redirectUrl)
  } catch {}
}

type AuthorizePageProps = {}

const AuthorizePage: React.FC<AuthorizePageProps> = () => {
  useEffect(() => {
    forwardUser()
  }, [])

  return <Loader text="Du skickas vidare..." />
}

export default AuthorizePage
