import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Anchor from './Anchor'

type Props = {
  anchor?: string
  children: React.ReactNode
  content?: string
  justify?: 'top'
  lg?: number
  lgPlus?: number
  md?: number
  sm?: number
  xs?: number
  className?: string
  style?: React.CSSProperties
}

const Column: React.FunctionComponent<Props> = ({
  anchor,
  children,
  content,
  justify,
  lg,
  lgPlus,
  md,
  sm,
  xs,
  className = '',
  style
}) => {
  const columnClassName = classNames(
    'Column',
    justify === 'top' && 'Column--JustifyStart',
    { [`Column--LgPlus${lgPlus}`]: lgPlus != null },
    { [`Column--Lg${lg}`]: lg != null },
    { [`Column--Md${md}`]: md != null },
    { [`Column--Sm${sm}`]: sm != null },
    { [`Column--Xs${xs}`]: xs != null },
    className
  )

  return content ? (
    <>
      <Anchor hash={anchor} />
      <div
        className={columnClassName}
        dangerouslySetInnerHTML={content ? { __html: content } : undefined}
        style={style}
      />
    </>
  ) : (
    <>
      <Anchor hash={anchor} />
      <div className={columnClassName} style={style}>
        {children}
      </div>
    </>
  )
}

Column.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.string,
  justify: PropTypes.oneOf(['top']),
  lg: PropTypes.number,
  lgPlus: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default Column
