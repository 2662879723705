/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-key */
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { AddressPhone as Phone } from '../../../domain/models/AddressModel'
import { useGetCustomer } from '../../../hooks/queryHooks/useGetCustomer'
import { usePutCustomer } from '../../../hooks/queryHooks/usePutCustomer'
import { PhoneEdit } from '../../../integrations/allkort/v1/schemas/Phone'
import { mapPhonesToPhoneEdit } from '../../../utils/mapPhonesToPhoneEdit'
import ActionButton from '../../elements/ActionButton'
import { CustomTooltip } from '../../elements/CustomTooltip'
import RightsButton from '../../elements/RightsButton'
import { useCustomerStatus } from '../../hocs/GetCustomerStatus'
import ProfileSettingsModal from '../ProfileSettingsModal'

type Props = {}

/**
 * This number is used to give new phone numbers unique IDs.
 * These are later replaced with real IDs when added to the database
 */
let phoneId = 0

export const CustomerProfilePhone: React.FC<Props> = (props) => {
  const { data: customer } = useGetCustomer()
  const { customerAllowedToWriteBool } = useCustomerStatus( customer );

  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [removalConfirmation, setRemovalConfirmation] = React.useState<{
    phoneId: number
  } | null>(null)

  const updateCustomerMutation = usePutCustomer({
    extendedOnSuccessFunction: () => setEditMode(false)
  })

  const [phones, setPhones] = React.useState(
    mapPhonesToPhoneEdit(customer!.phones)
  )

  useEffect(() => {
    phones.forEach((phone, index) => validatePhoneNumber(phone.phoneNo, index))
  }, [])

  let cannotSave = false
  phones.forEach((phone) => {
    if (
      !phone.description ||
      !phone.phoneNo ||
      phone.descriptionErrorMessage ||
      phone.phoneNoErrorMessage
    ) {
      cannotSave = true
    }
  })

  const deleteRow = (phoneIdtoDelete: number) => {
    const newPhones = phones.filter(
      (phone) => phone.phoneId !== phoneIdtoDelete
    )
    setPhones(newPhones)
  }

  const handleRemovalConfirmation = (phone: PhoneEdit) => {
    if (phone.description !== '' || phone.phoneNo !== '') {
      setRemovalConfirmation({ phoneId: phone.phoneId })
    } else {
      deleteRow(phone.phoneId)
    }
  }

  const addRow = () => {
    const newPhones = [...phones]
    phoneId += 1
    newPhones.push({
      description: '',
      phoneId,
      phoneNo: '',
      sortOrder: 0,
      descriptionErrorMessage: '',
      phoneNoErrorMessage: ''
    })
    setPhones(newPhones)
  }

  const handleSave = () => {
    const newPhones = phones.map(
      (phone) =>
        ({
          description: phone.description,
          phoneId: phone.phoneId,
          phoneNo: phone.phoneNo
        } as Phone)
    )

    updateCustomerMutation.mutate({
      phones: newPhones
    })
  }

  const handleCancel = () => {
    setPhones(mapPhonesToPhoneEdit(customer!.phones))
    setEditMode(false)
  }

  const handleChange = (
    value: string,
    index: number,
    key: 'phoneNo' | 'description'
  ) => {
    const newPhones = [...phones]

    const newPhone = {
      ...phones[index],
      [key]: value
    } as PhoneEdit

    if (key === 'phoneNo') {
      newPhone.phoneNoErrorMessage = getPhoneNumberError(value, index)
    }
    if (key === 'description') {
      newPhone.descriptionErrorMessage = getDescriptionError(value)
    }

    newPhones[index] = newPhone
    setPhones(newPhones)
  }

  const handleSwitchToEditMode = () => {
    setEditMode(true)

    if (phones.length === 0) {
      addRow()
    }
  }

  const validatePhoneNumber = (value: string, index: number) => {
    const newPhones = [...phones]

    newPhones[index].phoneNoErrorMessage = getPhoneNumberError(value, index)
    setPhones(newPhones)
  }

  const getPhoneNumberError = (value: string, index: number) => {
    let errorMessage = ''
    const duplicates = phones.filter(
      (phone, i) => phone.phoneNo === value && i !== index
    )

    // Checks so the number starts with +46 
    const startsWithCountryCode = /^\+46/.test(value);

    if (value.length === 0) {
      errorMessage = 'Kan inte vara tomt'
    } else if (!startsWithCountryCode) {
      errorMessage = 'Felaktigt format, använd +467....'
    } else if (duplicates.length !== 0) {
      errorMessage = 'Telefonnumret finns redan'
    }

    return errorMessage
  }

  const getDescriptionError = (value: string) => {
    const errorMessage = value.length === 0 ? 'Lägg till en beskrivning' : ''
    return errorMessage
  }

  return (
    <div>
      <h4 id="phone-numbers">{'Telefonnummer'}</h4>
      <div className="ProfilePhone__Area">
        {phones.length === 0 && !editMode ? (
          <p>
            <i>{'Du har inga telefonnummer inlagda.'}</i>
          </p>
        ) : (
          <div className="ProfilePhone__Row">
            <>
              <div className="ProfilePhone__Column">
                <strong>{'Telefonnummer'}</strong>
                {editMode ? (
                  <>
                    <CustomTooltip
                      className="ms-2"
                      text="Telefonnummer måste börja med +467xxxxxxxx"
                      tooltipPlacement="top-end"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="ProfilePhone__Column">
                <strong>{'Referens'}</strong>
                {editMode ? (
                  <>
                    <CustomTooltip
                      className="ms-2"
                      text="Skriv in valfri text som identifierar telefonnumret. Exempelvis namnet på personen numret tillhör."
                      tooltipPlacement="top-end"
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </>
          </div>
        )}
        {phones &&
          phones.map((item, index) => (
            <div key={index}>
              {editMode ? (
                <>
                  <div className="ProfilePhone__Row ">
                    <div className="ProfilePhone__Column">
                      <input
                        className="ProfilePhone__Input"
                        onBlur={(event) =>
                          validatePhoneNumber(event.currentTarget.value, index)
                        }
                        onChange={(event) =>
                          handleChange(
                            event.currentTarget.value,
                            index,
                            'phoneNo'
                          )
                        }
                        placeholder="+467012345678"
                        style={{ width: '90%' }}
                        type="tel"
                        value={item.phoneNo}
                      />
                      <div className="ProfilePhone__ErrorMessage">
                        {item.phoneNoErrorMessage}
                      </div>
                    </div>
                    <div className="ProfilePhone__Column">
                      <input
                        className="ProfilePhone__Input"
                        onChange={(event) =>
                          handleChange(
                            event.currentTarget.value,
                            index,
                            'description'
                          )
                        }
                        placeholder="Fyll i valfri text*"
                        value={item.description ?? ''}
                      />
                      <div className="ProfilePhone__ErrorMessage">
                        {(item.description?.length === 0 ? ('Referens krävs') : (''))}
                      </div>
                      <ActionButton
                        onClick={() => handleRemovalConfirmation(item)}
                        size="small"
                        title="Ta bort rad"
                        variation="delete"
                      />
                      {removalConfirmation && (
                        <ProfileSettingsModal
                          onBack={() => {
                            setRemovalConfirmation(null)
                          }}
                          onCancel={() => {
                            setRemovalConfirmation(null)
                          }}
                          onConfirm={() => {
                            deleteRow(removalConfirmation.phoneId)
                            setRemovalConfirmation(null)
                          }}
                          onSave={() => {
                            deleteRow(removalConfirmation.phoneId)
                            setRemovalConfirmation(null)
                          }}
                          saveButtonText="Ta bort"
                        >
                          <div className="Modal__Section">
                            <h5>{'Bekräfta borttagning'}</h5>
                            {
                              'Är du säker på att du vill ta bort detta telefonnummer?'
                            }
                          </div>
                        </ProfileSettingsModal>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="ProfilePhone__Row ProfilePhone__Border">
                  <div className="ProfilePhone__Column">
                    <p>{item.phoneNo}</p>
                  </div>
                  <div className="ProfilePhone__Column">
                    <p style={{ fontStyle: 'italic' }}>{item.description}</p>
                  </div>
                </div>
              )}
            </div>
          ))}
        {editMode && (
          <div
            className="ProfilePhone__Row"
            style={{ borderBottom: '2px solid #f0f0f0' }}
          >
            <Button
              className="PaymentOptions__Badge my-4"
              onClick={addRow}
              style={{ border: 'none' }}
            >
              <i className="Icon fa fa-plus fad me-2" />
              {'Lägg till rad'}
            </Button>
          </div>
        )}
        {editMode ? (
          <div className="mt-3">
            <Button
              className="ProfilePhone__Button ProfilePhone__Button--Save me-3"
              disabled={cannotSave}
              onClick={handleSave}
            >
              {'Spara'}
            </Button>
            <Button
              className="ProfilePhone__Button ProfilePhone__Button--Cancel"
              onClick={handleCancel}
            >
              {'Avbryt'}
            </Button>
          </div>
        ) : (
          <RightsButton
            className={classNames('PaymentOptions__Badge', {
              'mt-5': phones.length > 0
            })}
            disableOnPasswordLogin={false}
            onClick={handleSwitchToEditMode}
            style={{ border: 'none' }}
            userRights="PhoneWrite"
            customerAllowedToWrite={customerAllowedToWriteBool}
          >
            {phones.length === 0
              ? 'Lägg till telefonnummer'
              : 'Redigera telefonnummer'}
          </RightsButton>
        )}
      </div>
    </div>
  )
}
