import { PagedResult } from '../../domain/models/PagedResult'
import { authorizedFetch } from './authorizedFetch'
import { parseResponse } from './parseResponse'

export const authorizedGetPaged = async <T>(
  path: string,
  query?: unknown
): Promise<PagedResult<T>> => {
  try {
    const response = await authorizedFetch('GET', path, { query })

    if (!response.ok) {
      throw new Error()
    }

    const payload = await parseResponse<T[]>(response)
    const currentPage = parseInt(
      response.headers.get('x-pagination-pageindex') ?? ''
    )
    const emptyPage = Number.isNaN(currentPage)
    const nextPage = emptyPage ? 1 : currentPage + 1
    const maxPages = Number(response.headers.get('x-pagination-pagecount'))
    const hasMore =
      !emptyPage && response.headers.get('x-pagination-hasnextpage') === 'True'
    const pagedResult = new PagedResult(payload, nextPage, hasMore, maxPages)
    return pagedResult
  } catch {
    throw new Error(
      'Lyckades inte hämta data. Försök igen senare eller kontakta kundservice.'
    )
  }
}
