import { CardModel } from '../../../domain/models/CardModel'
import { castUtcDate } from '../../../utils/castUtcDate'

export const mockCards: CardModel[] = [
  new CardModel(
    '546821',
    '************7813',
    'Peter Andersson',
    'Rydviks Taxitjänst',
    false,
    false,
    null,
    false,
    true,
    true
  ),
  new CardModel(
    '546825',
    '************9358',
    'Lorenzo Agazzi-Hurtig',
    'Cederbergs AB',
    true,
    false,
    null,
    true,
    false,
    false
  ),
  new CardModel(
    '546742',
    '************3456',
    'Amanda Andersson',
    '',
    false,
    false,
    null,
    false,
    true,
    true
  ),
  new CardModel(
    '546743',
    '************3211',
    'Lena Andersson',
    '',
    true,
    false,
    null,
    false,
    false,
    false
  ),
  new CardModel(
    '546835',
    '************8421',
    'Cassandra Melin',
    'Privat',
    false,
    false,
    castUtcDate().toString(),
    true,
    true,
    true
  ),
  new CardModel(
    '546848',
    '************5901',
    'Tina Törnqvist',
    'Privat',
    true,
    true,
    castUtcDate().toString(),
    false,
    false,
    false
  ),
  new CardModel(
    '546875',
    '************0418',
    'Erik Hansson',
    '',
    false,
    false,
    null,
    false,
    false,
    false
  )
]
