import qs from 'qs'
import { getApiToken } from '../getApiToken'

export const authorizedFetch = (
  method: string,
  path: string,
  options: { body?: unknown; query?: unknown } = {}
) => {
  const requestInit: RequestInit = {
    headers: {
      Authorization: `Bearer ${getApiToken()}`
    },
    method
  }

  if (options.body) {
    requestInit.body = JSON.stringify(options.body)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(requestInit.headers as any)['Content-Type'] = 'application/json'
  }

  if (options.query) {
    path += `?${qs.stringify(options.query)}`
  }

  const response = fetch(`${import.meta.env.VITE_API_URL}${path}`, requestInit)
  return response
}
