import { useEffect } from 'react'

let hasInitialized = false
const useSetupMatomo = () => {
  useEffect(() => {
    const containerID = import.meta.env.VITE_MATOMO_CONTAINER_ID
    if (!hasInitialized && containerID) {
      // @ts-ignore
      // eslint-disable-next-line no-multi-assign
      const _mtm = (window._mtm = window._mtm || [])
      _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
      const d = document
      const g = d.createElement('script')
      const s = d.getElementsByTagName('script')[0]
      g.async = true

      g.src = `https://qstar-matomo.hemsida.eu/js/container_${containerID}.js`
      s.parentNode?.insertBefore(g, s)
      hasInitialized = true
    }
  }, [])
  return null
}

export default useSetupMatomo
