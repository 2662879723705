import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AgreementDto } from '../../integrations/backend/v1/schemas/AgreementDto'
import { useRepos } from '../useRepos'
import { queryKeys } from './QueryKeys'

export const queryKey = queryKeys.useGetAgreements
export type GetAgreementsParams = {
  queryOptions?: Omit<
    UseQueryOptions<AgreementDto[], unknown, AgreementDto[], unknown[]>,
    'initialData'
  >
  additionalQueryKeys?: unknown[]
}

export const useGetAgreements = (
  options: GetAgreementsParams = {
    queryOptions: {},
    additionalQueryKeys: []
  }
) => {
  const { queryOptions, additionalQueryKeys = [] } = options
  const { agreementRepo } = useRepos()

  const query = useQuery({
    queryKey: [queryKey, ...additionalQueryKeys],
    queryFn: () => agreementRepo.getAgreements(),
    keepPreviousData: true,
    ...queryOptions
  })

  return query
}
