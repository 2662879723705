import qs from 'qs'
import { PagedResult } from '../../../../domain/models/PagedResult'

export class ProxyBase<T> {
  private apiToken: string

  constructor(apiToken: string) {
    this.apiToken = apiToken
  }

  private fetch(
    method: string,
    path: string,
    options: { body?: unknown; query?: unknown } = {}
  ) {
    const requestInit: RequestInit = {
      headers: {
        Authorization: `Bearer ${this.apiToken}`
      },
      method
    }

    if (options.body) {
      requestInit.body = JSON.stringify(options.body)

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(requestInit.headers as any)['Content-Type'] = 'application/json'
    }

    if (options.query) {
      path += `?${qs.stringify(options.query)}`
    }

    const response = fetch(
      `${import.meta.env.VITE_API_URL}${path}`,
      requestInit
    )
    return response
  }

  private async json<R = T>(response: Response) {
    const text = await response.text()
    const responseBody = (text ? JSON.parse(text) : undefined) as R
    return responseBody
  }

  /**
   * @description
   * Throws exceptions on all types of failures or errors.
   * No exception = all good.
   */
  protected async get<R = T>(path: string, query?: unknown): Promise<R> {
    const response = await this.fetch('GET', path, { query })

    if (!response.ok) {
      throw new Error(
        'Lyckades inte hämta data. Försök igen senare eller kontakta kundservice.'
      )
    }

    const responseBody = await this.json(response)
    return responseBody as unknown as R
  }

  /**
   * @description
   * Throws exceptions on all types of failures or errors.
   * No exception = all good.
   */
  protected async post(
    path: string,
    body?: unknown,
    useResponseError?: boolean
  ): Promise<unknown> {
    const response = await this.fetch('POST', path, { body })

    if (!response.ok) {
      if (useResponseError) {
        const res = await response.json()
        throw new Error(res.Message)
      } else {
        throw new Error(
          'Lyckades inte skicka förfrågan. Försök igen senare eller kontakta kundservice.'
        )
      }
    }
    return response
  }

  /**
   * @description
   * Throws exceptions on all types of failures or errors.
   * No exception = all good.
   */
  protected async create(path: string, body: unknown): Promise<T> {
    const response = await this.fetch('POST', path, { body })

    if (!response.ok) {
      throw new Error(
        'Lyckades inte skicka förfrågan. Försök igen senare eller kontakta kundservice.'
      )
    }

    const responseBody = await this.json(response)
    return responseBody
  }

  /**
   * @description
   * Throws exceptions on all types of failures or errors.
   * No exception = all good.
   */
  protected async getBlob(path: string): Promise<Blob> {
    const response = await this.fetch('GET', path)

    if (!response.ok) {
      throw new Error(
        'Lyckades inte hämta data. Försök igen senare eller kontakta kundservice.'
      )
    }

    const blob = response.blob()
    return blob
  }

  /**
   * @description
   * Throws exceptions on all types of failures or errors.
   * No exception = all good.
   */
  protected async getArr(
    path: string,
    query?: unknown
  ): Promise<PagedResult<T>> {
    try {
      const response = await this.fetch('GET', path, { query })

      if (!response.ok) {
        throw new Error()
      }

      const payload = await this.json<T[]>(response)
      const currentPage = parseInt(
        response.headers.get('x-pagination-pageindex') ?? ''
      )
      const emptyPage = Number.isNaN(currentPage)
      const nextPage = emptyPage ? 1 : currentPage + 1
      const maxPages = Number(response.headers.get('x-pagination-pagecount'))
      const hasMore =
        !emptyPage &&
        response.headers.get('x-pagination-hasnextpage') === 'True'
      const pagedResult = new PagedResult(payload, nextPage, hasMore, maxPages)
      return pagedResult
    } catch {
      throw new Error(
        'Lyckades inte hämta data. Försök igen senare eller kontakta kundservice.'
      )
    }
  }

  /**
   * @description
   * Throws exceptions on all types of failures or errors.
   * No exception = all good.
   */
  protected async put(path: string, body?: unknown): Promise<T> {
    const response = await this.fetch('PUT', path, { body })

    if (!response.ok) {
      throw new Error(
        'Lyckades inte skicka förfrågan. Försök igen senare eller kontakta kundservice.'
      )
    }

    const responseBody = await this.json(response)
    return responseBody
  }

  /**
   * @description
   * Throws exceptions on all types of failures or errors.
   * No exception = all good.
   */
  protected async delete(path: string, body?: unknown): Promise<unknown> {
    const response = await this.fetch('DELETE', path, { body })

    if (!response.ok) {
      throw new Error(
        'Lyckades inte ta bort objekt. Försök igen senare eller kontakta kundservice.'
      )
    }

    return response
  }
}
