import React from 'react'
import {
  MailingDeliveryType,
  MailingDeliveryTypes
} from '../../domain/enums/MailingDeliveryType'
import ProfileSettingsModal from '../views/Profile/ProfileSettingsModal'

type Props = {
  onBack: () => void
  onCancel: () => void
  onConfirm: () => void
  onSave: () => void
  newPaymentMethod: MailingDeliveryTypes
  saved: boolean
  newEmail: string
  hasChangedEmail: boolean
}

const paymentMethodTranslationMap = {
  [MailingDeliveryType.EInvoice]: 'e-faktura',
  [MailingDeliveryType.EInvoiceCompany]: 'e-faktura företag',
  [MailingDeliveryType.EInvoiceCompanyAndEmail]: 'e-faktura företag',
  [MailingDeliveryType.Email]: 'mailfaktura',
  [MailingDeliveryType.PaperMailing]: 'pappersfaktura'
}

const PaymentChangeConfirmation: React.FC<Props> = ({
  onBack,
  onCancel,
  onConfirm,
  onSave,
  newPaymentMethod,
  saved,
  newEmail,
  hasChangedEmail
}) => {
  const paymentMethodTranslation = paymentMethodTranslationMap[newPaymentMethod]
  return (
    <>
      {hasChangedEmail ? (
        <ProfileSettingsModal
          isPaymentSettings={false}
          onBack={onBack}
          onCancel={onCancel}
          onConfirm={onConfirm}
          onSave={onSave}
          saved={saved}
        >
          <div className="Modal__Section">
            <h5>{'Bekräfta ny mailadress'}</h5>
            <p>
              {'Är du säker på att du vill ändra mailadress till '}
              <b>{newEmail}</b>
              {'?'}
            </p>
          </div>
        </ProfileSettingsModal>
      ) : (
        <ProfileSettingsModal
          isPaymentSettings
          onBack={onBack}
          onCancel={onCancel}
          onConfirm={onConfirm}
          onSave={onSave}
          saved={saved}
        >
          <div className="Modal__Section">
            <h5>{'Bekräfta nytt betalsätt'}</h5>
            <p>
              {'Är du säker på att du vill ändra faktureringssätt till '}
              <b>{paymentMethodTranslation}</b>
              {'?'}
            </p>
            {paymentMethodTranslation ===
              paymentMethodTranslationMap.PaperMailing && (
              <p>
                {'Detta innebär att en pappersfaktura kommer att '}
                {'skickas varje månad till en kostnad av 25 kr.'}
              </p>
            )}
            {paymentMethodTranslation === paymentMethodTranslationMap.Email && (
              <p>
                {
                  'Detta innebär att en faktura skickas till dig varje månad via mail.'
                }
              </p>
            )}
          </div>
        </ProfileSettingsModal>
      )}
    </>
  )
}

export default PaymentChangeConfirmation
