import classNames from 'classnames'
import React from 'react'
import { useLocation } from 'react-router-dom'
import parseUrl from 'url-parse'
import { MenuItem } from '../../legacy/mock/components/menuItems'
import Link from './Link'

type Props = {
  headerItems: MenuItem[]
  transparent?: boolean
}

const SubMenu: React.FC<Props> = ({ headerItems, transparent }) => {
  const location = useLocation()
  const subMenuLocation = `subMenu-${location.pathname?.slice(1).replace( '/', '-' )}`

  return (
    <nav
      className={classNames({
        SubMenu: true,
        'SubMenu--Transparent': transparent,
      })}
    >
      <ul className={classNames('SubMenu__Child List--Unstyled SubMenu__Items SubMenu__Items--Navigation', subMenuLocation)}>
        {headerItems.map((item) => {
          const itemClass = classNames(
            'SubMenu__Item',
            item.url && {
              'SubMenu__Item--Active':
                parseUrl(item.url).pathname === location.pathname ||
                // location.pathname.includes(`/${item.url}`) ||
                (location.pathname.includes('tankkort') &&
                  parseUrl(item.url).pathname.includes('tankkort')) ||
                (location.pathname.includes('smartkort') &&
                  parseUrl(item.url).pathname.includes('smartkort')) ||
                (location.pathname.includes('hantera-anvandare') &&
                  parseUrl(item.url).pathname.includes('hantera-anvandare')) ||
                (location.pathname === '/fakturor-kontobesked' &&
                  parseUrl(item.url).pathname.includes('tankkort')) ||
                (location.pathname === '/fakturor-kontobesked/bankcard' &&
                  parseUrl(item.url).pathname.includes('bankcard'))
            },
            item.classes
          )
          return (
            <li className={itemClass} key={item.url ? item.url : item.title}>
              <Link ariaLabel={item.title} href={`/${item.url}`}>
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default SubMenu
