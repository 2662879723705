/* eslint-disable react/no-multi-comp */
import React from 'react'
import { AuthenticationRightsList } from '../../integrations/allkort/v1/proxies/UserProfileProxy'
import {
  allWriteAuthenticationRights,
  WriteAuthenticationRights
} from '../../integrations/allkort/v1/schemas/LoginAuthenticationRole'
import { UserProfile } from '../../integrations/allkort/v1/schemas/UserProfile'
import { UserProfileCreate } from '../../integrations/allkort/v1/schemas/UserProfileCreate'
import { UserProfileUpdate } from '../../integrations/allkort/v1/schemas/UserProfileUpdate'
import Icon from '../elements/Icon'

const inputNameToLabelRelations = {
  personalNumber: 'Personnummer',
  rights: 'Användaren har rätt att redigera följande',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  email: 'E-mail',
  phoneNo: 'Telefonnummer'
} as const

export const UserProfileSummary: React.FC<{
  userProfile: UserProfile | UserProfileCreate | UserProfileUpdate
  authenticationRights: AuthenticationRightsList
  becomeSuperUser: boolean
}> = ({ userProfile, authenticationRights, becomeSuperUser }) => {
  return (
    <ul style={{ padding: 0 }}>
      {Object.entries(userProfile).map(([key, value] : [string, any]) => {
        if (key === 'rights') {
          const rights = value.filter((right: string) =>
            right.includes('Write')
          ) as WriteAuthenticationRights
          return (
            <li key={key} style={{ listStyle: 'none' }}>
              <p
                style={{
                  margin: '1.75rem 0 .75rem',
                  fontSize: '1.3rem',
                  fontWeight: '500'
                }}
              >
                {
                  inputNameToLabelRelations[
                    key as keyof typeof inputNameToLabelRelations
                  ]
                  
                }
                {':'}
              </p>
              { false && becomeSuperUser && (
                // TODO: This is set to false because it will trigger on future implementation. Gets superuser value from toggle and show this message.
                // Adds extra text to warn about setting user to superuser.
                <p 
                className='warning-wrap'>
                  Användaren kommer att få fullmakt <br />
                  med samma rättigheter som kontots ägare.
                </p>
              )}
              
              {[...allWriteAuthenticationRights]
                // This below is disgusting, but i had to :) BYE
                .sort((a, b) => {
                  // @ts-ignore
                  return authenticationRights[a] > authenticationRights[b]
                    ? 1
                    : -1
                })
                .map((right) => {
                  const iconColor = rights.includes(right)
                    ? 'green300'
                    : 'red300'
                  const isRightEnabled = rights.includes(right)

                  return (
                    <p key={right} style={{ marginBottom: '.5rem' }}>
                      <span style={{ marginRight: '.5rem' }}>
                        {
                          authenticationRights.find(
                            (rightObject) => rightObject.claimName === right
                          )?.groupingName
                        }
                      </span>
                      <Icon
                        name={isRightEnabled ? 'check' : 'times'}
                        primaryColor={iconColor}
                        secondaryColor={iconColor}
                        style={{
                          fontSize: isRightEnabled ? '1.1rem' : '1.3rem'
                        }}
                      />
                    </p>
                  )
                })}
            </li>
          )
        }

        return (
          <li key={key} style={{ listStyle: 'none' }}>
            <strong>
              {
                inputNameToLabelRelations[
                  key as keyof typeof inputNameToLabelRelations
                ]
              }
              {
              key !== 'becomeSuperUser' ? ':' : '' // Avoid the last form input that is becomeSuperUser. Otherwise showing empty `:`
              }
            </strong>{' '}
            {value}
          </li>
        )
      })}
    </ul>
  )
}
