const MS_PER_MINUTE = 60000

export default (date: Date | null): string | null => {
  if (date == null) {
    return null
  }

  return new Date(date.getTime() - date.getTimezoneOffset() * MS_PER_MINUTE)
    .toISOString()
    .split('T')[0]!
}
