import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'

type UsePostCustomerServiceTaskArgs = {
  queryOptions?: UseMutationOptions<
    unknown,
    PutCardMutationArgs,
    unknown,
    unknown
  >
  extendedOnErrorFunction?: () => unknown
}
export type PutCardMutationArgs = {
  cardNo: string
  cardReference1: string
  cardReference2: string
}

export const usePostCustomerServiceTask = (
  args: UsePostCustomerServiceTaskArgs = {}
) => {
  const { queryOptions = {}, extendedOnErrorFunction } = args
  const { addToast } = useToasts()
  const { customerRepo } = useRepos()

  const mutation = useMutation(
    (message: string) => customerRepo.postCustomerServiceTask(message),
    {
      onError: () => {
        addToast({
          message:
            'Misslyckades att skicka kundärendet. Försök gärna igen senare. ' +
            'Om problemet består, vänligen kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: () => {
        addToast('Lyckades skicka kundärendet.')

        extendedOnErrorFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}
