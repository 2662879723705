import { QueryKey, useQueries, useQueryClient } from '@tanstack/react-query'
/**
 * Rerenders the component where this hook is used whenever
 * the queries change. This is primarily used to interact
 * with the DOM after suspense is done suspending.
 *
 * Check ScrollToAnchor for a example usecase
 */
const useRerenderOnQueryChange = (queryKeys: QueryKey[] = []): boolean => {
  const queryClient = useQueryClient()

  const allQueryKeys =
    queryKeys.length > 0
      ? queryClient.getQueryCache().findAll(queryKeys)
      : queryClient.getQueryCache().getAll()

  const queryResults = useQueries({
    queries: allQueryKeys
  })

  return queryResults.some((result) => result.isLoading)
}

export default useRerenderOnQueryChange
