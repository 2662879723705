import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { useRepos } from '../useRepos'
import { useToasts } from '../useToasts'
import useInvalidateQuery from './useInvalidateQuery'

type UsePutCardDisableArgs = {
  queryOptions?: UseMutationOptions<
    void,
    PutCardDisableMutationArgs,
    unknown,
    unknown
  >
  extendedOnSuccessFunction?: () => unknown
  extendedOnErrorFunction?: () => unknown
}
export type PutCardDisableMutationArgs = {
  cardNo: string
  cardName: string
}

export const usePutCardDisable = (args: UsePutCardDisableArgs = {}) => {
  const {
    queryOptions = {},
    extendedOnSuccessFunction,
    extendedOnErrorFunction
  } = args
  const invalidateQuery = useInvalidateQuery()
  const { addToast } = useToasts()
  const { cardRepo } = useRepos()

  const mutation = useMutation(
    (cardArgs: PutCardDisableMutationArgs) =>
      cardRepo.putCardDisable(cardArgs.cardNo),
    {
      onError: () => {
        addToast({
          message:
            'Lyckades inte spärra kortet. ' +
            'Försök igen senare eller kontakta kundservice.',
          isError: true
        })

        extendedOnErrorFunction?.()
      },
      onSuccess: (data, cardArgs) => {
        invalidateQuery('cards')
        addToast(`${cardArgs.cardName} är nu spärrat`)

        extendedOnSuccessFunction?.()
      },
      ...queryOptions
    }
  )

  return mutation
}
